import styles from './PageHeader.module.scss';

export interface IPageHeaderProps {
    children: any;
}

export function PageHeader(props: IPageHeaderProps) {
    return (<div className={styles.host}>
        {props.children}
    </div>);
}
