import axios from 'axios';
import Logger from 'js-logger';
import { serverUrl } from '@platform/config';

const endpoint = `${serverUrl}/api/Google`;

export async function getSheetData(req: any): Promise<any[]> {
    const resp = await axios.post(`${endpoint}/GetSheetData`, JSON.stringify(req));
    Logger.debug('getSheetData: ', req, resp);
    return resp.data;
}