import { DecimalInput } from "@core/VisualComponents/Inputs/DecimalInput/DecimalInput";
import styles from "./CrmGridAddDecimalFilterDialog.module.scss";
import { useEffect, useState } from "react";
import { t } from "i18next";
import { InvalidSpan } from "@core/VisualComponents/Inputs/InvalidHint/InvalidHint";
import { ICrmField } from "@core/Models/autogenerated/tenantConfig.models";

interface IDecimalFilterQuery {
    $gte?: number;
    $lte?: number;
}

export interface ICrmGridAddDecimalFilterDialogProps {
    field: ICrmField;
    query: IDecimalFilterQuery | null;
    onChanged: (query: IDecimalFilterQuery | null) => void;
    setIsValid: (isValid: boolean) => void;
    onEnter?: () => void;
    autoFocus?: boolean;
}

export function CrmGridAddDecimalFilterDialog(props: ICrmGridAddDecimalFilterDialogProps) {
    const [isValidLeft, setIsValidLeft] = useState<boolean>(true);
    const [isValidRight, setIsValidRight] = useState<boolean>(true);
    const [currQuery, setCurrQuery] = useState<any>(props.query);

    useEffect(() => {
        props.setIsValid(isValidLeft && isValidRight);
    }, [isValidLeft, isValidRight]);

    const onChangedLeft = (value: any) => {
        let newQuery = currQuery;

        if (value == null || value === "" || isNaN(value)) {
            if (newQuery?.$lte == null) {
                newQuery = null;
            }
            else {
                delete newQuery.$gte;
            }
        }
        else {
            newQuery = newQuery ?? {};
            newQuery.$gte = value;
        }

        setCurrQuery(newQuery);
        props.onChanged(newQuery);
    }

    const onChangedRight = (value: any) => {
        let newQuery = currQuery;

        if (value == null || value === "" || isNaN(value)) {
            if (newQuery?.$gte == null) {
                newQuery = null;
            }
            else {
                delete newQuery.$lte;
            }
        }
        else {
            newQuery = newQuery ?? {};
            newQuery.$lte = value;
        }

        setCurrQuery(newQuery);
        props.onChanged(newQuery);
    }

    const onEnter = () => {
        if (isValidLeft && isValidRight) {
            props.onEnter && props.onEnter();
        }
    }

    return <div className={styles.container}>
        <DecimalInput
            placeholder={t("from")}
            initialValue={currQuery?.$gte}
            onChanged={onChangedLeft}
            setIsValid={setIsValidLeft}
            autoFocus={props.autoFocus}
            onEnter={onEnter}
            hideErrorTip
        />
        <span>–</span>
        <DecimalInput
            placeholder={t("to")}
            initialValue={currQuery?.$lte}
            onChanged={onChangedRight}
            setIsValid={setIsValidRight}
            onEnter={onEnter}
            hideErrorTip
        />
        {isValidLeft && isValidRight ||
            <InvalidSpan text={t("invalid_decimal_value")} className={styles.invalidSpan}/>
        }
    </div>;
}