import {addAuthInterceptor} from './auth-interceptor';
import axios from 'axios';
import {addContentTypeInterceptor} from './content-type-interceptor';
import {addRetryInterceptor} from './retry-interceptor/retry-interceptor';

export interface IInterceptorIdPair {
    requestInterceptorId: number|null;
    responseInterceptorId: number|null;
}

export interface IInterceptorIds {
    ids: IInterceptorIdPair[];
}

export function setupAxiosInterceptors(location: Location): IInterceptorIds {
    const interceptorIds:IInterceptorIds = { ids: [] };

    const authExcludedPaths = [
        '/api/auth/SignIn',
        '/api/auth/Signup',
        '/api/auth/Register'
    ];
    interceptorIds.ids.push(addAuthInterceptor({excludedPaths: authExcludedPaths, location}));
    interceptorIds.ids.push(addContentTypeInterceptor({ urlPrefix: '/api', contentType: 'application/json'}));
    interceptorIds.ids.push(addRetryInterceptor({ retries: 8 }));

    return interceptorIds;
}

export function ejectAxiosInterceptors(interceptorIds: IInterceptorIds) {
    for (let idsPair of interceptorIds.ids) {
        if(idsPair.requestInterceptorId)
            axios.interceptors.request.eject(idsPair.requestInterceptorId);

        if(idsPair.responseInterceptorId)
            axios.interceptors.response.eject(idsPair.responseInterceptorId);
    }
}
