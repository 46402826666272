import styles from "./CrmGridAddTagsFilterDialog.module.scss";
import { ICrmField } from "@core/Models/autogenerated/tenantConfig.models";
import { TagsInput } from "@core/VisualComponents/Inputs/TagsInput/TagsInput";
import { useEffect } from "react";

interface ITagsFilterQuery {
    $containsAny: string[];
}

export interface ICrmGridAddTagsFilterDialogProps {
    field: ICrmField;
    query: ITagsFilterQuery | null;
    onChanged: (query: ITagsFilterQuery | null) => void;
    onEnter?: () => void;
    autoFocus?: boolean;
}

export function CrmGridAddTagsFilterDialog(props: ICrmGridAddTagsFilterDialogProps) {
    const keydownHandler = (event: KeyboardEvent) => {
        switch (event.key) {
            case "Enter":
                props.onEnter && props.onEnter();
                break;
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", keydownHandler, false);
        return () => {
            document.removeEventListener("keydown", keydownHandler, false);
        };
    }, [keydownHandler]);

    const onChanged = (values: string[] | string | null) => {
        if (values == null || values.length === 0) {
            props.onChanged(null);
        }
        else {
            props.onChanged({
                $containsAny: Array.isArray(values) ? values : [values],
            });
        }
    }

    const getSelectedValues = () => {
        return props.field.options?.filter(x => props.query?.$containsAny.includes(x.value)).map(x => x.value) ?? [];
    }

    return <div className={styles.host} onClick={e => e.stopPropagation()}>
        <TagsInput
            initialValue={getSelectedValues()}
            options={props.field.options}
            placeholder={props.field.placeholder}
            onChanged={onChanged}
            autoFocus={props.autoFocus}
        />
    </div>;
}