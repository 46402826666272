import {DATA_TYPE, ITable} from 'jsstore';
import {kvPairsTableName} from '@core/JsStore/stores/kv-pairs-store';

export function createKvPairsTable(): ITable {
    return {
        name: kvPairsTableName,
        columns: {
            id: {
                dataType: DATA_TYPE.Number,
                primaryKey: true,
                autoIncrement: true
            },
            key: {
                dataType: DATA_TYPE.String,
                notNull: true,
                unique: true
            },
            value: {
                dataType: DATA_TYPE.String,
            }
        }
    };
}
