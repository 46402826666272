import classNames from 'classnames/bind';
import styles from './CrmPaginationInternal.module.scss';
import {useTranslation} from "react-i18next";

export interface ICrmPaginationInternalProps {
    onFirstPageClicked: ()=>void;
    onPreviousPageClicked: ()=>void;
    onNextPageClicked: ()=>void;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
}

export function CrmPaginationInternal(props: ICrmPaginationInternalProps) {
    const { t } = useTranslation();
    const firstPageLinkVisible = props.hasPreviousPage;
    const prevPageLinkVisible = props.hasPreviousPage;
    const nextPageLinkVisible = props.hasNextPage;

    return (<div className={styles.crmPagination}>
        {t("pages")}:
        { firstPageLinkVisible && <a className={styles.pageNavLink}
                                     onClick={()=>props.onFirstPageClicked()}>{t("first")}</a>}
        { prevPageLinkVisible && <a className={styles.pageNavLink}
                                     onClick={()=>props.onPreviousPageClicked()}>{t("prev")}</a>}
        { nextPageLinkVisible && <a className={styles.pageNavLink}
                                     onClick={()=>props.onNextPageClicked()}>{t("next")}</a>}
    </div>);
}
