import { ICrmField, ICrmValueOption } from "@core/Models/autogenerated/tenantConfig.models";
import { t } from "i18next";
import { MutableRefObject, useEffect, useRef } from "react";
import { ComboboxInput } from "@core/VisualComponents/Inputs/ComboboxInput/ComboboxInput";

export interface ICrmGridAddBooleanFilterDialogProps {
    field: ICrmField;
    query: boolean | null;
    onChanged: (query: boolean | null) => void;
    onEnter?: () => void;
    autoFocus?: boolean;
}

export function CrmGridAddBooleanFilterDialog(props: ICrmGridAddBooleanFilterDialogProps) {
    const keydownHandler = (event: KeyboardEvent) => {
        switch (event.key) {
            case "Enter":
                props.onEnter && props.onEnter();
                break;
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", keydownHandler, false);
        return () => {
            document.removeEventListener("keydown", keydownHandler, false);
        };
    }, [keydownHandler]);

    const onChanged = (value: string | string[] | null) => {
        props.onChanged(value == null ? null : value === "true")
    }

    const options: ICrmValueOption[] = [
        {
            value: "true",
            label: t("yes"),
        },
        {
            value: "false",
            label: t("no"),
        },
    ];

    const getSelectedValue = () => {
        return props.query == null ? null : props.query.toString();
    }

    return <ComboboxInput
        options={options}
        initialValue={getSelectedValue()}
        placeholder={props.field.placeholder}
        onChanged={onChanged}
        autoFocus={props.autoFocus}
    />;
}