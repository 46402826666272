import { MutableRefObject, useEffect, useRef, useState } from "react";
import Overlay from "react-overlays/esm/Overlay";
import styles from "./CrmGridAddColumnButton.module.scss";
import { PrimaryButton } from "@core/VisualComponents/Buttons/PrimaryButton";
import { ReactComponent as CloseBtnSvg } from '@assets/Icons/close-button-icon.svg';
import { StringInput } from "@core/VisualComponents/Inputs/StringInput/StringInput";
import { SecondaryButton } from "@core/VisualComponents/Buttons/SecondaryButton";
import { AIApi } from "@core/AI/ai-api";
import { accessTokenKey } from "@core/Constants/app-storage-keys";
import { getValueFromLocalStorage } from "@core/Hooks/use-local-storage";
import { saveConfig} from '@core/Redux/Slices/appSlice';
import { useAppSelector, useAppDispatch } from "@core/Redux/hooks";
import { selectTenantConfig, selectUserInfo } from "@core/Redux/store";
import { useTranslation } from "react-i18next";
import { FieldsManagerTool } from "@core/AI/fieldsManager";
import { userApiSaveJsonConfig } from "@core/Api/user-api";
import { INewUserInfo } from "src/App/AppLayouts/Shared/AIChat/Components/NewUserForm/NewUserForm";
import { ReactComponent as AddButtonFilledSvg } from '@assets/Icons/add-button-filled-icon.svg';
import { t } from "i18next";
import { useLocation } from "react-router-dom";
import { tablePath } from "@core/Constants/route-paths";
import { urlIsBasePath } from "src/Utils/urlUtils";
import { useStore } from "@core/Stores/EventSourcingStoreProvider";

export function CrmGridAddColumnButton() {
    const [showAddColumn, setShowAddColumn] = useState<boolean>(false);
    const addColumnButtonRef = useRef<any>();

    const handleShowAddColumn = () => {
        setShowAddColumn(prev => !prev);
    };

    return <>
        <div ref={addColumnButtonRef} onClick={handleShowAddColumn} className={styles.addColumnButton}>
            <AddButtonFilledSvg/>
            <span>{t("add-column")}</span>
        </div>
        {showAddColumn &&
            <CrmGridAddColumnPanel onHide={() => setShowAddColumn(false)} targetRef={addColumnButtonRef}/>
        }
    </>;
}

interface ICrmGridAddColumnPanelProps {
    onHide: () => void;
    targetRef: MutableRefObject<any>;
}

enum CreationState {
    Init = "Init",
    Loading = "Loading",
    Answer = "Answer",
}

function CrmGridAddColumnPanel(compProps: ICrmGridAddColumnPanelProps) {
    const valueRef = useRef<string | null>(null);
    const [state, setState] = useState<CreationState>(CreationState.Init);
    const [answer, setAnswer] = useState<string | null>(null);
    const { t } = useTranslation();

    let tenantConfig = useAppSelector(selectTenantConfig);
    const userInfo = useAppSelector(selectUserInfo);
    const dispatch = useAppDispatch();

    const location = useLocation();
    const tableConfig = tenantConfig?.tables.find(table => urlIsBasePath(location, tablePath(table.tableId)));
    const tableId = tableConfig?.tableId ?? "orders";

    const store = useStore(tableId);

    const accessToken = getValueFromLocalStorage<string>(accessTokenKey);

    class AddColumnAIApi extends AIApi {
        showNewUserForm(login?: string | undefined, password?: string | undefined): Promise<INewUserInfo> {
            return Promise.reject<INewUserInfo>("Not implemented");
        }
    }

    const keydownHandler = (event: KeyboardEvent) => {
        switch (event.key) {
            case "Escape":
                onHide();
                break;
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", keydownHandler, false);
        return () => {
            document.removeEventListener("keydown", keydownHandler, false);
        };
    }, [keydownHandler]);

    const onCreate = () => {
        setState(CreationState.Loading);

        const api = new AddColumnAIApi(tenantConfig, userInfo!, dispatch, store);
        const agent = new FieldsManagerTool(api, accessToken);

        const description = valueRef.current ?? "";

        agent.run(`${t("ai-prompt-add-column")}: '${description}'`).then(async ans => {
            setAnswer(ans);

            if (api.configChanged) {
                dispatch(saveConfig(api.getOrderConfig()));
                userApiSaveJsonConfig({ config: JSON.stringify(api.getOrderConfig()) });
                onHide();
            }
            else {
                setState(CreationState.Answer);
            }
        });
    };

    const onHide = () => {
        compProps.onHide();
    }

    const onAnswerOk = () => {
        setState(CreationState.Init);
    }

    return (<Overlay
        show={true}
        flip={true}
        placement={'bottom-start'}
        rootClose={true}
        onHide={onHide}
        target={compProps.targetRef}
        popperConfig={popperConfig}
    >
        {({ show, props, arrowProps, placement }) => 
            <div {...props} className={styles.host}>
                <div className={styles.component}>
                    {(state == CreationState.Init || state == CreationState.Loading) && <>
                        <StringInput
                            valueRef={valueRef}
                            onEnter={onCreate}
                            placeholder={t("column-description")}
                            disabled={state == CreationState.Loading}
                            autoFocus
                        />
                        <div className={styles.buttonsPanel}>
                            <div className={styles.spring}></div>
                            <SecondaryButton onClick={onHide}>
                                {t("cancel")}
                            </SecondaryButton>
                            <PrimaryButton onClick={onCreate} disabled={state == CreationState.Loading}>
                                {state == CreationState.Loading ? t("creating") : t("create")}
                            </PrimaryButton>
                        </div>
                    </>}
                    {state == CreationState.Answer && <>
                        <span>{answer}</span>
                        <div className={styles.buttonsPanel}>
                            <div className={styles.spring}></div>
                            <PrimaryButton onClick={onAnswerOk}>
                                {t("ok")}
                            </PrimaryButton>
                        </div>
                    </>}
                    <button type="button"
                            className={styles.closeButton}
                            onClick={onHide}
                            disabled={state == CreationState.Loading}>
                        <CloseBtnSvg />
                    </button>
                </div>
            </div>
        }
    </Overlay>)
}

const popperConfig = {
    modifiers: [
        {
            name: 'preventOverflow',
            options: {
                boundary: 'viewport',
            },
        },
        {
            name: 'flip',
            options: {
                fallbackPlacements: ['left', 'right', 'bottom'],
            },
        },
    ]
};
