import { ICrmField } from "@core/Models/autogenerated/tenantConfig.models";
import { Select } from "antd";
import { useEffect } from "react";
import styles from "./CrmGridAddComboboxFilterDialog.module.scss";

interface IComboboxFilterQuery {
    $in: string[];
}

export interface ICrmGridAddComboboxFilterDialogProps {
    field: ICrmField;
    query: IComboboxFilterQuery | null;
    onChanged: (query: IComboboxFilterQuery | null) => void;
    onEnter?: () => void;
    autoFocus?: boolean;
}

export function CrmGridAddComboboxFilterDialog(props: ICrmGridAddComboboxFilterDialogProps) {
    const keydownHandler = (event: KeyboardEvent) => {
        switch (event.key) {
            case "Enter":
                props.onEnter && props.onEnter();
                break;
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", keydownHandler, false);
        return () => {
            document.removeEventListener("keydown", keydownHandler, false);
        };
    }, [keydownHandler]);

    const onChanged = (values: string[] | string | null) => {
        if (values == null || values.length === 0) {
            props.onChanged(null);
        }
        else {
            props.onChanged({
                $in: Array.isArray(values) ? values : [values],
            });
        }
    }

    const getSelectedValues = () => {
        return props.field.options?.filter(x => props.query?.$in.includes(x.value)).map(x => x.value);
    }

    return <div className={styles.host} onClick={e => e.stopPropagation()}>
        <Select
            mode="multiple"
            options={props.field.options!}
            defaultValue={getSelectedValues() ?? null}
            placeholder={props.field.placeholder}
            onChange={onChanged}
            allowClear
            style={{width: "100%"}}
            placement="topLeft"
            autoFocus={props.autoFocus}
        />
    </div>;
}