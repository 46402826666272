import { Route, Routes } from 'react-router-dom';
import { CreateOrEditEntityPage } from './CreateOrEditEntityPage';
import { ListOrdersPage } from './ListOrdersPage';
import { entityEditPathMask } from '@core/Constants/route-paths';

export function OrdersPage(props: {tableId: string}) {
    return (
        <Routes>
            <Route path="" element={<ListOrdersPage tableId={props.tableId}/>} />
            <Route path="create/*" element={<CreateOrEditEntityPage tableId={props.tableId} isCreating={true}/>} />
            <Route path={entityEditPathMask} element={<CreateOrEditEntityPage tableId={props.tableId} isCreating={false}/>} />
        </Routes>
    );
}
