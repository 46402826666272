export class EventHandler<T> {
    private onStateChangedHandlers: Array<(arg:T)=>Promise<void>> = [];

    public add(handler: (arg:T)=>Promise<void>): void {
        this.onStateChangedHandlers.push(handler);
    }
    public remove(handler: (arg:T)=>Promise<void>): void {
        this.onStateChangedHandlers = this.onStateChangedHandlers.filter(h => h !== handler);
    }
    public async trigger(arg: T): Promise<void> {
        for (let h of this.onStateChangedHandlers.slice(0))
            await h(arg);
    }
}