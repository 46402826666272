import styles from './SigninPage.module.scss';
import {useContext, useEffect, useState} from 'react';
import {PrimaryButtonRounded} from '@core/VisualComponents/Buttons/PrimaryButtonRounded';
import {authApiSignin} from '@core/Api/auth-api';
import {StatusCode} from '@core/Constants/http-status-codes';
import {AxiosError} from 'axios';
import {AccessTokenDispatchContext} from '@core/Contexts/AccessTokenContext';
import Logger from 'js-logger';
import {serializeError} from 'serialize-error';
import {useAppDispatch} from '@core/Redux/hooks';
import {initApplicationAsync} from '@core/Redux/Slices/appSlice';
import {useTranslation} from "react-i18next";
import { Input, Button } from 'antd';
import Title from 'antd/es/skeleton/Title';

declare var location: any;

export function SigninPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const setAccessToken = useContext(AccessTokenDispatchContext);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const isFormValid = () => {
        return login && login !== ''
            && password && password !== ''
    };

    useEffect(() => {
        let search = location.search;
        let params = new URLSearchParams(search);

        let login = params.get('login')!;
        if (login)
            setLogin(login);

        let password = params.get('password')!;
        if (password)
            setPassword(password);
    }, []);

    const onSigninClicked = () => {
        if (!isFormValid) {
            alert(t("login_password_must_not_be_empty_error"));
        }

        const signin = async () => {
            const resp = await authApiSignin({login, password});
            setAccessToken(resp.accessToken);

            // TODO не получается красиво обновить базу без перезагрузки страницы.
            // Падает OperationEventsPoller c ошибкой DatabaseClosedError
            await dispatch(initApplicationAsync());
            location.pathname = '/';
        }

        signin().catch((err: AxiosError) => {
            const statusCode = err.response?.status;
            if (statusCode === StatusCode.ClientErrorUnauthorized) {
                alert(t("invalid_login_or_password_error"));
                return;
            }

            Logger.error('Неверный логин или пароль',serializeError(err, { maxDepth: 2}));
        });
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            onSigninClicked();
        }
    };

    return (
        <div className={styles.host}>
        <div className={styles.loginFormWrapper}>
            <h1 className={styles.title}>{t("pleaseLogin")}</h1>
            <div className={styles.loginForm}>
                <div className={styles.inputFields}>
                    <div className={styles.inputWrapper}>
                        <span>{t("login")}</span>
                            <Input
                                className={styles.input}
                                placeholder={t("login") ?? ""}
                                type="text"
                                id="login"
                                value={login}
                                onKeyDown={handleKeyDown}
                                onChange={e => setLogin(e.target.value)}
                            />
                    </div>
                    <div className={styles.inputWrapper}>
                        <span>{t("password")}</span>
                            <Input.Password
                                className={styles.input}
                                placeholder={t("password") ?? ""}
                                id="password"
                                value={password}
                                onKeyDown={handleKeyDown}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </div>
                    </div>
                    <Button
                        type="primary"
                        className={styles.signinButton}
                        onClick={onSigninClicked}
                    >
                        {t("toLogIn")}
                    </Button>
                </div>
            </div>
        </div>
    );
}
