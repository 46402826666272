import { DateInput } from "@core/VisualComponents/Inputs/DateInput/DateInput";
import styles from "./CrmGridAddDateFilterDialog.module.scss";
import { MutableRefObject, useEffect, useState } from "react";
import { t } from "i18next";
import { ICrmField } from "@core/Models/autogenerated/tenantConfig.models";

interface IDateFilterQuery {
    $gte?: number;
    $lt?: number;
}

export interface ICrmGridAddDateFilterDialogProps {
    field: ICrmField;
    query: IDateFilterQuery | null;
    onChanged: (query: IDateFilterQuery | null) => void;
    onEnter?: () => void;
    autoFocus?: boolean;
    withTime?: boolean;
}

export function CrmGridAddDateFilterDialog(props: ICrmGridAddDateFilterDialogProps) {
    const [currQuery, setCurrQuery] = useState<IDateFilterQuery | null>(props.query);

    const keydownHandler = (event: KeyboardEvent) => {
        switch (event.key) {
            case "Enter":
                props.onEnter?.();
                break;
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", keydownHandler, false);
        return () => {
            document.removeEventListener("keydown", keydownHandler, false);
        };
    }, [keydownHandler]);

    const onChangedLeft = (value: number | null) => {
        let newQuery = currQuery;

        if (value == null || isNaN(value)) {
            if (newQuery?.$lt == null) {
                newQuery = null;
            }
            else {
                delete newQuery.$gte;
            }
        }
        else {
            newQuery = currQuery ?? {};
            newQuery.$gte = value;
        }

        setCurrQuery(newQuery);
        props.onChanged(newQuery);
    }

    const onChangedRight = (value: number | null) => {
        let newQuery = currQuery;

        if (value == null || isNaN(value)) {
            if (newQuery?.$gte == null) {
                newQuery = null;
            }
            else {
                delete newQuery.$lt;
            }
        }
        else {
            newQuery = newQuery ?? {};
            newQuery.$lt = value + getDiff(props.withTime);
        }

        setCurrQuery(newQuery);
        props.onChanged(newQuery);
    }

    return <div className={styles.container}>
        <div>
            <DateInput
                withTime={props.withTime}
                placeholder={t("from")}
                initialValue={currQuery?.$gte}
                onChanged={onChangedLeft}
                autoFocus={props.autoFocus}
            />
        </div>
        <span>–</span>
        <div>
            <DateInput
                withTime={props.withTime}
                placeholder={t("to")}
                initialValue={currQuery?.$lt ? currQuery.$lt - getDiff(props.withTime) : null}
                onChanged={onChangedRight}
            />
        </div>
    </div>;
}

function getDiff(withTime: boolean = false): number {
    return withTime ? OneMinuteInSeconds : OneDayInSeconds;
}

const OneDayInSeconds = 60 * 60 * 24;
const OneMinuteInSeconds = 60;