import {CSSProperties, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styles from './DateInput.module.scss';
import { registerComponent } from '@core/Plugins/pluginManager';
import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';
import { InputValidator } from '../InputValidator/InputValidator';
import { isEndOfDay } from '@core/Helpers/js-date-transformations';

import { DatePicker } from "antd";
import dayjs from 'dayjs';
import { t } from 'i18next';
import { InvalidSpan } from '../InvalidHint/InvalidHint';
import { DateTime } from 'luxon';

export interface IDateInputProps {
    initialValue?: number | null;
    placeholder?: string;
    onChanged?: (value: number | null) => void;
    className?: string;
    setIsValid?: (isValid: boolean) => void;
    style?: CSSProperties;
    readonly?: boolean;
    validation?: (value: any) => boolean;
    autoFocus?: boolean;
    withTime?: boolean;
    staticHostRef?: MutableRefObject<any>;
    onConfirmed?: (value: number | null) => void;
}

export const DateInput = registerComponent(coreUiComponentDescriptions.DateInput, _DateInput);

function _DateInput(props: IDateInputProps) {
    const [isOpen, setIsOpen] = useState<boolean>(!!props.staticHostRef);
    
    const isValidValue = (value: any) => {
        if (props.validation) {
            return props.validation(value);
        }

        return InputValidator.validateDecimal(props.initialValue);
    }

    const [isValid, setIsValid] = useState<boolean>(isValidValue(props.initialValue));

    const [dateValue, setDateValue] = useState(props.initialValue != null && isValid
        ? dayjs(getLocalizedDateFromSeconds(props.initialValue as any))
        : null);

    useEffect(() => {
        if (props.setIsValid) {
            props.setIsValid(isValid);
        }
    }, [isValid]);

    const getNumberValue = (date: dayjs.Dayjs | null) => {
        if (date == null) {
            return null;
        }

        if (isEndOfDay(date.toDate())) {
            return asUtcToUnixTime(date.toDate());
        }
        if (props.withTime) {
            return asUtcToUnixTimeRoundedToMinute(date.toDate());
        }
        return asUtcToUnixTimeRoundedToDay(date.toDate());
    }
    
    const onChange = useCallback((date: dayjs.Dayjs | null) => {
        setIsValid(true);
        setDateValue(date);

        props.onChanged?.(getNumberValue(date));
    }, [props.onChanged, getNumberValue]);

    const handleClear = (e: any) => {
        onChange(null);
    };

    const handleClearTime = (e: any) => {
        const newDate = dayjs(dateValue)
            .hour(23)
            .minute(59)
            .second(59);

        onChange(newDate);
    }

    const handleToday = () => {
        onChange(dayjs());
    }

    const handleConfirm = () => {
        setIsOpen(false);

        props.onConfirmed?.(getNumberValue(dateValue));
    }

    const handleOpenChange = (open: boolean) => {
        if (!open) {
            setIsOpen(false);
        }
    }

    const onPickerValueChange = (date: dayjs.Dayjs, info: any) => {
        if (info.source !== 'reset') {
            onChange(date);
        }
    }

    return <div onClick={e => e.stopPropagation()}>
        <DatePicker
            onClick={() => setIsOpen(true)}
            onOpenChange={handleOpenChange}
            open={isOpen}
            showTime={props.withTime ? { format: 'HH:mm', defaultValue: dayjs('23:59:59', 'HH:mm:ss') } : false}
            value={dateValue?.isValid() ? dateValue : undefined}
            onChange={onChange}
            format={getLocalizedDateFormat(props.withTime)}
            className={styles.host}
            placeholder={isValid ? props.placeholder : t("error")}
            status={isValid ? undefined : "error"}
            showNow={false}
            autoFocus={props.autoFocus}
            renderExtraFooter={() =>
                <div className={styles.extraFooter}>
                    {/* <span onClick={handleToday}>{props.withTime ? t("now") : t("today")}</span> */}
                    <span onClick={handleClear}>{t("clear")}</span>
                    {props.withTime && <span onClick={handleClearTime}>{t("clear-time")}</span>}
                    <span className={styles.confirm} onClick={handleConfirm}>{t("ok")}</span>
                </div>
            }
            preserveInvalidOnBlur
            disabled={props.readonly}
            needConfirm={false}
            popupStyle={props.staticHostRef
                ? { position: "static" }
                : undefined
            }
            style={props.staticHostRef
                ? { display: 'none' }
                : undefined
            }
            getPopupContainer={props.staticHostRef ? () => props.staticHostRef?.current : undefined}
            onPickerValueChange={onPickerValueChange}
        />
        {isValid ||
            <InvalidSpan/>
        }
    </div>;
}

function getLocalizedDateFormat(withTime: boolean = false): string {
    const now = new Date(2000, 11, 31); // Используем фиксированную дату для предсказуемого анализа
    const formatter = new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });

    const parts = formatter.formatToParts(now);

    const formatMap: { [key: string]: string } = {
        day: 'DD',
        month: 'MM',
        year: 'YYYY'
    };

    const dateFormat = parts
        .map(part => formatMap[part.type] || part.value)
        .join('');

    // dateFormat может выглядеть как "YYYY/MM/dd" или "dd.MM.YYYY" в зависимости от локали
    if (withTime) {
        return `${dateFormat} HH:mm`;
    }
    return dateFormat;
}

function asUtcToUnixTimeRoundedToDay(date: Date): number {
    return DateTime
        .fromJSDate(date)
        .setZone('UTC', { keepLocalTime: true })
        .startOf('day')
        .toUnixInteger();
}

function asUtcToUnixTimeRoundedToMinute(date: Date): number {
    return DateTime
        .fromJSDate(date)
        .setZone('UTC', { keepLocalTime: true })
        .startOf('minute')
        .toUnixInteger();
}

function asUtcToUnixTime(date: Date): number {
    return DateTime
        .fromJSDate(date)
        .setZone('UTC', { keepLocalTime: true })
        .toUnixInteger();
}

function getLocalizedDateFromSeconds(seconds: number): Date {
    return DateTime
        .fromSeconds(seconds)
        .setZone('UTC')
        .setZone(DateTime.local().zoneName, { keepLocalTime: true })
        .startOf('minute')
        .toJSDate();
}
