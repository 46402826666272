import { IEsNodeConfig } from "./Models/autogenerated/tenantConfig.models";

declare var location: any;

export function eventSourcingRpc(esnode: IEsNodeConfig|null): IEsNodeConfig {
    //todo: move this setting to .env.*
    if (esnode != null && esnode.app_host != null && location.host == esnode.app_host)
        return esnode;

    let enabled = false;
    try {
        const enabled_str = localStorage.getItem('eventSourcingRpcEnabled');
        enabled = enabled_str && JSON.parse(enabled_str as string);
    } catch {
    }

    const url = localStorage.getItem("eventSourcingRpcUrl");
    if (enabled && url) {
        return {enabled, url};
    }
    return {enabled: false, url: ""};
}
