import styles from './MobileMenuButton.module.scss';
import { ReactElement } from "react";

interface MobuleMenuButtonProps {
    svg: ReactElement,
    caption: string,
    onClick: () => void,
    selected? : boolean;
}

export function MobileMenuButton(props: MobuleMenuButtonProps) {
    return (
        <div className={styles.mobileMainMenuButton}>
            <div className={styles.mobileMainMenuButtonInner}>
                <button onClick={props.onClick}
                    className={styles.mainMenuButton + " "+ (props.selected? styles.selectedButton: styles.unselectedButton)}>
                    <div className={styles.mainMenuButtonIcon}>
                        {props.svg}
                    </div>
                    <span>{props.caption}</span>
                </button>
            </div>
        </div>
    );
}