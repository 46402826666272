import { IQuerySpecification } from "@core/EventSourcing/Implementation/LiveQuery";
import { WhereSpecification } from "@core/EventSourcing/Implementation/LiveQuery";
import {CrmFilterType, CrmFilterValueType, ICrmFilterCondition, ICrmFilterRangeValue} from '@core/Models/tenantConfig.models';
import {IWhereQuery} from 'jsstore';
import {DateTime} from 'luxon';

function applyConstantCondition(whereQuery: IWhereQuery, condition: ICrmFilterCondition): void {
    if (condition.values) {
        (whereQuery as any)[condition.fieldName] = { $in: condition.values };
    } else {
        (whereQuery as any)[condition.fieldName] = condition.value;
    }
}

function applySubstringCondition(whereQuery: IWhereQuery, condition: ICrmFilterCondition): void {
    if (condition.value) {
        (whereQuery as any)[condition.fieldName] = { $containsString: condition.value };
    }
}

// function prepareStartOfDayPlusDayDiffValue(dayDiff: number, utcNow: DateTime): number {
//     return utcNow
//         .startOf('day')
//         .plus({days: dayDiff})
//         .toUnixInteger();
// }

function prepareLtRangeValue(rangeValue: ICrmFilterRangeValue, utcNow: DateTime): any {
    if (!rangeValue.ltValueType || rangeValue.lt  == null) {
        return undefined;
    }
    switch (rangeValue.ltValueType) {
        case CrmFilterValueType.StartOfDayPlusDayDiff:
            let shift = parseInt(rangeValue.lt);
            let sign = shift < 0 ? '-' : '+';
            return {$time: `$now.startOfDay ${sign} ${Math.abs(shift)}d`};
        case CrmFilterValueType.Date:
            return DateTime.fromISO(rangeValue.lt);    
        case CrmFilterValueType.Numeric:
            return parseInt(rangeValue.lt);
        case CrmFilterValueType.String:
            return rangeValue.lt;
        default:
            throw new Error('Unsupported value type in ICrmFilterRangeValue');
    }
}

function prepareGteRangeValue(rangeValue: ICrmFilterRangeValue, utcNow: DateTime): any {
    if (!rangeValue.gteValueType || rangeValue.gte == null) {
        return undefined
    }
    switch (rangeValue.gteValueType) {
        case CrmFilterValueType.StartOfDayPlusDayDiff:
            //return prepareStartOfDayPlusDayDiffValue(parseInt(rangeValue.gte), utcNow);
            let shift = parseInt(rangeValue.gte);
            let sign = shift < 0 ? '-' : '+';
            return {$time: `$now.startOfDay ${sign} ${Math.abs(shift)}d`};
        case CrmFilterValueType.Date:
            return DateTime.fromISO(rangeValue.gte);
        case CrmFilterValueType.Numeric:
            return parseInt(rangeValue.gte);
        case CrmFilterValueType.String:
            return rangeValue.gte;
        default:
            throw new Error('Unsupported value type in ICrmFilterRangeValue');
    }
}

function applyRangeCondition(whereQuery: IWhereQuery, condition: ICrmFilterCondition, utcNow: DateTime): void {
    let query: any = {};

    const gte = prepareGteRangeValue(condition.rangeValue!, utcNow);
    const lt = prepareLtRangeValue(condition.rangeValue!, utcNow);

    if (gte != null) {
        query['$gte'] = gte;
    }

    if (lt != null) {
        query['$lt'] = lt;
    }

    (whereQuery as any)[condition.fieldName] = query;
}

function applyNonEmptyCondition(whereQuery: WhereSpecification, condition: ICrmFilterCondition, utcNow: DateTime): void {
    (whereQuery as any)[condition.fieldName] = { $exists: true }
}

function applyCondition(whereQuery: WhereSpecification, condition: ICrmFilterCondition, utcNow: DateTime): void {
    switch (condition.valueType) {
        case CrmFilterType.Constant:
            applyConstantCondition(whereQuery, condition);
            break;
        case CrmFilterType.Substring:
            applySubstringCondition(whereQuery, condition);
            break;
        case CrmFilterType.Range:
            applyRangeCondition(whereQuery, condition, utcNow);
            break;
        case CrmFilterType.NonEmpty:
            applyNonEmptyCondition(whereQuery, condition, utcNow);
            break;
        default:
            throw new Error('Unsupported CrmFilterValueType');
    }
}

export function crmFilterToWhereClause(conditions: ICrmFilterCondition[] | null): WhereSpecification;
export function crmFilterToWhereClause(conditions: ICrmFilterCondition[] | null, utcNow: DateTime): WhereSpecification | null;
export function crmFilterToWhereClause(conditions: ICrmFilterCondition[] | null, utcNow?: DateTime): WhereSpecification | null {
    if (!conditions || conditions.length === 0)
        return {};

    if (utcNow === undefined)
        utcNow = DateTime.utc();

    const whereQuery: IWhereQuery = {};
    for (let condition of conditions) {
        applyCondition(whereQuery, condition, utcNow);
    }
    return whereQuery;
}
