import { IDbEntity } from "@core/JsStore/stores/shared/models/i-db-entity";
import { EntitySetChanges } from "./EventSourcingStore";
import { KVStore } from "@core/Stores/KVStore";
import { EntitySnapshotStore } from "@core/Stores/EntitySnapshotStore";
import { useEffect, useState } from "react";
import { IEntity } from "@core/Models/i-entity";
import { IEventSouringQueryViewState } from "./Implementation/LiveQueryView";
import { IQuerySpecification } from "./Implementation/LiveQuery";
import Logger from "js-logger";
import axios from "axios";
import { getValueFromLocalStorage } from "@core/Hooks/use-local-storage";
import { accessTokenKey } from "@core/Constants/app-storage-keys";
import { EventSourcingClientBase } from "./EventSourcingClientBase";
import { ICrmOperationEventDecoded, ICrmOperationEventDecodedWithLastEventInfo } from "@core/Models/i-crm-operation-event-decoded";
import i18next from "src/Locale/i18n";
import { ICrmField } from "@core/Models/autogenerated/tenantConfig.models";
import { IBulkUpdateResult } from "./BulkUpdateApi";
import { QueryVars } from "@core/Utils/MongoQueryParser";

export class EventSourcingStoreReactRpcClient extends EventSourcingClientBase {
    public static getSchemas(name: string, fields: ICrmField[]) {
        let result = [
            ...EntitySnapshotStore.getSchemas(name, fields),
            ...KVStore.getSchemas(name + '_meta')
        ];
        return result;
    }

    methodUrl: (method:string) => string;
    tableId: string;
    currentCount: number = 0;

    private onUpdateEntityHandlers: Array<(oldEntity: IEntity, newEntity: IEntity) => Promise<void>> = [];
    private onNewEntityHandlers: Array<(entity: IEntity) => Promise<void>> = [];
    private onRemoveEntityHandlers: Array<(entity: IEntity) => Promise<void>> = [];
    private onEntitySetChangedHandlers: Array<(changes: EntitySetChanges) => Promise<void>> = [];

    constructor(
        tableId: string
        , tenant: string
        , clientInstance: string
        , url: string
    ) {
        super(tableId, tenant, clientInstance);

        this.methodUrl = (method:string) => `${url}/store/${method}`;
        this.tableId = tableId;
    }

    public dispose(): void {
    }

    public async get(entityId: string): Promise<IEntity | null> {
        const resp = await axios.post(this.methodUrl("get"), {tableId: this.tableId, entityId});
        return resp.data;
    }

    public async bulkGet(ids: string[]): Promise<(IEntity|null)[]> {
        const resp =  await axios.post(this.methodUrl("bulkGet"), {tableId: this.tableId, ids});
        return resp.data;
    }

    public async count(): Promise<number> {
        return '...' as any; //todo:
    }

    clear(): Promise<void> {
        throw new Error("Method not implemented.");
    }

    protected async propagateEvent(event: ICrmOperationEventDecoded): Promise<void> {
        //todo: remove broken event
        if (event.entityId == null)
            return;
        const resp = await axios.post(this.methodUrl("propagateEvent"), {tableId: this.tableId, event, client: this.clientInstance});
    }
    
    protected async propagateEvents(events: ICrmOperationEventDecodedWithLastEventInfo[]): Promise<IBulkUpdateResult> {
        const resp = await axios.post(this.methodUrl("propagateEvents"), {tableId: this.tableId, events, client: this.clientInstance});
        return resp.data;
    }

    public async queryIds(query: IQuerySpecification, queryVars: QueryVars): Promise<string[]> {
        const resp = await axios.post(this.methodUrl("queryIds"), {tableId: this.tableId, query, queryVars, client: this.clientInstance});
        return resp.data;
    }

    public async queryEntities(query: IQuerySpecification, queryVars: QueryVars, limit?: number): Promise<IEntity[]> {
        const resp = await axios.post(this.methodUrl("queryEntities"), {tableId: this.tableId, query, queryVars, limit, client: this.clientInstance});
        return resp.data;
    }

    public useGet = (entityId: string): IEntity | null => {
        const [entity, setEntitity] = useState<IEntity | null>(null);

        useEffect(() => {
            if (entityId == null)
                return;

            const accessToken = getValueFromLocalStorage<string>(accessTokenKey);
            const req = { tableId: this.tableId, entityId };

            const params = {
                token: accessToken,
                req: JSON.stringify(req),
              };
              
              // Create a query string
            const queryString = new URLSearchParams(params).toString();

            const eventSource = new EventSource(this.methodUrl('useGet') + `?${queryString}`);

            // Listen for the 'message' event
            eventSource.onmessage = (event) => {
                const update = JSON.parse(event.data);
                switch (update.event) {
                    case "Update":
                        setEntitity(update.entity);
                        break;
                }
            };

            // Listen for the 'end' event
            eventSource.addEventListener('end', (event) => {
                Logger.debug('Stream ended:', event.data);
                eventSource.close();
            });

            // Handle errors
            eventSource.onerror = (error) => {
                Logger.error('Error with SSE useGet:', error);
            };

            return () => {
                eventSource.close();
            }
        }, [this, entityId]);

        return entity;
    }

    public preloadLiveQuerieMonitors(queries: IQuerySpecification[]) {
    }

    public disposeLiveQueryMonitorByQuery(query: IQuerySpecification) {
    }

    public async process(abortController: AbortController) : Promise<void> {
        await super.process(abortController);
    }

    public useQuerySubscribe = (query: IQuerySpecification | null, queryVars: QueryVars, skip: number, limit: number, sortAscending: boolean
        , onArrayUpdate: (entities: IEntity[]) => void
        , onIndividualUpdate: (entities: IEntity[]) => void
        , onNewState: (state: IEventSouringQueryViewState) => void) => {


        useEffect(() => {
            if (query == null)
                return;

            const accessToken = getValueFromLocalStorage<string>(accessTokenKey);
            const req = { tableId: this.tableId, query, queryVarsRaw: Object.fromEntries(queryVars), skip, limit, sortAscending };

            const params = {
                token: accessToken,
                req: JSON.stringify(req),
              };
              
              // Create a query string
            const queryString = new URLSearchParams(params).toString();

            let eventSource: EventSource;

            onNewState({status: "loading", resultCount: 0});
            onArrayUpdate([]);

            const connect = () => {
                eventSource = new EventSource(this.methodUrl('useQuerySubscribe') + `?${queryString}`);

                eventSource.onmessage = (event) => {
                    const update = JSON.parse(event.data);
                    switch (update.event) {
                        case "ArrayUpdate":
                            this.currentCount = update.count;
                            onArrayUpdate(update.entities);
                            break;
                        case "IndividualUpdate":
                            onIndividualUpdate(update.entities);
                            break;
                        case "StateUpdate":
                            onNewState(update.state);
                            break;
                    }
                };

                eventSource.onerror = (error) => {
                    Logger.warn('[useQuerySubscribe] Error with SSE', error);
                    onNewState({status: "error", resultCount: 0, error: i18next.t("error_no_server_connection")});
                    //console.error('Error with SSE:', error);
                    eventSource.close();
                    setTimeout(connect, 1000);
                };
            }

            connect();

            return () => {
                eventSource.close();
            }
        }, [this, query, skip, limit, sortAscending]);
    }

    public onUpdateEntity(handler: (oldEntity: IEntity, newEntity: IEntity) => Promise<void>): void {
        this.onUpdateEntityHandlers.push(handler);
    }
    public onUpdateEntityRemove(handler: (oldEntity: IEntity, newEntity: IEntity) => Promise<void>): void {
        this.onUpdateEntityHandlers = this.onUpdateEntityHandlers.filter(h => h !== handler);
    }
    private async triggerOnUpdateEntity(oldEntity: IEntity, newEntity: IEntity): Promise<void> {
        for (let h of this.onUpdateEntityHandlers.slice(0))
            await h(oldEntity, newEntity);
    }

    public onNewEntity(handler: (entity: IEntity) => Promise<void>): void {
        this.onNewEntityHandlers.push(handler);
    }
    public onNewEntityRemove(handler: (entity: IEntity) => Promise<void>): void {
        this.onNewEntityHandlers = this.onNewEntityHandlers.filter(h => h !== handler);
    }
    private async triggerOnNewEntity(entity: IEntity): Promise<void> {
        for (let h of this.onNewEntityHandlers.slice(0))
            await h(entity);
    }

    public onRemoveEntity(handler: (entity: IEntity) => Promise<void>): void {
        this.onRemoveEntityHandlers.push(handler);
    }
    public onRemoveEntityRemove(handler: (entity: IEntity) => Promise<void>): void {
        this.onRemoveEntityHandlers = this.onRemoveEntityHandlers.filter(h => h !== handler);
    }
    private async triggerOnRemoveEntity(entity: IEntity): Promise<void> {
        for (let h of this.onRemoveEntityHandlers.slice(0))
            await h(entity);
    }

    public onEntitySetChanged(handler: (changes: EntitySetChanges) => Promise<void>): void {
        this.onEntitySetChangedHandlers.push(handler);
    }
    public onEntitySetChangedRemove(handler: (changes: EntitySetChanges) => Promise<void>): void {
        this.onEntitySetChangedHandlers = this.onEntitySetChangedHandlers.filter(h => h !== handler);
    }
    private async triggerOnEntitySetChanged(changes: EntitySetChanges): Promise<void> {
        for (let h of this.onEntitySetChangedHandlers.slice(0))
            await h(changes);
    }
}