import { ICrmCommentMessage } from "@core/Models/i-comment-message";
import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import _ from 'lodash';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import styles from './CommentsEditView.module.scss';
import { extractComments } from "src/Utils/commentsUtils";
import { useStore } from "@core/Stores/EventSourcingStoreProvider";
import { useAppSelector } from "@core/Redux/hooks";
import { selectUserInfo } from '@core/Redux/store';
import { CommentsMessage } from "@core/VisualComponents/Comments/CommentsMessage";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import { ReactComponent as SendMessageIconSvg } from '@assets/Icons/send-message-icon.svg';
import { Input, Spin } from "antd";
import { ICrmField } from "@core/Models/autogenerated/tenantConfig.models";
import { t } from "i18next";

interface ICommentsEditViewProps {
    field: ICrmField;
    tableId: string;
    entityId: string;
    isReversed?: boolean;
    style?: CSSProperties;
}

export function CommentsEditView(props: ICommentsEditViewProps) {
    const [inputValue, setInputValue] = useState<string>('');
    const [comments, setComments] = useState<ICrmCommentMessage[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const chatDialogRef = useRef<HTMLInputElement>(null);
    const store = useStore(props.tableId);
    const userInfo = useAppSelector(selectUserInfo);

    const entity = store.useGet(props.entityId);

    useEffect(() => {
        let comments = entity?.data[props.field.id] ?? [];
        setComments(sortComments(extractComments(comments)));

        if (entity) {
            setIsLoading(false);
        }
    }, [entity, props.field.id]);

    const sortComments = (comments: ICrmCommentMessage[]) => {
        return _.sortBy(comments, ['date', 'id']);
    }

    const storeComment = (comment: ICrmCommentMessage) => {
        let newComments = [...comments, comment];
        store.updateArray(props.entityId, props.field.id, comments, newComments);
    }

    const removeComment = (comment: ICrmCommentMessage) => {
        let newComments = comments.filter(x => x.id !== comment.id);
        store.updateArray(props.entityId, props.field.id, comments, newComments);
    }

    const addMessage = () => {
        const commentItem = {
            id: uuidv4(),
            author: userInfo?.login,
            date: DateTime.utc().toUnixInteger(),
            message: inputValue.trim(),
        } as ICrmCommentMessage;
        
        setInputValue('');

        storeComment(commentItem);
    };

    const isAddMessageAllowed = useCallback(() => {
        return !isLoading && !!inputValue && inputValue.trim().length > 0;
    }, [inputValue, isLoading])

    const handleAddMessage = useCallback(() => {
        if (isAddMessageAllowed()) {
            addMessage();
        }
    }, [isAddMessageAllowed]);

    const scrollDown = () => {
        chatDialogRef.current?.lastElementChild?.scrollIntoView({ block: props.isReversed ? "end" : "start"});
    };

    useEffect(() => {
        scrollDown();
    }, [comments.length]);

    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputValue(e.target.value);
    }

    const renderMessage = (message: ICrmCommentMessage) => {
        const canDelete = userInfo?.role == "SuperUser" || userInfo?.login == message.author;

        return (
            <CommentsMessage
                key={message.id}
                text={message.message.trim()}
                date={message.date}
                author={message.author}
                onDelete={canDelete ? () => removeComment(message) : undefined}
            />
        );
    };

    const handleKeyPress = (event: any) => {
        // Check if the pressed key is "Enter", and that the shift key is not held down.
        if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey) {
            // Prevent the default action to stop the form from submitting
            event.preventDefault();
            handleAddMessage();
        }
    }
    
    return (
        <div className={`${styles.chatWindow} ${props.isReversed && styles.reversed}`} style={props.style}>
            <div className={`${styles.chatDialog} ${props.isReversed && styles.reversed}`} ref={chatDialogRef}>
                {isLoading
                    ? <div className={styles.loadingContainer}>
                        <Spin size='large'/>
                        <span>{t("loading_status")}</span>
                    </div>
                    : comments.map((message) => renderMessage(message))
                }
            </div>
            <div className={styles.inputContainer}>
                <Input.TextArea
                    value={inputValue}
                    onKeyDown={handleKeyPress}
                    onChange={onChange}
                    placeholder={props.field.placeholder}
                    disabled={props.field.readonly || isLoading}
                    allowClear
                    autoFocus
                    autoSize={{maxRows: 4}}
                />
                <PrimaryButton style={{ padding: "6px 16px" }} onClick={handleAddMessage} disabled={props.field.readonly || isLoading}>
                    <SendMessageIconSvg style={{ height: "100%" }} className='addMessageIcon'/>
                </PrimaryButton>
            </div>
        </div>
    );
}