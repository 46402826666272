import { IAutocompleteStore } from "@core/Shared/AutocompleteStore";
import axios from "axios";

export class RpcAutocompleteStore implements IAutocompleteStore {
    methodUrl: (method:string) => string;
    tableId: string;


    constructor(tableId: string, url: string) {
        this.methodUrl = (method:string) => `${url}/autocomplete/${method}`;
        this.tableId = tableId;
    }

    public async getCachedValuesFromDumbCrmDb(fieldName: string): Promise<string[]> {
        const resp = await axios.post(this.methodUrl("getCachedValuesFromDumbCrmDb"), {tableId: this.tableId, fieldName});
        return resp.data;
    }
}