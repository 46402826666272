import { CSSProperties, MutableRefObject, useEffect } from "react";
import styles from "./CrmEditOverlay.module.scss";
import { Overlay } from "react-overlays";
import { PrimaryButton } from "@core/VisualComponents/Buttons/PrimaryButton";
import { t } from "i18next";
import { ReactComponent as CloseBtnSvg } from '@assets/Icons/close-button-icon.svg';
import { registerComponent } from "@core/Plugins/pluginManager";
import { coreUiComponentDescriptions } from "@pluginShared/core-ui-api";

export interface ICrmEditOverlayProps {
    onSave: () => void;
    onHide: () => void;
    style?: CSSProperties;
    targetRef: MutableRefObject<any>;
    disabledSave?: boolean;

    children?: any;
}

export const CrmEditOverlay = registerComponent(coreUiComponentDescriptions.CrmEditOverlay, _CrmEditOverlay);

function _CrmEditOverlay(compProps: ICrmEditOverlayProps) {
    const popperConfig = {
        modifiers: [
            {
                name: 'preventOverflow',
                options: {
                    boundary: 'viewport',
                },
            },
            {
                name: 'flip',
                options: {
                    fallbackPlacements: ['left', 'right', 'bottom'],
                },
            },
        ]
    };

    const onKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            compProps.onHide();
        }

        if (event.key === "Enter") {
            if (!compProps.disabledSave) {
                onSave();
            }
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown, false);
        return () => {
            document.removeEventListener("keydown", onKeyDown, false);
        };
    }, [onKeyDown]);

    const onSave = () => {
        compProps.onSave();
        compProps.onHide();
    }

    return <Overlay
        show
        flip={true}
        placement={'right-start'}
        rootClose={true}
        onHide={compProps.onHide}
        target={compProps.targetRef}
        popperConfig={popperConfig}
    >
        {({ show, props, arrowProps, placement }) => 
            <div {...props} className={styles.host}>
                {compProps.children}
                <div className={styles.buttonsPanel}>
                    <div className={styles.spring}></div>
                    <PrimaryButton onClick={onSave} disabled={compProps.disabledSave}>
                        {t("save")}
                    </PrimaryButton>
                </div>
                <button type="button"
                        className={styles.closeButton}
                        onClick={compProps.onHide}>
                    <CloseBtnSvg />
                </button>
            </div>
        }
    </Overlay>
}