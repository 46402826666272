import { useRef, useState } from "react";
import Overlay from "react-overlays/esm/Overlay";
import styles from "./FullChangesJson.module.scss";
// @ts-ignore
import ReactJsonViewCompare from 'react-json-view-compare';

export interface IFullChangesJsonProps {
    oldData: any;
    newData: any;
}

export function FullChangesJson(componentProps: IFullChangesJsonProps) {
    const [hintVisible, setHintVisible] = useState<boolean>(false);
    const buttonRef = useRef<any>();
    const {oldData, newData} = componentProps;

    const hideHint = () => {
        setHintVisible(false);
    }

    const handleClick = () => {
        setHintVisible(true);
    }

    const popperConfig = {
        modifiers: [
            {
                name: 'preventOverflow',
                options: {
                    boundary: 'viewport',
                },
            },
            {
                name: 'flip',
                options: {
                    fallbackPlacements: ['left', 'right', 'bottom'],
                },
            },
        ]
    };

    return <>
        <div
            ref={buttonRef}
            className={styles.button}
            onClick={handleClick}
        >i</div>
        <Overlay
            show={hintVisible}
            flip={true}
            placement={'left-start'}
            rootClose={true}
            onHide={hideHint}
            target={buttonRef}
            popperConfig={popperConfig}
        >
            {({ show, props, arrowProps, placement }) => 
                <div {...props} className={styles.overlayContent} onClick={e => e.stopPropagation()}>
                    {/* <pre>{JSON.stringify(data, null, 4)}</pre> */}
                    <ReactJsonViewCompare oldData={oldData} newData={newData}/>
                </div>
            }
        </Overlay>
    </>;
}