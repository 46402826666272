import _ from 'lodash';
import { BaseTool, CompositeTool, IApiForAIAgent } from "./ai-api";
import { ListFieldsTool } from './fieldsManager';
import Logger from 'js-logger';

const getFieldManagerPrompt = async (api: IApiForAIAgent) => {
    const fields = await new ListFieldsTool(api).run("");
    const processors = await new ListEventProcessors(api).run("");
    const currentTableId = api.getCurrentSelectedTable()?.tableId;

    return `
You are an AI CRM automation management tool. Automation task implemented with event processors.
Event processors handle external/internal events, create and handle triggers.
You need to call upon various tools to do your job. All inputs must be filled by you using query context and common sense.
Output must describe all successfull operations.

Table currently opened by the user is { "tableId": "${currentTableId}"}

The summary information on installed event processors:
${processors}

The summary information on available tables and fields(in short format) in CRM:
${fields}
`
}

export class EventProcessorManagerTool extends CompositeTool {
    name: string = "event_processor_manager";
    description: string = `
This function is useful when a user wants to automate CRM: create process automatically handling external or internal events, update data on triggers.
This tool must be used only if user explicitly ask for creating event processor (word 'processor' must be mentoned),
The input is a text with a human-readable description of task (preserve user language).
`;

    public getPrompt = (): Promise<string> => getFieldManagerPrompt(this.api);

    toolkit: BaseTool[];

    constructor(api: IApiForAIAgent, access_token : string) {
        super(api, access_token, 'gpt-4o');

        this.toolkit = [
            //new ListBussinesProcess(this.api),
            new ListEventProcessors(this.api),
            new AddEventProcessor(this.api),
            new DeleteEventProcessor(this.api),
        ];
    }

    async run(query: string) : Promise<string> {
        console.log(`*${this.name} ${query}`)

        const agent = await this.createAgent();

        const response = await agent.invoke({
            input: query,
        });

        return response.output;
    }
}

export class AddEventProcessor extends BaseTool {
    name: string = "add_event_processor";
    description: string =
        "useful for when you need generate new event processor. Input must be human readable description of automation task";

    async run(query: string): Promise<string> {
        try {
            const processor = await this.api.addEventProcessor(query);
            return `Event processor {name:'${processor.name}', id:${processor.id}} added`;
        } catch (err: any) {
            Logger.error('AddEventProcessor fail', err);
            if (err.response?.statusText)
                return `fail to add event processor: ${err.response?.statusText}`;

            return `fail to delete event processor with unknown error`;
        } 
    }
}

export class DeleteEventProcessor extends BaseTool {
    name: string = "delete_event_processor";
    description: string =
        "useful for when you need add field(aka column) to CRM. Input is a string: event processor id";

    async run(query: string): Promise<string> {
        const id = query;
        try {
            const response = await this.api.deleteEventProcessor(id);
            return `Event processor with id ${id} successfully deleted`;
        }
        catch(err: any) {
            Logger.error('DeleteEventProcessor fail', err);
            if (err.response?.statusText)
                return `fail to delete event processor with id: ${id}: ${err.response?.statusText}`;

            return `fail to delete event processor with id: ${id}`;
        }
    }
}

export class ListEventProcessors extends BaseTool {
    name: string = "list_event_processors";
    description: string =
        "useful for when you need to get brief summary information about all available event processors in CRM";

    async run(query: string): Promise<string> {
        try {
        const processors = await this.api.listEventProcessors();
        const result = processors.map(processor => ({
            id: processor.id,
            name: processor.name,
            description: processor.description
        }));

        return JSON.stringify(result);
        } catch (err: any) {
            Logger.error('ListEventProcessors fail', err);
            if (err.response?.statusText)
                return `Can't get list of current event processors: ${err.response?.statusText}`;
            return `Can't get list of current event processors due unknown error`;
        }
    }
}
