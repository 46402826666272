import styles from "./ModalWindow.module.scss";
import { CSSProperties, useContext, useEffect, useState } from "react";
import { registerComponent } from '@core/Plugins/pluginManager';
import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';
import { Drawer, Space } from 'antd';
import { t } from 'i18next';
import { PrimaryButton } from '../Buttons/PrimaryButton';
import { SecondaryButton } from '../Buttons/SecondaryButton';
import { DrawerStyles } from "antd/es/drawer/DrawerPanel";
import { DeviceTypeContext } from "@core/Contexts/DeviceTypeContext";

export interface IModalWindowProps {
    title?: string | JSX.Element;
    beforeHide?: () => boolean;
    onHide?: () => void;
    beforeSave?: () => boolean;
    onSave?: () => void;
    children?: any;
    saveButtonText?: string;
    hideMask?: boolean;
    styles?: DrawerStyles;
}

export const ModalWindow = registerComponent(coreUiComponentDescriptions.ModalWindow, _ModalWindow);

function _ModalWindow(props: IModalWindowProps) {
    const [isOpen, setIsOpen] = useState(true);
    const [isOpened, setIsOpened] = useState(false);
    const [isSaveClicked, setIsSaveClicked] = useState<boolean>(false);

    const deviceType = useContext(DeviceTypeContext);

    const escFunction = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            props.onHide?.();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    const afterOpenChange = (open: boolean) => {
        if (open) {
            setIsOpened(true);
        }
        else {
            if (isSaveClicked) {
                props.onSave?.();
                setIsSaveClicked(false);
            }
            else {
                props.onHide?.();
            }
        }
    }

    const onClose = () => {
        if (!props.beforeHide || props.beforeHide()) {
            if (isOpened) {
                setIsOpen(false);
            }
            else {
                props.onHide?.();
            }
        }
    }

    const onSave = () => {
        if (!props.beforeSave || props.beforeSave()) {
            setIsSaveClicked(true);
            setIsOpen(false);
        }
    }

    const buttonsPanel = props.onSave ? <Space>
        <SecondaryButton onClick={onClose}>{t("cancel")}</SecondaryButton>
        <PrimaryButton onClick={onSave}>{props.saveButtonText ?? t("save")}</PrimaryButton>
    </Space> : undefined;

    // We use a fixed window width, but no more than 45% of the page width, to prevent overflow.
    // We use it exactly like this, and not in CSS, so that the push works correctly.
    const width = deviceType.isMobile ? window.innerWidth : Math.min(736, window.innerWidth * .45);

    return <Drawer
        title={props.title && <>
            {typeof props.title == "string"
                ? <h1 className={styles.title}>{props.title}</h1>
                : <div className={styles.header}>{props.title}</div>}
        </>}
        afterOpenChange={afterOpenChange}
        onClose={onClose}
        open={isOpen}
        extra={buttonsPanel}
        footer={buttonsPanel}
        destroyOnClose
        width={width}
        push={{ distance: width }}
        mask={!props.hideMask}
        styles={props.styles}
    >
        {props.children}
    </Drawer>;
}