import {ReactComponent as AvatarSvg} from '@assets/Icons/profile-boy-icon.svg';
import { useRef, useState} from 'react';
import Overlay from 'react-overlays/Overlay';
import { Placement } from 'react-overlays/usePopper';
import styles from './AvatarButton.module.scss';
import {useTranslation} from "react-i18next";
import { UserProfileWindow } from './UserProfileWindow';

export interface IAvatarButtonProps {
    placement?: Placement|undefined;
}

export function AvatarButton(compProps: IAvatarButtonProps) {
    const { t } = useTranslation();
    const [userProfileVisible, setUserProfileVisible] = useState(false);
    const btnRef = useRef(null);

    const onCloseClicked = () => {
        setUserProfileVisible(false);
    };

    return (<div onClick={() => setUserProfileVisible(true)}>
        <button ref={btnRef}
                className={styles.avatar}>
            <div className={styles.avatarIcon}>
                <AvatarSvg/>
            </div>
            <span className={styles.avatarCaption}>{t("profile")}</span>
        </button>
        <Overlay
            show={userProfileVisible}
            placement={compProps.placement || 'right-end'}
            rootClose={true}
            onHide={() => setUserProfileVisible(false)}
            target={btnRef}
        >
            {({show, props, arrowProps, placement}) => (
                <div {...addStyle(props, {zIndex: 100})} className={styles.dialog}>
                    <UserProfileWindow/>
                </div>
            )}
        </Overlay>
    </div>);
}

function addStyle(props: Record<string, any>, style: any) {
    const result = {
        ...props,
        style: {...props.style, ...style}
    }
    return result;
}

