import React, { useEffect, useMemo, useState } from 'react';
import {Link, Outlet, useLocation} from 'react-router-dom';
import {MainMenu} from './MainMenu/MainMenu';
import {MainMenuSection} from './MainMenu/MainMenuSection/MainMenuSection';
import {MainMenuItem} from './MainMenu/MainMenuItem/MainMenuItem';
import {MainMenuItemIcon} from './MainMenu/MainMenuItem/MainMenuItemIcon/MainMenuItemIcon';
import {ReactComponent as CartVariantSvg} from '@assets/Icons/cart-variant-icon.svg';
import { IconDefinition, faSquarePlus } from '@fortawesome/free-regular-svg-icons';
import {MainMenuItemCaption} from './MainMenu/MainMenuItem/MainMenuItemCaption/MainMenuItemCaption';
import {ReactComponent as PowerPlugOutlineSvg} from '@assets/Icons/power-plug-outline-icon.svg';
import {useAppSelector} from '@core/Redux/hooks';
import styles from './DesktopAppLayout.module.scss';
import {AvatarButton} from '../Shared/Profile/AvatarButton';
import {MenuDelimiter} from './MainMenu/MenuDelimiter/MenuDelimiter';
import {useLocalStorage} from '@core/Hooks/use-local-storage';
import { selectPluginsState, selectTenantConfig, selectUserInfo } from '@core/Redux/store';
import {useTranslation} from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AiChatButton from "./AiChat/AiChatButton";
import AiChatPopup from "./AiChat/AiChatPopup";
import { tablePath } from '@core/Constants/route-paths';
import { IRoute } from "@pluginShared/i-plugin-contract";
import _ from "lodash";
import { loadedRoutes } from '@core/Plugins/pluginManager';
import { IInstalledModule } from '@core/Models/installedModule';
import { urlIsBasePath } from 'src/Utils/urlUtils';

export function DesktopAppLayout() {
    
    const { t } = useTranslation();
    const [menuExpanded, setMenuExpanded] = useLocalStorage('menuExpanded', {
        initialValue: true
    });
    const pluginsState = useAppSelector(selectPluginsState);
    const userInfo = useAppSelector(selectUserInfo);
    const tentantConfig = useAppSelector(selectTenantConfig);

    const [isChatOpen, setIsChatOpen] = useState(false);
    const toggleChat = () => {
        setIsChatOpen(!isChatOpen);
      };


    //TODO: надо как-то по другому сделать логику в каких окнах показывать кнопку чата а в каких нет
    //такой вариант слишком hacky
    const location = useLocation();
    const showAiChat = userInfo?.role == 'SuperUser' 
        && tentantConfig?.tables.some(table => urlIsBasePath(location, tablePath(table.tableId)));

    let menuSections = useMemo(() => {
        let routeList: IRoute[] = [];

        for (const route of pluginsState.modules.flatMap((module: IInstalledModule) => module.installation.routes)) {
            routeList.push(loadedRoutes[route.id ?? ""]);
        }

        routeList.push({
            path: "/add-report",
            id: "add-report",
            menuCaption: t("add-report"),
            menuIcon: faSquarePlus,
            groupCaption: t("reports"),
            func: () => undefined,
        });
        routeList.push({
            path: "/add-integration",
            id: "add-integration",
            menuCaption: t("add-integration"),
            menuIcon: faSquarePlus,
            groupCaption: t("integrations"),
            func: () => undefined,
        });
        routeList.push({
            path: "/add-data-import",
            id: "add-data-import",
            menuCaption: t("add-data-import"),
            menuIcon: faSquarePlus,
            groupCaption: t("data-import"),
            func: () => undefined,
        });

        return Object.entries(_.groupBy(routeList, route => route.groupCaption ?? ""));
    }, [ pluginsState.modules]);

    return (<div className={styles.host}>
        <div className={styles.leftNav}>
            <div className={styles.thickNav}>
                <MainMenu expanded={menuExpanded}
                          onClick={() => setMenuExpanded(v => !v)}>
                    <AvatarButton/>
                    <MenuDelimiter/>
                    <MainMenuSection caption={'CRM'}
                                     expanded={menuExpanded}>
                        {tentantConfig?.tables.map(table =>
                            <Link key={table.tableId} to={tablePath(table.tableId)}>
                                <MainMenuItem to={tablePath(table.tableId)}>
                                    <MainMenuItemIcon>
                                        <CartVariantSvg/>
                                    </MainMenuItemIcon>
                                    {menuExpanded && <MainMenuItemCaption>{table.tableName}</MainMenuItemCaption>}
                                </MainMenuItem>
                            </Link>
                        )}
                    </MainMenuSection>

                    {menuSections.map(([groupCaption, routes]) =>
                        <MainMenuSection key={groupCaption} caption={groupCaption || t("plugins")} expanded={menuExpanded}>
                            {routes.map(route =>
                                <Link key={route.id} to={route.path}>
                                    <MainMenuItem to={route.path}>
                                        <MainMenuItemIcon>
                                            {route.menuIcon ?
                                                <FontAwesomeIcon icon={route.menuIcon as IconDefinition}/> :
                                                <PowerPlugOutlineSvg/>
                                            }
                                        </MainMenuItemIcon>
                                        {menuExpanded && <MainMenuItemCaption>{route.menuCaption}</MainMenuItemCaption>}
                                    </MainMenuItem>
                                </Link>
                            )}
                        </MainMenuSection>
                    )}
                </MainMenu>
            </div>
        </div>
        <div className={styles.mainContent}>
            <Outlet/>
        </div>
        <React.StrictMode>
            {showAiChat &&
            <>
                <AiChatButton onClick={toggleChat} />
                {/* <TestButton /> */}
                <AiChatPopup isOpen={isChatOpen} onHide={() => setIsChatOpen(false)}/>
            </>
            }
        </React.StrictMode>
    </div>);
}
