import { ICrmOperationEvent } from "./autogenerated/operation.models";
import { ICrmArrayUpdateEvent } from "./i-crm-array-operation-events";
import { IEntity, IEntityData } from "./i-entity";

export interface ICrmOperationEventDecoded extends ICrmOperationEvent
{
    decodedData : any;
}

export interface ICrmOperationEventDecodedWithLastEventInfo extends ICrmOperationEventDecoded
{
    lastEventNumber?: number;
    lastEventTime?: number;
}

export function decodeEvent(event: ICrmOperationEvent): ICrmOperationEventDecoded {
    return {...event, decodedData: JSON.parse(event.data) };
}