import React, {memo, MutableRefObject, useCallback, useContext, useMemo} from 'react';
import {useTranslation} from "react-i18next";
import {CrmGridView} from '@core/VisualComponents/CrmGridView/CrmGridView';
import {useAppSelector} from '@core/Redux/hooks';
import {selectEntitiesAreLoading, selectEntityById, selectQueryStatus} from '@core/Redux/Slices/ordersSlice/storesSlice';
import styles from './OrderGridView.module.scss';
import { selectTableConfig, selectUserInfo } from '@core/Redux/store';
import {ICellClickedArgs, ICrmCellInputEditors} from "src/PluginShared/core-ui-api";
import { DeviceTypeContext } from '@core/Contexts/DeviceTypeContext';
import { IOrderContextMenu } from '../OrderContextMenu/OrderContextMenu';
import { IEntityData } from '@core/Models/i-entity';
import { ICrmField } from '@core/Models/autogenerated/tenantConfig.models';

export interface IOrderGridViewProps {
    tableId: string;
	entityIds: string[];
	fields: ICrmField[];
	numRowsPerPage: number;
	ResetCursor: () => void;
	MoveCursor: (offset: number) => void;
	cursorAtStart: boolean;
	cursorAtEnd: boolean;
	cellEditorsRef: React.MutableRefObject<ICrmCellInputEditors>;
	contextMenuRef: MutableRefObject<IOrderContextMenu>;
	selectedEntityId?: string;
	onRowClick: (entityId: string) => void;

    selectedRows?: string[] | "all";
    onChangeSelectedRows?: (entityIds: string[] | "all") => void;

    simpleFilters: {[columnId: string]: any};
    onChangedSimpleQuery: (columnId: string, query: any) => void;
    onResetSimpleQuery: (columnId: string) => void;
}

export function OrderGridView(props: IOrderGridViewProps) {
    const { t } = useTranslation();
    const tableConfig = useAppSelector(selectTableConfig(props.tableId));
    const tableName = tableConfig?.tableName ?? '';

    const userInfo = useAppSelector(selectUserInfo);
    const deviceType = useContext(DeviceTypeContext);
    const queryStatus = useAppSelector(selectQueryStatus(props.tableId));

    const onEditCellClicked = useCallback((args: ICellClickedArgs) => {
        const {entity, field} = args.props;
        const entityData = args.entityData;

        props.cellEditorsRef.current.showEditor(props.tableId, entity.id, entityData, field, { x: args.e.clientX, y: args.e.clientY });
    }, [props.cellEditorsRef, props.tableId]);

    const onContextMenu = useCallback((e: MouseEvent, ref: MutableRefObject<any>, entityId: string, entityData: IEntityData, field?: ICrmField) => {
        return props.contextMenuRef.current.onContextMenu(e, ref, props.tableId, entityId, entityData, field);
    }, [props.contextMenuRef, props.tableId]);

    const useItemByIdSelector = useMemo(() => {
        return itemByIdSelectorFactory(props.tableId);
    }, [props.tableId])
    
    return (<div className={styles.host}>
            <CrmGridView entityIds={props.entityIds}
                keyField="id"
                tableName={tableName}
                tableId={props.tableId}
                fields={props.fields}
                addColumnAllowed={!deviceType.isMobile && userInfo?.role == 'SuperUser'}
                useItemByIdSelector={useItemByIdSelector}
                numRowsPerPage={props.numRowsPerPage}
                onCellDoubleClicked={onEditCellClicked}
                onContextMenu={onContextMenu}
                ResetCursor={props.ResetCursor}
                MoveCursor={props.MoveCursor}
                cursorAtStart={props.cursorAtStart}
                cursorAtEnd={props.cursorAtEnd}
                focusedEntityId={props.selectedEntityId}
                onRowClick={props.onRowClick}
                simpleFilters={props.simpleFilters}
                onChangedSimpleQuery={props.onChangedSimpleQuery}
                onResetSimpleQuery={props.onResetSimpleQuery}
                showFilters
                isLoading={queryStatus.status == "loading"}
                selectedRows={props.selectedRows}
                onChangeSelectedRows={props.onChangeSelectedRows}
            />

    </div>);
}

const itemByIdSelectorFactory = (tableId: string) => (id: string) => {
    return useAppSelector(selectEntityById(tableId, id));
};