import axios from 'axios';
import Logger from 'js-logger';
import { serverUrl } from '@platform/config';

const endpoint = `${serverUrl}/api/entity`;

export async function entityApiGet(req: any): Promise<any[]> {
    const resp = await axios.post(`${endpoint}/List`, JSON.stringify(req));
    Logger.debug('entityApiGet: ', req, resp);
    return resp.data;
}

export async function entityApiBatchList(req: any): Promise<any[]> {
    const resp = await axios.post(`${endpoint}/BatchList`, JSON.stringify(req));
    Logger.debug('entityApiBatchList: ', req, resp);
    return resp.data;
}


