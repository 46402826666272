import type { ICrmOperationEvent, IGet2Request } from "@core/Models/autogenerated/operation.models";
import { EventStreamFetchStatus, IEventStream, IEventStreamFetchResult } from "./IEventStream";
import { operationApiAdd, operationApiAddRange, operationApiGet } from "@core/Api/operation-api";
import { type IHandleStatus, type IQueueStore, QueueHandler, QueueItemStatus } from "@core/Stores/QueueStore";
import { AxiosError } from "axios";
import Logger from "js-logger";
import { EventHandler } from "@core/Helpers/eventHandler";
import { ICrmOperationEventDecoded, ICrmOperationEventDecodedWithLastEventInfo } from "@core/Models/i-crm-operation-event-decoded";
import { BulkUpdateStatus, IBulkUpdateResult } from "./BulkUpdateApi";

class EventRemoteStreamCursor
{
    public fromServerId : number = -1;
    public toServerId : number | undefined = undefined;
    public offset : number = 0;    
}

export interface IEventStreamEvent
{
    event: ICrmOperationEventDecoded;
}

export class EventRemoteStream implements IEventStream
{
    public tableId: string;
    pendingQueue: IQueueStore<IEventStreamEvent>;
    public onUploadingSizeChanged = new EventHandler<number>;
    public onDownloadingPartialComplete = new EventHandler<number|null>;

    public constructor(tableId: string, pendingQueueFactory: (handler:QueueHandler<IEventStreamEvent>)=>IQueueStore<IEventStreamEvent>) {
        this.tableId = tableId;
        this.pendingQueue = pendingQueueFactory(this.processEvent);
        let pendingQueueMonitor = this.pendingQueue.createMonitor();

        pendingQueueMonitor.onChange.add(async ids => {
            this.onUploadingSizeChanged.trigger(ids.length);
        });
    }

    public add = async (event: ICrmOperationEventDecoded): Promise<void> => {
        await this.pendingQueue.enqueue({event});
    }

    public addRange = async (events: ICrmOperationEventDecoded[]): Promise<IBulkUpdateResult> => {
        return await this.processEvents(events);
    }

    public fetch = async (cursor: EventRemoteStreamCursor | null, limit: number): Promise<IEventStreamFetchResult> => {
        if (!cursor)
            cursor = new EventRemoteStreamCursor();

        let fetchResult = await operationApiGet({
            useDefaultTenant: true,
            tableId: this.tableId,
            fromServerId: cursor.fromServerId,
            toServerId: cursor.toServerId,
            offset: cursor.offset,
            limit: limit
        } as IGet2Request);

        let result = {} as IEventStreamFetchResult;
        result.events = fetchResult.events;

        if (fetchResult.events.length < limit) {
            cursor = new EventRemoteStreamCursor();
            cursor.fromServerId = fetchResult.toServerId;
            cursor.toServerId = undefined;
            cursor.offset = 0;

            result.status = EventStreamFetchStatus.FullCompletion;
            this.onDownloadingPartialComplete.trigger(null);
        } else {
            cursor.offset += fetchResult.events.length;
            cursor.toServerId = fetchResult.toServerId;

            result.status = EventStreamFetchStatus.PartialCompletion;
            try {
                const maxId = fetchResult.events.map(x => x.id).reduce((x, acc) => Math.max(x, acc));
                let progress = 1 - (maxId - cursor.fromServerId) / (cursor.toServerId - cursor.fromServerId); //approximation of progress
                progress = Math.max(0, Math.min(1, progress));
                this.onDownloadingPartialComplete.trigger(progress);
            } catch(err) {
                Logger.warn("failed to update downloading progress", err);
            }
        }
        result.nextCursor = cursor;

        return result;
    }

    private isStatusOK = (status: number): boolean => {
        return status >= 200 && status <= 299;
    }
    
    private isStatusTransientError = (status: number): boolean => {
        // You can extend this list based on the specific status codes you consider transient
        return status === 429 || status === 503 || status === 500;
    }

    public process = async (): Promise<void> => {
        await this.pendingQueue.process(this.processEvent);
    }

    private processEvent = async (item: IEventStreamEvent) => {
        const {event:e} = item;

        Logger.debug(`[EventRemoteStream] start sending event ${e.entityId}`);
        try {
            const encodedEvent = {...e};
            delete encodedEvent["decodedData"];

            const resp = await operationApiAdd({
                useDefaultTenant: true,
                operationEvent: encodedEvent as ICrmOperationEvent
            });

            Logger.debug(`[EventRemoteStream] event ${e.tableId}/${e.entityId} sent with ${resp.status}`);
            if (this.isStatusOK(resp.status)) {
                return {
                    status: QueueItemStatus.Handled,
                    statusText: resp.statusText,
                } as IHandleStatus;
            } else if (this.isStatusTransientError(resp.status)) {
                return {
                    status: QueueItemStatus.Pending,
                    statusText: resp.statusText,
                } as IHandleStatus;
            } else {
                return {
                    status: QueueItemStatus.Pending,
                    statusText: resp.statusText,
                } as IHandleStatus;
            }
        } catch (ex) {
            const err = ex as AxiosError;

            let message: string = "";
            if(err.response) {
                message=err.response.data as string;
            } else if (err.message) {
                message = err.message;
            }

            if (err.code == "ERR_NETWORK"
                || (err.response && err.response.status && this.isStatusTransientError(err.response.status))) {
                Logger.error(`[EventRemoteStream]transient failure while sending event`, err);
                return {
                    status: QueueItemStatus.Pending,
                    statusText: `${err.code}: ${message}`,
                } as IHandleStatus;
            } else {
                Logger.error(`[EventRemoteStream]Fail to send event ${JSON.stringify(e)}`, err);
                return {
                    status: QueueItemStatus.Pending,
                    statusText: `${err.code}: ${message}`,
                } as IHandleStatus;
            }
        }
    }

    private processEvents = async (events: ICrmOperationEventDecodedWithLastEventInfo[]) => {
        let encodedEvents = [];
        for (const event of events) {
            const encodedEvent = {...event};
            delete encodedEvent.decodedData;
            delete encodedEvent.lastEventNumber;
            delete encodedEvent.lastEventTime;

            encodedEvents.push(encodedEvent);
        }

        try {
            const resp = await operationApiAddRange({
                useDefaultTenant: true,
                operationEvents: encodedEvents as ICrmOperationEvent[]
            });
            
            if (this.isStatusOK(resp.status)) {
                return {
                    status: BulkUpdateStatus.Done,
                    numberOfCompleted: events.length,
                } as IBulkUpdateResult;
            }
            else {
                return {
                    status: BulkUpdateStatus.Error,
                    errorMessage: resp.statusText,
                    numberOfCompleted: 0,
                } as IBulkUpdateResult;
            }
        }
        catch (ex) {
            const err = ex as AxiosError;

            let message: string = "";
            if (err.response) {
                message=err.response.data as string;
            }
            else if (err.message) {
                message = err.message;
            }

            return {
                status: BulkUpdateStatus.Error,
                errorMessage: `${err.code}: ${message}`,
                numberOfCompleted: 0,
            } as IBulkUpdateResult;
        }
    }
}