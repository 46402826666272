import { db } from "@core/JsStore/idb";
import { Table } from "dexie";
import Logger from "js-logger";
import { DATA_TYPE } from "jsstore";

interface IKvPair {
    id: number;
    key: string;
    value: string;
}

export interface IKvStore<T> {
    get(key: string): Promise<T | null>;
    set(key: string, value: T): Promise<void>;
    clear(): Promise<void> ;
}

export class KVStore<T> implements IKvStore<T> {
    private name : string;

    constructor(name: string) {
        this.name = name;
    }

    public static getSchemas(name: string) {
        return [{
            name: name,
            columns: {
                id: {
                    dataType: DATA_TYPE.Number,
                    primaryKey: true,
                    autoIncrement: true
                },
                key: {
                    dataType: DATA_TYPE.String,
                    notNull: true,
                    unique: true
                },
                value: {
                    dataType: DATA_TYPE.String,
                }
            }
        }];
    }

    public async get(key: string): Promise<T | null> {
        let pair = await this.dbGet(key);
        if (!pair)
            return null;
        return JSON.parse(pair.value);
    }

    public async set(key: string, value: T): Promise<void> {
        const pair = await this.dbGet(key);
        if (pair !== null)
            await this.dbUpdate(pair.id, key, JSON.stringify(value));
        else
            await this.dbInsert(key, JSON.stringify(value));
    }

    async clear(): Promise<void> {
        const table = this.getTable();
        await db.transaction('rw', table, () => {
            try {
                return table.clear();
            } catch (err) {
                Logger.error(`[KVStore(${this.name})] clear failed`, err);
            }
        });
    }

    async dbGet(key: string): Promise<IKvPair | null> {
        const table = this.getTable();
        const item =  await db.transaction('r', table, () => {
            try {
                return table.where({
                    key: key
                }).first();
            } catch (err) {
                Logger.error(`[KVStore(${this.name})] dbGet ${key} failed`, err);
            }
        });
        return item ?? null;
    }
 
    async dbInsert(key: string, value: string):Promise<void> {
        const table = this.getTable();
        await db.transaction('rw', table, () => {
            try {
                return table.add({
                    key,
                    value
                })
            } catch (err) {
                Logger.error(`[KVStore(${this.name})] dbInsert ${key} failed`, err);
            }
        });
    }

    async dbUpdate(id: number, key: string, value: string): Promise<void> {
        const table = this.getTable();
        await db.transaction('rw', table, () => {
            try {
                return table.put({
                    id,
                    key,
                    value,
                });
            } catch (err) {
                Logger.error(`[KVStore(${this.name})] dbUpdate ${key} failed`, err);
            }
        });
    }

    private getTable(): Table {
        return (db as any)[this.name] as Table;
    }
}