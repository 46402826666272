import {db} from '@core/JsStore/idb';
import { IAutocompletePersistentStore, IDbAutocompleteValue } from '@core/Shared/AutocompleteStore';
import {Table} from 'dexie';


export const autocompleteTableName = (tableId: string) => `${tableId}_autocomplete`;

export class AutocompletePersistentStore implements IAutocompletePersistentStore{
    public tableId: string;

    constructor(tableId: string) {
        this.tableId = tableId;
    }

    getAutocompleteTable(): Table {
        return (db as any)[autocompleteTableName(this.tableId)] as Table;
    }

    public async add(fieldName: string, values: Record<string, number>): Promise<void> {
        const autocompleteTable = this.getAutocompleteTable();
        await db.transaction('rw', autocompleteTable, async () => {
            await autocompleteTable.add({
                fieldName,
                valuesJson: JSON.stringify(values)
            });
        });
    }

    public async load(fieldName: string): Promise<IDbAutocompleteValue> {
        const autocompleteTable = this.getAutocompleteTable();

        const cachedValuesFromDb = await db.transaction('rw', autocompleteTable, async () => {
            return await autocompleteTable.where({ fieldName }).first() as IDbAutocompleteValue;
        });
        return cachedValuesFromDb;
    }

    public async store(entries: { fieldName: string; values: Record<string, number>; }[]): Promise<void> {
        const autocompleteTable = this.getAutocompleteTable();
        await db.transaction('rw', autocompleteTable, async () => {
            for (let entry of entries) {
                await autocompleteTable.put({
                    fieldName: entry.fieldName,
                    valuesJson: JSON.stringify(entry.values)
                });
            }
        });
    }

    public async clear(): Promise<void> {
        const autocompleteTable = this.getAutocompleteTable();
        await db.transaction('rw', autocompleteTable, async () => {
            await autocompleteTable.clear();
        });
    }
}

