import styles from './PageContent.module.scss';

export const PageContentComponentId = 'PageContentComponent';

export interface IPageContentProps {
    children: any;
}

export function PageContent(props: IPageContentProps) {
    return (<div id={PageContentComponentId} className={styles.host}>{props.children}</div>);
}
