import styles from './PageTitleContainer.module.scss';

export interface IPageTitleContainerProps {
    children: any;
}

export function PageTitleContainer(props: IPageTitleContainerProps) {
    return (<div className={styles.host}>
        <h2 className={styles.TitleText}>{props.children}</h2>
    </div>);
}
