import styles from './PrimaryButtonRounded.module.scss';
import classNames from 'classnames/bind';
import { useCallback } from 'react';

let cx = classNames.bind(styles);

export interface IPrimaryButtonRoundedProps {
    onClick?: (e: React.MouseEvent) => void;
    children: any;
    className?: any;
}

export function PrimaryButtonRounded(props: IPrimaryButtonRoundedProps) {
    const onClick = useCallback((e: React.MouseEvent) => {
        // Send event to Google Analytics
        // try {

        //     (window as any).gtag('event', 'click', {
        //         'event_category': 'Button',
        //         'event_label': props.title ?? ""
        //     });
        // }
        // catch {
        // }

        if (props.onClick)
            props.onClick(e);
        
    }, [props.onClick, props.children]);

    return (<div className={cx(styles.host, props.className)} onClick={onClick}>{props.children}</div>);
}
