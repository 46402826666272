import Logger from "js-logger";

async function openDatabase(dbName: string): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(dbName);

        request.onsuccess = (event: Event) => {
            const db = (event.target as IDBOpenDBRequest).result;
            resolve(db);
        };

        request.onerror = (event: Event) => {
            reject(new Error(`Failed to open database: ${(event.target as IDBRequest).error?.name}`));
        };
    });
}

// Helper function to clear an object store
async function clearObjectStore(objectStore: IDBObjectStore): Promise<void> {
    return new Promise((resolve, reject) => {
        const clearRequest = objectStore.clear();

        clearRequest.onsuccess = () => resolve();
        clearRequest.onerror = (event: Event) => reject(new Error(`Failed to clear object store: ${(event.target as IDBRequest).error}`));
    });
}

// Helper function to complete a transaction
async function completeTransaction(transaction: IDBTransaction): Promise<void> {
    return new Promise((resolve, reject) => {
        transaction.oncomplete = () => resolve();
        transaction.onerror = (event: Event) => reject(new Error(`Transaction error: ${(event.target as IDBRequest).error}`));
    });
}

// Function to clear all object stores except those in the ignore list
export async function clearObjectStoresExcept(dbName: string, predicate: (name: string) => boolean): Promise<void> {
    try {
        const db = await openDatabase(dbName);
        const stores = Array.from(db.objectStoreNames).filter(predicate);

        if (stores.length === 0) {
            Logger.debug('[clearObjectStoresExcept] No object stores to clear.');
            return;
        }

        const transaction = db.transaction(stores, 'readwrite');

        await Promise.all(
            stores.map(async (objectStoreName) => {
                const objectStore = transaction.objectStore(objectStoreName);
                await clearObjectStore(objectStore);
                Logger.debug(`[clearObjectStoresExcept] Cleared object store: ${objectStoreName}`);
            })
        );

        await completeTransaction(transaction);
        Logger.debug('[clearObjectStoresExcept] All specified object stores cleared successfully.');
        db.close();
    } catch (error) {
        Logger.error(`[clearObjectStoresExcept] Error: ${error}`);
        throw error;
    }
}
// Function to clear a specific object store by its name, wrapped in a Promise
export async function clearObjectStoreByName(dbName: string, storeName: string): Promise<void> {
    try {
        const db = await openDatabase(dbName);
        const transaction = db.transaction([storeName], 'readwrite');
        const objectStore = transaction.objectStore(storeName);

        await clearObjectStore(objectStore);
        await completeTransaction(transaction);

        Logger.debug(`[clearObjectStoreByName] Object store ${storeName} cleared successfully.`);
        db.close();
    } catch (error) {
        Logger.error(`[clearObjectStoreByName] Error: ${error}`);
        throw error;
    }
}