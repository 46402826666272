import Logger from 'js-logger';
//todo: rollback
//import * as lokijs from 'lokijs';
import lokijs from 'lokijs';
import _ from 'lodash';
import { LokijsHelpers } from './lokijsHelpers';

export interface IResultEntry {
    id: string;
    orderKey: any;
    validDue: number;
}

//lokijs bugfix for big arrays
lokijs.Collection.prototype.max = function (field) {
    return _.max(this.extract(field));
};

lokijs.Collection.prototype.min = function (field) {
    return _.min(this.extract(field));
};


export class ResultSet {
    private db: lokijs;
    private positive: Collection<IResultEntry>;
    private waiting: Collection<IResultEntry>;
    public disposed: boolean;

    constructor() {
        this.db = new (lokijs as any)('ResultSet', {persistenceMethod: "memory"}); //'as any' - bug workaround?
        this.positive = this.db.addCollection('positive', { indices: ['orderKey', 'validDue'], unique: ['id'] });
        this.waiting = this.db.addCollection('waiting', { indices: ['orderKey', 'validDue'], unique: ['id'] });
        this.disposed = false;
    }

    public dispose = () => {
        if (!this.disposed) {
            this.db.removeCollection('positive');
            this.db.removeCollection('waiting');
            this.db.deleteDatabase();
            this.disposed = true;
        }
    };

    public count = () => {
        return this.positive.count();
    }

    public take = (skip: number, limit: number, isDescending: boolean = true): IResultEntry[] => {
        const sortCriteria = [['orderKey', isDescending], ['id', true]] as [keyof IResultEntry, boolean][];
        return LokijsHelpers.indexTake(this.positive, skip, limit, sortCriteria);
    }

    public takeAll = (isDescending: boolean = true): IResultEntry[] => {
        const sortCriteria = [['orderKey', isDescending], ['id', true]] as [keyof IResultEntry, boolean][];
        return LokijsHelpers.indexTakeAll(this.positive, sortCriteria);
    }

    public upsert = (positive: boolean, entry: IResultEntry):boolean => {
        let resultUpdated = false;

        if (Number.isNaN(entry.validDue) ) {
            Logger.error(`[ResultSet] entry ${entry.id} validDue is Nan`);
            entry.validDue = Infinity;
        }

        if (positive) {
            let saved = this.positive.by('id', entry.id);
            if (saved != null)
                this.positive.update({...saved, ...entry});
            else
                this.positive.insert(entry);

            saved = this.waiting.by('id', entry.id);
            if (saved != null)
                this.waiting.remove(saved);

            resultUpdated = true;
        } else {
            let saved = this.waiting.by('id', entry.id);

            if (entry.validDue != Infinity) {
                if (saved != null)
                    this.waiting.update({...saved, ...entry});
                else
                    this.waiting.insert(entry);
            } else {
                if (saved != null)
                    this.waiting.remove(saved);
            }

            saved = this.positive.by('id', entry.id);
            if (saved != null) {
                this.positive.remove(saved);
                resultUpdated = true;
            }
        }

        return resultUpdated;
    };

    public delete = (id: string): boolean => {
        let resultUpdated = false;

        let entry = this.positive.by('id', id);
        if (entry != null) {
            this.positive.remove(entry);
            resultUpdated = true;
        }

        entry = this.waiting.by('id', id);
        if (entry != null)
            this.waiting.remove(entry);

        return resultUpdated;
    };

    //next Date.now in seconds
    public getRecalculationTime = (): number => {
        let positiveTime = LokijsHelpers.indexMin(this.positive, 'validDue');
        let waitingTime = LokijsHelpers.indexMin(this.waiting, 'validDue');
        
        if (positiveTime == null || Number.isNaN(positiveTime))
            positiveTime = Infinity;
        if (waitingTime == null || Number.isNaN(waitingTime))
            waitingTime = Infinity;

        return Math.min(positiveTime, waitingTime);
    }

    public getRecalculationEntities = (time: number): Set<string> => {
        let result = new Set<string>();

        this.positive
            .find({'validDue': {$lte: time}})
            .map(x => x.id)
            .forEach(x => result.add(x));

        this.waiting
            .find({'validDue': {$lte: time}})
            .map(x => x.id)
            .forEach(x => result.add(x));

        return result;
    }
}
