import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';
import styles from './ContextMenu.module.scss';
import { MutableRefObject } from 'react';
import Overlay from 'react-overlays/Overlay';
import { registerComponent } from '@core/Plugins/pluginManager';

export interface IContextMenuProps {
    contextMenuRef?: MutableRefObject<any>
    triggerRef: MutableRefObject<any> | null;
    visible: boolean;
    onHide: () => void;
    children: any;
}

export const ContextMenu = registerComponent(coreUiComponentDescriptions.ContextMenu, _ContextMenu);

function _ContextMenu(compProps: IContextMenuProps) {
    return (<Overlay
        ref={compProps.contextMenuRef}
        show={compProps.visible}
        rootClose
        flip={true}
        placement={'bottom-start'}
        onHide={compProps.onHide}
        container={null}
        target={compProps.triggerRef}
    >
        {({props, arrowProps, placement}) => (
            <div {...props}
                 className={styles.host}>{compProps.children}</div>
        )}
    </Overlay>);
}
