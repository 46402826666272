import {useCallback, useContext, useEffect, useRef} from 'react';
import {useAppSelector} from '@core/Redux/hooks';
import _ from 'lodash';
import {
    getKvPairFromDumbCrmDb,
    rewriteKvPairFromDumbCrmDb,
    selectedTenantKey
} from '@core/JsStore/stores/kv-pairs-store';
import {IDataBase} from 'jsstore';
import {v4 as uuidv4} from 'uuid';


import Logger from 'js-logger';
import {serializeError} from 'serialize-error';
import {extenedLogsEnabled} from '@core/Services/logger-service';
import { selectAppReady, selectDbSchema, selectDefaultTenantId, selectTenantConfig } from '@core/Redux/store';

import { StoreContext } from '@core/Stores/EventSourcingStoreProvider';
import { lock } from '@platform/lock';

const processEventsLockName = 'processEventsLockName';

// Периодически затягивает события с бэка и сохраняет снапшоты состояния в IndexedDB
export function OperationEventPoller(props: any) {
    const selectedTenant = useAppSelector(selectDefaultTenantId);
    const appReady = useAppSelector(selectAppReady);
    const dbSchema = useAppSelector(selectDbSchema);
    const orderConfig = useAppSelector(selectTenantConfig);

    let stores = useContext(StoreContext);

    const processEvents = useCallback(async (dbSchema: IDataBase, abortController: AbortController) => {
        try {
            for (let store of Object.values(stores)) {
                await store.process(abortController);
            }
        }
        catch (err) {
            Logger.error("[OperationEventPoller] processEvents failed", err);
        }
    }, [stores]);

    // Периодически тянем свежие события с бэка и производим их обработку
    useEffect(() => {
        if (extenedLogsEnabled())
            Logger.debug("[OperationEventPoller] mounted");

        if (!appReady || !selectedTenant || !orderConfig || !dbSchema)
            return;

        Logger.debug("[OperationEventPoller] started");

        const lockAbortController = new AbortController();
        let effectInterrupted = false;
        const pollerGuid = uuidv4();

        let intervalHandler: any = undefined;

        const processEventsPeriodically = async () => {
            // if (extenedLogsEnabled())
            //     Logger.debug("processEventsPeriodically");

            try {
                // //todo:
                // if (extenedLogsEnabled()) {
                //     Logger.debug('navigator.lock is defined:', navigator.locks);
                //     //todo: remove steal
                //     //await navigator.locks.request(processEventsLockName, {ifAvailable: true}, async (lock) => {
                //     await navigator.locks.request(processEventsLockName, {steal: true}, async (lock) => {
                //         Logger.debug('lock is ', lock);
                //         Logger.debug('signal is aborted:', lockAbortController.signal.aborted);
                //     });
                // }

                //await navigator.locks.request(processEventsLockName, {signal: lockAbortController.signal}, async (lock) => {
                await lock(processEventsLockName, async (lock) => {
                    // if (extenedLogsEnabled())
                    //     Logger.debug("processEventsPeriodically lock");
                    const selectedTenantInDb = await getKvPairFromDumbCrmDb(selectedTenantKey);
                    if (selectedTenantInDb?.value !== selectedTenant) {
                        await rewriteKvPairFromDumbCrmDb(selectedTenantKey, selectedTenant);
                    }

                    try {
                        // if (extenedLogsEnabled())
                        //     Logger.debug("processEventsPeriodically->processEvents first");

                        await processEvents(dbSchema, lockAbortController);
                    }
                    catch(err) {
                        console.log("first processEvents fail", serializeError(err, { maxDepth: 2}));
                    }
                }, {signal: lockAbortController.signal});

                if (effectInterrupted || process.env.REACT_APP_ENABLE_EVENT_POLLER === 'false')
                    return;

                let busy = false;
                intervalHandler = setInterval(() => {
                    // if (extenedLogsEnabled())
                    //     Logger.debug("processEventsPeriodically->interval");

                    if (effectInterrupted || busy)
                        return;

                    busy = true;

                    // if (extenedLogsEnabled())
                    //     Logger.debug("processEventsPeriodically->interval before lock");

                    navigator.locks.request(processEventsLockName, {signal: lockAbortController.signal}, async (lock) => {
                        // if (extenedLogsEnabled())
                        //     Logger.debug("processEventsPeriodically->processEvents interval");

                        await processEvents(dbSchema, lockAbortController);
                    })
                        .then(() => busy = false)
                        .catch(err => busy = false);
                }, parseInt(process.env.REACT_APP_EVENT_POLLER_INTERVAL as string));
            } catch (err:any) {
                Logger.error('processEventsPeriodically failed', serializeError(err, {maxDepth:2}));
            }
        };

        processEventsPeriodically()
            .catch(err=>Logger.error('Something went wrong in operation events poller',
                appReady, selectedTenant, orderConfig, dbSchema,
                serializeError(err, { maxDepth: 2})));

        return () => {
            Logger.debug('[OperationEventPoller] Interrupt');
            effectInterrupted = true;
            clearInterval(intervalHandler);
            lockAbortController.abort('OperationEventPoller has been aborted');
            Logger.debug('[OperationEventPoller] Interrupted');
        }
    }, [selectedTenant, appReady, processEvents, orderConfig, dbSchema]);

    return (<></>)
}
