import { Button } from 'antd';
import styles from './PrimaryButton.module.scss';
import { registerComponent } from '@core/Plugins/pluginManager';
import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';
import { CSSProperties, MutableRefObject, ReactNode } from 'react';

export interface IPrimaryButtonProps {
    onClick?: (e: React.MouseEvent) => void;
    children: ReactNode;
    title?: string;
    style?: CSSProperties;
    disabled?: boolean;
    buttonRef?: MutableRefObject<HTMLButtonElement>;
}

export const PrimaryButton = registerComponent(coreUiComponentDescriptions.PrimaryButton, _PrimaryButton);

function _PrimaryButton(props: IPrimaryButtonProps) {
    return <Button
        ref={props.buttonRef}
        title={props.title}
        className={styles.host}
        style={props.style}
        onClick={props.onClick}
        disabled={props.disabled}
        type='primary'
    >
        {props.children}
    </Button>;
}
