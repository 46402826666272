import { useCallback, useMemo, useRef } from "react";
import { useBlocker, useLocation, useNavigate } from "react-router-dom";

export function useBlockNavigation(confirm: ()=>boolean) {
    const location = useLocation();
    const rootLocation = useMemo(()=>location, []);


    const blocker = useBlocker(({ currentLocation, nextLocation }) => {
        let block = false;

        //console.log(currentLocation, nextLocation);

        if (nextLocation.pathname.startsWith(rootLocation.pathname)) {
            block = false;
        } else if (!confirm || confirm()) {
            block = false;
        } else {
            block = currentLocation.pathname !== nextLocation.pathname
        }
        //console.log(`block ${block}`);

        return block;
    });
}

export function useUnsavedChangeBlocker(confirm: ()=>boolean, haveChangs: ()=>boolean) {
    const pageUnblocked = useRef<boolean>(false);

    const confirmPageLeave = useCallback( () => {
        if (pageUnblocked.current)
            return true;
        
        if (haveChangs()) {
            pageUnblocked.current = confirm();
            return pageUnblocked.current;
        }
        pageUnblocked.current = true;
        return true;
    }, [confirm, haveChangs]);

    useBlockNavigation(confirmPageLeave);

    const unblockFunction = () => {
        pageUnblocked.current = true;
    }

    return unblockFunction;
}
