import { Route } from "react-router-dom";
import { CommentsEditPage } from "./CommentsEditPage";
import { IBreadcrumbs } from "@core/VisualComponents/Navigation/Breadcrumbs";
import { ICrmField, CrmFieldViewType } from "@core/Models/autogenerated/tenantConfig.models";

interface ICommentEditorRoutersProps {
    tableId: string;
    entityId?: string;
    fields: ICrmField[];
    breadcrumbs: IBreadcrumbs[];
}

export function CommentEditorRouters(props: ICommentEditorRoutersProps) {
    if (props.tableId == null || props.entityId == null)
        return null;

    return props.fields
                .filter(field => field.viewType === CrmFieldViewType.Comments 
                                || field.viewType === CrmFieldViewType.Timeline)
                .map(field => 
                    <Route
                        key={field.id}
                        path={field.id}
                        element={
                            <CommentsEditPage
                                breadcrumbs={props.breadcrumbs}
                                tableId={props.tableId}
                                entityId={props.entityId!}
                                field={field}
                            />
                        }
                    />
    );
}