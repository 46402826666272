import _ from 'lodash';
import axios, {AxiosHeaders} from 'axios';
import {IInterceptorIdPair} from './setup-interceptors';

export interface IContentTypeInterceptorOptions {
    urlPrefix: string;
    contentType: string;
}

function addContentTypeRequestInterceptor(options: IContentTypeInterceptorOptions): number {
    return axios.interceptors.request.use(function (config) {
        if (_.startsWith(config.url, options.urlPrefix)) {
            const headers = config.headers as AxiosHeaders;
            headers && headers.set('Content-Type', options.contentType);
        }
        return config;
    }, function (error) {
        return Promise.reject(error);
    });
}

export function addContentTypeInterceptor(options: IContentTypeInterceptorOptions): IInterceptorIdPair {
    return {
        requestInterceptorId: addContentTypeRequestInterceptor(options),
        responseInterceptorId: null
    };
}
