import styles from './MainMenu.module.scss';
import classNames from 'classnames/bind';
import {MenuExpander} from './MenuExpander/MenuExpander';
import React from 'react';

let cx = classNames.bind(styles);

export interface IMainMenuProps {
    expanded: boolean;
    onClick: () => void;
    children: any;
}

export function MainMenu(props: IMainMenuProps) {
    return (<div className={cx(styles.host, {host_collapsed: !props.expanded})}>
        <MenuExpander expanded={props.expanded}
                      onClick={props.onClick}/>
        {props.children}
        <div className={styles.spring}></div>
        <div className={styles.coccyx}></div>
    </div>);
}
