import styles from './FieldLabel.module.scss';

export interface IFieldLabelProps {
    caption: string
}

export function FieldLabel(props: IFieldLabelProps) {
    return (<label className={styles.host}>
        <span>{props.caption}</span>
    </label>);
}
