import {DateTime} from 'luxon';
import {CSSProperties, useEffect, useState} from 'react';
import { InputValidator } from '../InputValidator/InputValidator';
import styles from "./TimeInput.module.scss";
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { InvalidSpan } from '../InvalidHint/InvalidHint';
import { registerComponent } from '@core/Plugins/pluginManager';
import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';

export interface ITimeInputProps {
    placeholder?: string;
    initialValue?: string | null;
    onChanged?: (value: string | null) => void;
    setIsValid?: (isValid: boolean) => void;
    className?: string;
    style?: CSSProperties;
    readonly?: boolean;
    validation?: (value: any) => boolean;
    autoFocus?: boolean;
}

export const TimeInput = registerComponent(coreUiComponentDescriptions.TimeInput, _TimeInput);

function _TimeInput(props: ITimeInputProps) {
    const isValidValue = (value: any) => {
        if (props.validation) {
            return props.validation(value);
        }

        return InputValidator.validateTime(props.initialValue);
    }

    const [isValid, setIsValid] = useState<boolean>(isValidValue(props.initialValue));

    const extractedTime = extractTime(props.initialValue);
    const [timeValue, setTimeValue] = useState(extractedTime ? dayjs(extractedTime) : undefined);

    useEffect(() => {
        if (props.setIsValid) {
            props.setIsValid(isValid);
        }
    }, [isValid]);

    const onChange = (date: any) => {
        setTimeValue(date);
        const timeStr = date != null
            ? DateTime.fromJSDate(date.toDate()).toFormat('HH:mm')
            : null;
        setIsValid(true);
        props.onChanged?.(timeStr);
    }

    const handleClear = (e: any) => {
        onChange(null);
    };

    const handleNow = () => {
        onChange(dayjs());
    }

    return <div onClick={e => e.stopPropagation()}>
        <TimePicker
            value={timeValue}
            format={"HH:mm"}
            onChange={onChange}
            className={styles.host}
            placeholder={isValid ? props.placeholder : t("error")}
            status={isValid ? undefined : "error"}
            showNow={false}
            renderExtraFooter={() =>
                <div style={{ textAlign: "center" }}>
                    <span className={styles.footerSpan} onClick={handleNow}>{t("now")}</span><br/>
                    <span className={styles.footerSpan} onClick={handleClear}>{t("clear")}</span>
                </div>
            }
            disabled={props.readonly}
            autoFocus={props.autoFocus}
        />
        {isValid ||
            <InvalidSpan/>
        }
    </div>;
}

function extractTime(value: any) {
    if (typeof value != "string") {
        return undefined;
    }

    const match = value?.match(/^(\d{1,2}):(\d{2})$/);

    if (match) {
        const hours = match[1];
        const minutes = match[2];
        const hoursStr = `${(hours.length === 1 ? '0' : '')}${hours}`;
        const dateStr = `2000-01-01T${hoursStr}:${minutes}:00`;
        return DateTime.fromISO(dateStr).toJSDate();
    }

    return undefined;
}
