import { DateTime } from "luxon";
import style from "./CommentsDateTime.module.scss";

export interface ICommentsDateTimeProps {
    date: number; // date and time in seconds
}

export function CommentsDateTime(props: ICommentsDateTimeProps) {
    const time = DateTime.fromSeconds(props.date).toFormat('HH:mm');
    const date = DateTime.fromSeconds(props.date).toLocaleString();

    return (
        <span className={style.host}>
            <span className={style.date}>{date}</span>
            &nbsp;|&nbsp;
            <span className={style.time}>{time}</span>
        </span>
    );
}