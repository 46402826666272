import Logger, { ILogLevel } from 'js-logger';
import { clientInstanceIdKey, fakeUserNameKey } from '@core/Constants/app-storage-keys';
import _ from 'lodash';
import { serializeError } from 'serialize-error';
import { ICrmUserInfo } from '@core/Models/autogenerated/user.models';

const serverBranch = process.env.REACT_APP_GIT_REF_NAME!;
const serverSha = process.env.REACT_APP_GIT_COMMIT_SHA!;
const baseUrl = `${process.env.REACT_APP_LOGGER_SERVICE_URI}/api/v1/NgxLogger/Log`;

declare var location: any;

var g_userInfo: ICrmUserInfo | null = null;

export function extenedLogsEnabled() {
    try {
        const extended_logs_str = localStorage.getItem('extended_logs');
        const extended_logs = extended_logs_str && JSON.parse(extended_logs_str as string);
        return g_userInfo?.tenant == 'Florists' || extended_logs;
    } catch {
        return true;
    }
}


export function setupLogger(userInfo: ICrmUserInfo) {
    g_userInfo = userInfo;
}

function logToServer(messages: any[], level: ILogLevel): void {
    if (!process.env.REACT_APP_LOGGER_SERVICE_URI)
        return;

    const url = baseUrl
        + `?source=${encodeURIComponent(process.env.REACT_APP_LOGGER_SERVICE_SOURCE!)}`
        + `&locationHref=${encodeURIComponent(location?.href)}`
        + `&locationHost=${encodeURIComponent(location?.host)}`
        ;

    try {
        let additional: Record<string, any> = {
            clientInstanceName: JSON.parse(localStorage.getItem(fakeUserNameKey) ?? ''),
            clientInstanceId: JSON.parse(localStorage.getItem(clientInstanceIdKey) ?? ''),
            tenant: g_userInfo?.tenant,
            user: g_userInfo?.login,
        };

        messages = Array.from(messages).map(e => {
            if (_.isString(e))
                return e;
            if (e instanceof Error)
                return serializeError(e, { maxDepth: 2});
            else
                return JSON.stringify(e);
        });

        if (messages.length > 1) {
            additional.args = messages.slice(1);
        }

        const body = {
            level: level.value,
            message: messages.length > 0 ? messages[0] : '',
            additional,
        };

        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(body)
        })
            .then()
            .catch(error => console.error(`can't send logs to server ${error}`));
    }
    catch (error) {
        console.error(`can't send logs to server ${error}`);
    }
}

export function initLogger() {
    window.onerror = function (
        msg,
        url,
        line,
        col,
        error) {
        Logger.error({
            msg, url, line, col, error
        });
    }

    window.addEventListener('unhandledrejection', function (event) {
        const sErr = serializeError(event.reason, { maxDepth: 2 });
        Logger.error('Unhandled rejection', sErr);
    });

    Logger.setLevel(Logger.DEBUG);
    const consoleHandler = Logger.createDefaultHandler();
    Logger.setHandler(function (messages, context) {
        consoleHandler(messages, context);
        logToServer(messages, context.level);
    });
}