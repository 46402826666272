import cs from 'clsx';

import styles from './StatusIndicator.module.scss';
import { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useAppSelector } from '@core/Redux/hooks';
import { useTranslation } from "react-i18next";
import { TFunction } from 'i18next';
import { selectDownloadingProgress, selectEntitiesAreLoading, selectQueryStatus, selectUploadingQueueNotEmpty } from '@core/Redux/Slices/ordersSlice/storesSlice';
import {ReactComponent as CloudSyncIcon} from '@assets/Icons/cloud-sync-outline.svg';
import {ReactComponent as CloudCheckIcon} from '@assets/Icons/cloud-check-outline.svg';
import {ReactComponent as CloudRemoveIcon} from '@assets/Icons/cloud-remove-outline.svg';

type StatusView = {
    text: string, 
    icon: FunctionComponent<any>, 
    className: string
} | null;

export function StatusIndicator(props: {tableId: string}) {
    const { t } = useTranslation();
    const [statusView, setStatusView] = useState<StatusView>(null);

    const entitiesAreUploading = useAppSelector(selectUploadingQueueNotEmpty(props.tableId));
    const entitiesDownloadingProgress = useAppSelector(selectDownloadingProgress(props.tableId));
    const queryStatus = useAppSelector(selectQueryStatus(props.tableId));

    useEffect(() => {
        if (queryStatus.status == 'error') {
            setStatusView({
                text: queryStatus.error ?? 'unknown error',
                icon: CloudRemoveIcon,
                className: styles.errorStatus
            });
        } else if (queryStatus.status == 'loading') {
            setStatusView({
                text: t("loading_status"),
                icon: CloudSyncIcon,
                className: styles.loadingStatus
            });
        } else if (entitiesAreUploading) {
            setStatusView({
                text: t("uploading_status"),
                icon: CloudSyncIcon,
                className: styles.uploadingStatus
            });
        }  else if (entitiesDownloadingProgress != null) {
            setStatusView({
                text: t("downloading_status") + ` ${Math.floor(entitiesDownloadingProgress * 100)}%`,
                icon: CloudSyncIcon,
                className: styles.downloadingStatus
            });
        } else {
            setStatusView({
                text: t("idle_status"),
                icon: CloudCheckIcon,
                className: styles.idleStatus
            });
        }

    }, [entitiesAreUploading, entitiesDownloadingProgress, queryStatus.status]);

    if (statusView == null) {
        return null;
    }
    else
        return (
            <statusView.icon className={cs(statusView.className, styles.icon)} title={statusView.text}/>
        )
}