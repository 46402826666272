import { useAppSelector } from "@core/Redux/hooks";
import styles from "./AddReport.module.scss"
import { selectDefaultTenantId } from '@core/Redux/store';
import { useTranslation } from 'react-i18next'; 

export function AddReport () {
    const { i18n } = useTranslation(); 
    const selectedTenant = useAppSelector(selectDefaultTenantId);
    const baseUrlEn = "https://tabsmy.com/create-your-custom-report";
    const baseUrlRu = "https://tabsmy.com/create-your-custom-report-ru";

    const currentLang = i18n.language;
    const baseUrl = currentLang === 'ru' ? baseUrlRu : baseUrlEn;

    const urlWithParameter = `${baseUrl}?tenantId=${selectedTenant}`;
    
    return (
        <div className={styles.host}>
            <iframe
                src={urlWithParameter}
                style={{width: '100%', height: '100%'}}
            />
        </div>
    );
}