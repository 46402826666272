import { registerComponent } from '@core/Plugins/pluginManager';
import styles from './ContextMenuItem.module.scss';
import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';

export interface IContextMenuItemProps {
    onClick?: (e: React.MouseEvent) => void;
    children: any;
}

export const ContextMenuItem = registerComponent(coreUiComponentDescriptions.ContextMenuItem, _ContextMenuItem);

function _ContextMenuItem(props: IContextMenuItemProps) {
    return (<div className={styles.host}
                 onClick={props.onClick}>
        <div className={styles.inner}>{props.children}</div>
    </div>);
}
