import { IEntityData } from "@core/Models/i-entity";
import * as Cache from 'memory-cache';

export const MaximumAutocompleteValues : number = 1000;
export const AUTOCOMPLETE_VERSION = 2;
export const AUTOCOMPLETE_META_KEY = "autocomplete_version";

export interface ICachedAutocompleteValue {
    values: Record<string, number>;
}

export interface IDbAutocompleteValue {
    fieldName: string;
    valuesJson: string;
}

export interface IAutocompletePersistentStore {
    add(fieldName: string, values: Record<string, number>): Promise<void>;
    load(fieldName: string): Promise<IDbAutocompleteValue>;
    store(entries: { fieldName: string; values: Record<string, number>; }[]): Promise<void>;
    clear(): Promise<void>;
}

export interface IAutocompleteStore {
    //insertAutocompleteValueToDumbCrmDb(fieldName: string, values: Record<string, number>): Promise<void>;
    getCachedValuesFromDumbCrmDb(fieldName: string): Promise<string[]>;
    //pushAutocompleteValueToDumbCrmDb(fieldName: string, value: string): Promise<void>;
    //removeAutocompleteValueFromDumbCrmDb(fieldName: string, value: string): Promise<void>;
    //flushCachesToDumbCrmDb(): Promise<void>;
    //clear(): Promise<void>;
    //updateAutocompleteValuesInDb(oldEntityData: IEntityData | null, newEntityData: IEntityData | null, prevKey?: string): Promise<void>;
}

export class AutocompleteStore implements IAutocompleteStore {
    public tableId: string;
    public persistentStore: IAutocompletePersistentStore;

    constructor(tableId: string, persistentStore: IAutocompletePersistentStore) {
        this.tableId = tableId;
        this.persistentStore = persistentStore;
    }

    public async insertAutocompleteValueToDumbCrmDb(fieldName: string, values: Record<string, number>): Promise<void> {
        const keysToRemove = Object.keys(values).slice(0, -MaximumAutocompleteValues);
        for (const key of keysToRemove) {
            delete values[key];
        }

        await this.persistentStore.add(fieldName, values);

        const cacheKey = this.getCacheKey(fieldName);
        Cache.put(cacheKey, {
            values
        } as ICachedAutocompleteValue);
    }

    public async getCachedValuesFromDumbCrmDb(fieldName: string): Promise<string[]> {
        const cacheKey = this.getCacheKey(fieldName);
        let cachedValues: ICachedAutocompleteValue | null = Cache.get(cacheKey);
        if (!cachedValues)
            cachedValues = await this.restoreCachedValuesFromDumbCrmDb(fieldName);

        const comparator = (value1: string, value2: string) => {
            value1 = value1.trim().toLowerCase();
            value2 = value2.trim().toLowerCase();

            if (value1 < value2) {
                return -1;
            }

            if (value1 > value2) {
                return 1;
            }

            return 0;
        };

        return Object.keys(cachedValues?.values ?? {}).slice().sort(comparator);

    }

    public async pushAutocompleteValueToDumbCrmDb(fieldName: string, value: string): Promise<void> {
        value = value?.trim();
        const cacheKey = this.getCacheKey(fieldName);
        let cachedValues: ICachedAutocompleteValue | null = Cache.get(cacheKey);
        if (!cachedValues)
            cachedValues = await this.restoreCachedValuesFromDumbCrmDb(fieldName);

        if (cachedValues) {
            await this.setAutocompleteValueToCache(fieldName, cachedValues, value);
        }
        else {
            await this.insertAutocompleteValueToDumbCrmDb(fieldName, { [value]: 1 });
        }
    }

    public async removeAutocompleteValueFromDumbCrmDb(fieldName: string, value: string): Promise<void> {
        value = value?.trim();
        const cacheKey = this.getCacheKey(fieldName);
        let cachedValues: ICachedAutocompleteValue | null = Cache.get(cacheKey);
        if (!cachedValues)
            cachedValues = await this.restoreCachedValuesFromDumbCrmDb(fieldName);

        if (cachedValues?.values && cachedValues.values[value]) {
            cachedValues.values[value] -= 1;

            if (cachedValues.values[value] <= 0) {
                delete cachedValues.values[value];
            }

            const changedCacheKey = this.getChangedCacheKey();
            const changedCache: Set<string> = Cache.get(changedCacheKey) || new Set();
            if (!changedCache.has(fieldName)) {
                changedCache.add(fieldName);
                Cache.put(changedCacheKey, changedCache);
            }
        }
    }

    public async flushCachesToDumbCrmDb(): Promise<void> {
        const cacheKey = this.getChangedCacheKey();
        let cachedKeys: Set<string> | null = Cache.get(cacheKey);
        if (!cachedKeys)
            return;

        const fieldNames = Array.from(cachedKeys);

        let entries = fieldNames.map(fieldName => {
            const fieldNameCacheKey = this.getCacheKey(fieldName);
            const cachedValues: ICachedAutocompleteValue = Cache.get(fieldNameCacheKey);
            return { fieldName, values: cachedValues.values };
        });


        await this.persistentStore.store(entries);

        Cache.put(cacheKey, new Set());
    }

    public async clear(): Promise<void> {
        await this.persistentStore.clear();
    }

    public async updateAutocompleteValuesInDb(oldEntityData: IEntityData | null, newEntityData: IEntityData | null, prevKey?: string): Promise<void> {
        const keys = Object.keys({
            ...oldEntityData,
            ...newEntityData,
        });
        
        for (let key of keys) {
            if (key == "_keywords" || key == "id") {
                continue;
            }

            const oldValue = oldEntityData?.[key];
            const newValue = newEntityData?.[key];
            const cacheKey = (prevKey ? prevKey + "." : "") + key;

            if (oldValue !== newValue) {
                if (oldValue != null && oldValue !== "" && (typeof oldValue == "string" || typeof oldValue == "number")) {
                    this.removeAutocompleteValueFromDumbCrmDb(cacheKey, oldValue.toString());
                }
                if (newValue != null && newValue !== "" && (typeof newValue == "string" || typeof newValue == "number")) {
                    await this.pushAutocompleteValueToDumbCrmDb(cacheKey, newValue.toString());
                }

                if (Array.isArray(oldValue) || Array.isArray(newValue)) {
                    const oldIds = (Array.isArray(oldValue) ? oldValue : []).map(x => x.id);
                    const newIds = (Array.isArray(newValue) ? newValue : []).map(x => x.id);
                
                    for (const value of (Array.isArray(oldValue) ? oldValue : []).filter(x => !newIds.includes(x.id))) {
                        await this.updateAutocompleteValuesInDb(value, null, cacheKey);
                    }
                
                    for (const value of (Array.isArray(newValue) ? newValue : []).filter(x => !oldIds.includes(x.id))) {
                        await this.updateAutocompleteValuesInDb(null, value, cacheKey);
                    }
                }
            }
        }
    };

    private getCacheKey(fieldName: string): string {
        return `autocomplete_${this.tableId}_${fieldName}`;
    }

    private getChangedCacheKey(): string {
        return `autocomplete_changed_cache_${this.tableId}`;
    }
    

    private async setAutocompleteValueToCache(fieldName: string, cachedValues: ICachedAutocompleteValue, value: string): Promise<void> {
        const keysToRemove = Object.keys(cachedValues.values).slice(0, -MaximumAutocompleteValues);
        for (const key of keysToRemove) {
            delete cachedValues.values[key];
        }

        if (!cachedValues.values[value]) {
            cachedValues.values[value] = 0;
        }
        cachedValues.values[value] += 1;

        const changedCacheKey = this.getChangedCacheKey();
        const changedCache: Set<string> = Cache.get(changedCacheKey) || new Set();
        if (!changedCache.has(fieldName)) {
            changedCache.add(fieldName);
            Cache.put(changedCacheKey, changedCache);
        }
    }

    private async restoreCachedValuesFromDumbCrmDb(fieldName: string): Promise<ICachedAutocompleteValue | null> {
        const cacheKey = this.getCacheKey(fieldName);
        const cachedValuesFromDb = await this.persistentStore.load(fieldName);

        if (!cachedValuesFromDb) {
            return null;
        }

        const values = JSON.parse(cachedValuesFromDb.valuesJson);
        const cachedValues = {
            values
        } as ICachedAutocompleteValue;
        Cache.put(cacheKey, cachedValues);

        return cachedValues;
    }
}
