import { entityCreatePath } from "@core/Constants/route-paths";
import styles from './GreetingPanel.module.scss';
import { PrimaryButtonRounded } from "@core/VisualComponents/Buttons/PrimaryButtonRounded";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export interface IGreetingPanelProps {
    tableId: string;
    onCreate: () => void;
}

export function GreetingPanel(props: IGreetingPanelProps) {
    const { t } = useTranslation();

    const handleButtonClick = () => {
        const url = "/add-data-import";
        window.location.href = url;
      };

    
    return (
        <div className={styles.greetingPanel}>
            <h2 className={styles.header}> {t("noOrders")}</h2>
            <p className={styles.message}>{t("startCRM")}</p>
            <div>
                <PrimaryButtonRounded onClick={props.onCreate}>{t("createOrder")}</PrimaryButtonRounded>
            </div>
            <p className={styles.message}>{t("or")}</p>
            <div>
                <PrimaryButtonRounded onClick={handleButtonClick}>{t("add-data-import")}</PrimaryButtonRounded>
            </div>
        </div>
    );
}