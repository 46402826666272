import styles from './FilterArea.module.scss';
import {useTranslation} from "react-i18next";
import { FilterAreaButton } from './FilterAreaButton/FilterAreaButton';

export interface IFilterAreaProps {
    children?: any;
}

export function FilterArea(props: IFilterAreaProps) {
    const { t } = useTranslation();
    return (<div className={styles.host}>
        <div className={styles.filtersContainer}>
            {props.children}
        </div>
    </div>);
}
