import {IDataBase} from 'jsstore';
import {IColumnOption, ITable} from 'jsstore/dist/ts/common/interfaces';

interface IColumnOptionPair{
    name: string;
    option: IColumnOption;
}

function findPrimaryColumn(table: ITable):IColumnOptionPair|null {
    for (const [name, option] of Object.entries(table.columns)) {
        if(option.primaryKey === true)
            return {name, option};
    }

    return null;
}

function mapTableSchema(table: ITable): string {
    const primaryColumnOption = findPrimaryColumn(table);

    let tableConf: string = '';
    if(primaryColumnOption !== null){
        if(primaryColumnOption.option.autoIncrement === true) {
            tableConf = '++';
        }
        tableConf += primaryColumnOption.name;
    }

    const columnNames: string[] = [];
    for (const [name, option] of Object.entries(table.columns)){
        if(name === primaryColumnOption?.name)
            continue;

        columnNames.push(name);
    }

    if (columnNames.length > 0)
        tableConf = tableConf + ', ' + columnNames.join(', ');

    return tableConf;
}

export function mapJsStoreToDexieSchema(dbSchema: IDataBase): Record<string, string | null> {
    const schema: Record<string, string | null> = {};
    for(let table of dbSchema.tables){
        schema[table.name] = mapTableSchema(table);
    }
    return schema;
}