import { max } from 'lodash';
import React, { CSSProperties, ReactElement } from 'react';

export interface IEllipsisTextProps {
    text: string;
    length?: number;
    tail?: string;
    tailClassName?: string;
    style?: CSSProperties;
};

export function CrmText(props: IEllipsisTextProps) {
    const {
        tail = '...',
        tailClassName = 'more',
        text,
        length,
        ...restProps
    } = props ?? {};

    if (!text || (length != null && length < 0))
        return <span></span>;

    return linkifyAndEllipsifyText(text, length, tail, tailClassName, props.style);

}

function linkifyAndEllipsifyText(inputText: string, truncateLength?: number, tail?: string, tailClassName?: string, style?: CSSProperties): ReactElement[] {
    // Regular expression to match URLs or capture any other text
    const regex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])|([^]+?)(?=(\b(https?|ftp|file):\/\/)|$)/ig;

    let key=0;
    let outputHtml: ReactElement[] = [];
    let match;

    if (truncateLength == null) {
        truncateLength = Number.MAX_SAFE_INTEGER;
    }

    while (truncateLength >= 0 && (match = regex.exec(inputText)) !== null) {
        let text = "";

        if (match[1]) {
            text = match[1];
            let displayText = text;
            if (displayText.length > truncateLength)
                displayText = displayText.slice(0, truncateLength);
            
            outputHtml.push(<a key={key++} href={match[1]} style={{...style, display: "inline-block"}} target="_blank" onClick={(e)=>e.stopPropagation()}>
                                {displayText}
                            </a>);
        } else if (match[3]) { // Non-URL text
            text = match[3];
            let displayText = text;
            if (displayText.length > truncateLength)
                displayText = displayText.slice(0, truncateLength);
            outputHtml.push(<span key={key++} style={style}>{displayText}</span>);
        }

        truncateLength -= text.length;
    }

    if (truncateLength < 0) {
        outputHtml = [
            <span key="" title={inputText} style={style}>
                {outputHtml}
                <span key={key++} className={tailClassName}>{tail}</span>
            </span>
        ];
    }

    return outputHtml;
}