import { ColumnSize } from "../types/layout";

export function getColumnSizeStyle(size: ColumnSize) {
    const targetSize = size.actualSize ?? size.defaultSize;

    if (targetSize == null)
        return 'auto';
    else if (typeof targetSize == 'number')
        return `${targetSize}px`;
    else if (typeof targetSize == 'string')
        return targetSize;
}

export function updateSizeStyles(style: any, columnSizes: ColumnSize[] | undefined) {
    style["--data-table-library_grid-template-columns"] = columnSizes?.map(getColumnSizeStyle).join(' ');
}