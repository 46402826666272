import {Table} from 'dexie';
import {db} from '@core/JsStore/idb';

export const kvPairsTableName = 'kvPairs';
export const selectedTenantKey = 'selectedTenant';
export const lastOpEventNumberKey = 'lastOpEventNumber';

export interface IKvPair {
    id: number;
    key: string;
    value: string;
}

function getKvPairsTable(): Table {
    return (db as any)[kvPairsTableName] as Table;
}

async function updateKvPairFromDumbCrmDb(id: number, value: string): Promise<void> {
    const kvPairsTable = getKvPairsTable();
    await db.transaction('rw', kvPairsTable, async () => {
        await kvPairsTable.put({
            value
        }, id);
    });
}

async function insertKvPairFromDumbCrmDb(key: string, value: string): Promise<void> {
    const kvPairsTable = getKvPairsTable();
    await db.transaction('rw', kvPairsTable, async () => {
        await kvPairsTable.add({
            key,
            value
        })
    });
}

export async function getKvPairFromDumbCrmDb(key: string): Promise<IKvPair | null> {
    const kvPairsTable = getKvPairsTable();

    if (kvPairsTable == null)
        return null;

    const item =  await db.transaction('r', kvPairsTable, async () => {
        return kvPairsTable.where({
            key: key
        }).first();
    });
    return item ?? null;
}

export async function rewriteKvPairFromDumbCrmDb(key: string, value: string): Promise<void> {
    const kvPair = await getKvPairFromDumbCrmDb(key);
    if (kvPair !== null)
        await updateKvPairFromDumbCrmDb(kvPair.id, value);
    else
        await insertKvPairFromDumbCrmDb(key, value);
}

export async function clearKvPairsFromDumbCrmDb(): Promise<void> {
    const kvPairsTable = getKvPairsTable();
    await db.transaction('rw', kvPairsTable, async () => {
        await kvPairsTable.clear();
    });
}
