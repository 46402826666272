import styles from './MainMenuItemIcon.module.scss';

export interface IMainMenuItemIconProps {
    children: any;
}

export function MainMenuItemIcon(props: IMainMenuItemIconProps) {
    return (<div className={styles.host}>
        {props.children}
    </div>);
}
