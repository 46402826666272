import { CSSProperties, MutableRefObject, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './CrmGridView.module.scss';

import { CrmPaginationInternal } from './CrmPaginationInternal/CrmPaginationInternal';
import { coreUiComponentDescriptions, ICellClickedArgs } from "src/PluginShared/core-ui-api";
import { registerComponent } from '@core/Plugins/pluginManager';
import { t } from 'i18next';

import {
    Table,
    Header,
    HeaderRow,
    Body,
    Row,
    HeaderCell,
    Cell,
    Data,
    TableNode,
    ExtendedNode,
  } from '@table-library/react-table-library/table';
import { ColumnSize, Layout } from '@table-library/react-table-library/types/layout';
import { getValueFromLocalStorage, setValueToLocalStorage } from '@core/Hooks/use-local-storage';
import { CrmGridViewUserSettings, setSettingsColumnSize } from './CrmGridViewUserSettings';
import _ from 'lodash';
import { IEntity, IEntityData } from '@core/Models/i-entity';
import { CrmCellInternal } from './CrmCellInternal/CrmCellInternal';
import { CrmGridSimpleFilterButton } from './CrmGridAddFilter/CrmGridSimpleFilterButton/CrmGridSimpleFilterButton';
import { ICrmField } from '@core/Models/autogenerated/tenantConfig.models';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useAppSelector } from '@core/Redux/hooks';
import { selectUserInfo } from '@core/Redux/store';
import { ReactComponent as EllipsisIcon } from "@assets/Icons/ellipsis-vertical-icon.svg";

/* ====== CrmGridRow ====== */

interface CrmTableNode extends TableNode {
    value: string;
}

export interface ICrmGridRowProps {
    tableId: string;
    entityId: string,
    rowNumber : number,
    fields: ICrmField[],
    useItemByIdSelector: (id: string)=>IEntity;
    onContextMenu?: (e: MouseEvent, ref: MutableRefObject<any>, entityId: string, entityData: IEntityData, field?: ICrmField) => void;
    onCellDoubleClicked?: (args: ICellClickedArgs) => void;
    onRowClick?: (entityId: string) => void;
    onChangeSelected?: (selected: boolean) => void;
    className: string;
    focused?: boolean;
    selected?: boolean;
    style?: CSSProperties;
}

export const CrmGridRow = registerComponent(coreUiComponentDescriptions.CrmGridRow, _CrmGridRow);

function _CrmGridRow(props: ICrmGridRowProps){
    let entity = props?.useItemByIdSelector(props.entityId);
    const actionCellRef = useRef<any>();

    const handleChangeIsSelected = () => {
        props.onChangeSelected?.(!props.selected);
    }

    return <Row 
                key={props.entityId}
                className={props.className}
                item={entity}
                onClick={() => props.onRowClick ? props.onRowClick(props.entityId) : null}
                style = {props.style}
            >
                {(props.onContextMenu || props.onChangeSelected) &&
                    <Cell className={styles.actionsCell}>
                        {props.onContextMenu &&
                            <div
                                className={styles.contextMenuIcon}
                                onClick={e => props.onContextMenu?.(e as any, actionCellRef, props.entityId, entity.data)}
                                ref={actionCellRef}
                            >
                                <EllipsisIcon/>
                            </div>}
                        {props.onChangeSelected &&
                            <Checkbox checked={props.selected} onClick={handleChangeIsSelected}/>}
                    </Cell>
                }
                {props.fields.map(field =>
                    <Cell key={field.id} className={props.focused ? styles.focusedRow : ''}>
                        <CrmCellInternal
                            key={field.id}
                            tableId={props.tableId}
                            field={field}
                            entity={entity}
                            onContextMenu={(args) => props.onContextMenu?.(args.e, args.cellRef, props.entityId, args.entityData, field)}
                            onCellDoubleClicked={props.onCellDoubleClicked}/>
                    </Cell>
                )}
            </Row>
}

/* ====== CrmGridView ====== */

export interface ICrmGridViewProps {
    tableId: string;
    keyField: string;
    tableName: string;
    entityIds: string[];
    numRowsPerPage: number;
    fields: ICrmField[],
    children?: ReactElement<any, any>[] | ReactElement<any, any> | undefined;
    useItemByIdSelector: (id: string)=>IEntity;
    onCellDoubleClicked?: (args: ICellClickedArgs) => void;
    onContextMenu?: (e: MouseEvent, ref: MutableRefObject<any>, entityId: string, entityData: IEntityData, field?: ICrmField) => void;
    addColumnAllowed? : boolean;
    focusedEntityId?: string;
    onRowClick?: (entityId: string) => void;
    isLoading?: boolean;

    simpleFilters?: {[columnId: string]: any};
    onChangedSimpleQuery?: (columnId: string, query: any) => void;
    onResetSimpleQuery?: (columnId: string) => void;

    selectedRows?: string[] | "all";
    onChangeSelectedRows?: (entityIds: string[] | "all") => void;

    ResetCursor: () => void;
    MoveCursor: (offset: number) => void;
    cursorAtStart: boolean;
    cursorAtEnd: boolean;

    style?: CSSProperties;
    showFilters?: boolean;
}

export const CrmGridView = registerComponent(coreUiComponentDescriptions.CrmGridView, _CrmGridView);

function _CrmGridView(props: ICrmGridViewProps) {
    const fields = props.fields;
    const userSettingsKey = `tableUserSettings_${props.tableId}`;
    const [focusedRow, setFocusedRow] = useState<string>();

    const selectedRows = props.selectedRows == "all" ? "all" : props.selectedRows ?? [];
    const allChecked = selectedRows == "all";
    const indeterminate = selectedRows != "all" && selectedRows.length > 0;

    const defaultRowClickHandler = useCallback((entityId: string) => setFocusedRow(entityId), [setFocusedRow]);

    if (!props.onRowClick) {
        props.focusedEntityId = focusedRow;
        props.onRowClick = defaultRowClickHandler;
    }

    const showPagination = !props.cursorAtStart || !props.cursorAtEnd;

    let data: Data<CrmTableNode> = useMemo(()=>({nodes: props.entityIds.map(e => ({id: e, value: e}))}), [props.entityIds]);

    const layout = useMemo(()=>{
        let settings = getValueFromLocalStorage<CrmGridViewUserSettings>(userSettingsKey);
        let columnSizes: ColumnSize[] = [];

        if (props.onContextMenu || props.onChangeSelectedRows) {
            columnSizes.push({ defaultSize: 'auto', actualSize: 'auto' });
        }

        columnSizes.push(...fields.map(column => {
            const defaultSize = 
                (column.style?.maxWidth != null) ?
                    `minmax(auto, ${column.style.maxWidth})`
                    : 'minmax(auto, 20em)';

            let actualSize = undefined;
            if (settings?.ColumnSettings != null && column.id in settings?.ColumnSettings) {
                actualSize = settings?.ColumnSettings[column.id].size;
            }

            return { defaultSize, actualSize } as ColumnSize;
        }));

        const resizeColumnHandlers = fields.map(column => {
            const columnId = column.id;

            return _.debounce((size:number) => {
                let settings = getValueFromLocalStorage<CrmGridViewUserSettings>(userSettingsKey);
                settings = setSettingsColumnSize(settings, columnId, size);
                setValueToLocalStorage(userSettingsKey, settings);
            }, 1000);
        });

        const onResize = (index: number, size: number) => {
            let indexOffset = 0;

            if (props.onContextMenu || props.onChangeSelectedRows) {
                indexOffset -= 1;
            }

            resizeColumnHandlers[index + indexOffset](size);
        }

        const layout = {
            custom: true, 
            fixedHeader: true, 
            horizontalScroll: true,
            columnSizes,
            onResize: onResize
        } as Layout;

        return layout;
    }, [fields, props.onContextMenu, props.onChangeSelectedRows]);

    const onChangeSelectedRow = useCallback((entityId: string, selected: boolean) => {
        if (selectedRows == "all") {
            if (!selected) {
                props.onChangeSelectedRows?.(props.entityIds.filter(x => x != entityId));
            }

            return;
        }

        if (selected && !selectedRows.includes(entityId)) {
            const newSelectedRows = [...selectedRows, entityId];

            props.onChangeSelectedRows?.(newSelectedRows);
            return;
        }
        if (!selected && selectedRows.includes(entityId)) {
            const newSelectedRows = selectedRows.filter(x => x != entityId);

            props.onChangeSelectedRows?.(newSelectedRows);
        }
    }, [props.selectedRows, props.entityIds, props.onChangeSelectedRows]);

    const onCheckAllChange = useCallback((e: CheckboxChangeEvent) => {
        const newSelectedRows = e.target.checked ? "all" : [];
        props.onChangeSelectedRows?.(newSelectedRows);
    }, [props.onChangeSelectedRows]);


    return (<div className={styles.tableWrapper} style={props.style}>
        <div className={styles.gridContainer}>
            <Table
                data={data}
                layout={layout}
            >
                {(tableList: ExtendedNode<CrmTableNode>[]) => (
                <>
                    <Header>
                        <HeaderRow>
                            {(props.onContextMenu || props.onChangeSelectedRows) &&
                                <HeaderCell className={styles.systemHeaderCell}>
                                    {props.onContextMenu &&
                                        <div style={{ width: "1em" }}></div>}
                                    {props.onChangeSelectedRows &&
                                        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={allChecked} disabled={props.isLoading}/>}
                                </HeaderCell>
                            }
                            {fields.map(column =>
                                <HeaderCell key={column.id} resize={true}>
                                    <span className={styles.headerText}>{column.caption}</span>
                                    {props.showFilters &&
                                        <CrmGridSimpleFilterButton
                                            query={props.simpleFilters?.[column.id]}
                                            field={column}
                                            onChangedSimpleQuery={query => props.onChangedSimpleQuery && props.onChangedSimpleQuery(column.id, query)}
                                            onResetSimpleQuery={() => props.onResetSimpleQuery && props.onResetSimpleQuery(column.id)}
                                        />
                                    }
                                </HeaderCell>
                            )}
                        </HeaderRow>
                    </Header>

                    <Body>
                        {props.isLoading ||
                            <>{tableList.map((node, index) => <CrmGridRow 
                                key={index}
                                tableId={props.tableId}
                                rowNumber = {index}
                                entityId={node.value}
                                className={""}
                                fields={props.fields}
                                onContextMenu={props.onContextMenu}
                                onCellDoubleClicked={props.onCellDoubleClicked}
                                useItemByIdSelector={props.useItemByIdSelector}
                                onRowClick = {props.onRowClick}
                                onChangeSelected={props.onChangeSelectedRows ? selected => onChangeSelectedRow(node.value, selected) : undefined}
                                focused={node.value === props.focusedEntityId}
                                selected={selectedRows == "all" || selectedRows.includes(node.value)}
                                />)
                            }</>
                        }
                    </Body>
                </>
            )}
            </Table>
            {data.nodes.length == 0 && !props.isLoading &&
                <div className={styles.noData}>{t("noOrders")}</div>
            }
            {props.isLoading &&
                <div className={styles.noData}>{t("loading")}</div>
            }
        </div>
        { showPagination ?
            <CrmPaginationInternal
                onFirstPageClicked={()=>props.ResetCursor()}
                onPreviousPageClicked={()=>props.MoveCursor(-props.numRowsPerPage)}
                onNextPageClicked={()=>props.MoveCursor(props.numRowsPerPage)}
                hasPreviousPage={!props.cursorAtStart}
                hasNextPage={!props.cursorAtEnd}
            />
            :
            <div/>
        }
    </div>)
}