import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { BaseTool, CompositeTool, IApiForAIAgent } from "./ai-api";
import { ListFieldsTool } from './fieldsManager';
import { IFilter, QueryFilter, QueryFilterV2 } from 'src/App/Pages/OrdersPage/OrderFilters/OrderFilters';
import Logger from 'js-logger';
import { CrmFieldViewType, CrmSortDirection } from '@core/Models/autogenerated/tenantConfig.models';
import { ICrmFilter } from '@core/Models/tenantConfig.models';
import { validateMongoFilter } from '@core/Validators/validateMongoFilter';
import { createQueryVars } from '@core/Stores/QueryVars';
import { IQuerySpecification } from '@core/EventSourcing/Implementation/LiveQuery';
import { IEntity } from '@core/Models/i-entity';

const getFilterManagerPrompt = async (api:IApiForAIAgent) => {
    const currentTableId = api.getCurrentSelectedTable()?.tableId ?? "orders";

    return `You are an AI CRM filters (also known as tabs) management tool. 
There is no conversation with user, so all information must be used as is. 
You need to use provided tools to solve the task. 
filter structure is: 
{ 
    "id": <filter id as string. Leave this field undefined when creating>, 
    "caption": <filter caption. User see this as tab name. Required>, 
    "conditions": <deprecated. Old-format filter condition. Do not use it. Leave it undefined>,
    "where": <conditions in new version of filter structure. It is similar to mongo query language. Required>,
    "users": <optional. array of strings: user logins that can use this filter. Default "null" - means for all users>,
    "fields": <optional. array of strings: field ids. when user use this filter, view display this subset of fields. Default "null" - means all show fields>
	"sortField": <optional. string of field id to be sorted by>
	"sortDirection": <optional. string of sort direction. Can be '${CrmSortDirection.Asc}' or '${CrmSortDirection.Desc}'>
    ... //other fields without description. preserve this intact when updating
}.

In addition to standard MongoDB-compatible operators, The 'where' field has unique features and limitations:
- Standard MongoDB Operators
    Supports $eq, $ne, $gt, $gte, $lt, $lte, $in, $nin, $and, $or, $not, and $type. Syntax and behavior are identical to MongoDB.

- String Case Sensitivity
    String comparisons are case-insensitive by default, so all values are normalized to lowercase.

- Time-Based Queries
    The where field supports advanced time-based queries, allowing conditions to be based on the current date and time:
    IMPORTANT: You must use this operator for fields of type '${CrmFieldViewType.Date}' or '${CrmFieldViewType.DateTime}' (except for comparison with null) and not for any other fields.

    - $time Operator: This operator enables relative time-based conditions within where. You can specify:
        - Base Time: The point in time to compare against. Options:
            - "$now": Current local time.
            - "$utc_now": Current UTC time.
            - An ISO-formatted date string, e.g., "2024-11-01T10:00:00Z".
            - Example: To find records created at the current day: {
                "createdAt": {
                    "$gte": {
                        "$time": "$now.startOfDay"
                    }
                }
            }

        - Granularity Modifiers: Applied to set the precision of the base time.
            - .startOfDay, .endOfDay: Start or end of the day.
            - .startOfMonth, .endOfMonth: Start or end of the month.
            - .startOfYear, .endOfYear: Start or end of the year.
            - Examples:
                - From the start of the Current Month: {
                    "Date": {
                        "$gte": {
                            "$time": "$now.startOfMonth"
                        }
                    }
                }
                - To the end of the Current Year: {
                    "Date": {
                        "$lte": {
                            "$time": "$now.endOfYear"
                        }
                    }
                }
                - For a date equal to the current day: {
                    "Date": {
                        "$gte": {
                            "$time": "$now.startOfDay"
                        },
                        "$lte": {
                            "$time": "$now.endOfDay"
                        }
                    }
                }

        - Arithmetic Operations: You can add or subtract time intervals from the base time. This is specified in the format + or -, followed by a number and a time unit:
            - sec (seconds), min (minutes), h (hours), d (days), m (months), y (years).
            - Examples:
                - Until the end of the day following the current day: {
                    "Date": {
                        "$lte": {
                            "$time": "$now.endOfDay + 1d"
                        }
                    }
                }
                - Not earlier than three months ago: {
                    "Date": {
                        "$gte": {
                            "$time": "$now - 3m"
                        }
                    }
                }
                - Until the beginning of next month: {
                    "Date": {
                        "$lt": {
                            "$time": "$now.startOfMonth + 1m"
                        }
                    }
                }

- Variable Support
    Allows variables in queries, especially the user variable for the current user's login. Use $var to include variables (e.g., "user": {"$var": "user"}).

- Additional Operators
    These operators extend MongoDB functionality, allowing enhanced filtering based on specific requirements.

    - $between and $jbetween: Checks if a field's value falls within a specified range:
        - $between: Performs strict, type-safe comparisons.
        - $jbetween: Uses JavaScript's loose comparison for values, allowing for slight variations in types.
        - Example: Finds records where field is between 10 and 50: {
            "field": {
                "$between": [10, 50]
            }
        }

    - $contains, $containsString, $containsAny, $containsNone: These operators handle array and string containment checks:
        - $contains: Checks if an array or object fully contains all elements/keys in another array or object.
            - Example: {
                "tags": {
                    "$contains": ["tag1", "tag2"]
                }
            }
        - $containsString: Checks if a string contains another substring.
            - Example: {
                "description": {
                    "$containsString": "keyword"
                }
            }
        - $containsAny: Checks if any element of a specified array is in another array.
            - Example: {
                "tags": {
                    "$containsAny": ["tag1", "tag2"]
                }
            }
        - $containsNone: Checks that none of the elements in a specified array exist in another array.
            - Example: {
                "tags": {
                    "$containsNone": ["tag3", "tag4"]
                }
            }

    - $elemMatch: Evaluates array elements based on a given predicate (an embedded query):
        - Example: Finds records where any tags array element has type equal to "important": {
            "tags": {
                "$elemMatch": { "type": { "$eq": "important" } }
            }
        }
        
    - $size and $len: These operators check the length of arrays and strings, respectively.
        - $size: Checks the length of an array:
            - Example: Finds records where items array has exactly 5 elements: {
                "items": {
                    "$size": 5
                }
            }
        - $len: Checks the length of a string:
            - Example: Finds records where name is exactly 10 characters long: {
                "name": {
                    "$len": 10
                }
            }

- Some MongoDB operators (which are not described above) are not supported in this filter.

Notes:
- If there is no suitable format for filter, return appropriate error message.
- Every table uses its own set of filters.
- Each filter work with data from its table.
- If you want to check that a value is empty, you should pay attention to the field type:
    - If it is a string type (such as: '${CrmFieldViewType.String}', '${CrmFieldViewType.MultiString}', '${CrmFieldViewType.Combobox}', '${CrmFieldViewType.Phone}', '${CrmFieldViewType.Time}'), then use the $len operator with a value of 0.
    - If it is an array type (such as: '${CrmFieldViewType.Array}', '${CrmFieldViewType.Comments}', '${CrmFieldViewType.Tags}'), then use the $size operator with a value of 0.
    - If it is a decimal type (such as: '${CrmFieldViewType.Decimal}', '${CrmFieldViewType.Date}', '${CrmFieldViewType.DateTime}'), then use the $eq operator with a value of null.
- For fields of type '${CrmFieldViewType.Time}', the value is stored in the string format "HH:mm" in 24-hour format. For this format, the $time operator is prohibited. Only string comparison can be used.
    For example, to check that the time is in the afternoon, use the following expression: {"openTime": {"$gte": "12:00"}}
- For fields of type '${CrmFieldViewType.Comments}', the value is stored as an array of objects with the following fields:
    - "id": id of the comment,
    - "date": date comment created,
    - "author": a string with the login of the comment author or "System",
    - "message": comment text,
    - "metadata": an optional object with additional information about the comment.
- If the user wants you to show some rows, you need to create a filter for him that will show such rows in the table.

IMPORTANT:
 * You must test the filter and analyze the result before adding the filter.

Table currently opened by the user is { "tableId": "${currentTableId}"}
The summary information on available fields(in short format) in CRM:${await new ListFieldsTool(api, true).run("")}.
The summary information on available filters(in short format) in CRM:${await new ListFiltersTool(api).run("")}.`;
}


export class FiltersManagerTool extends CompositeTool {
    name: string = "filters_manager";
    description: string = 
`This function is useful when a user wants to manage CRM filters. 
Filters represent filtered data view. User use filters when need to observe data filtered and sorted by some conditions. 
Do not try to use this function if user wants to change cells or rows or use conditional behaviour on unfiltered data. 
The input is a text with a human-readable description of the filter modification task. 
`;
    
    toolkit: BaseTool[];

    public getPrompt = (): Promise<string> => getFilterManagerPrompt(this.api);

    constructor(api: IApiForAIAgent, access_token : string) {
        super(api, access_token, 'gpt-4o');

        this.toolkit = [
            new ListFieldsTool(this.api, true),
            new ListFiltersTool(this.api),
            new AddFilterTool(this.api),
            new DeleteFilterTool(this.api),
            new UpdateFilterTool(this.api),
            new GetFilterInfoTool(this.api),
            new TestFilterTool(this.api),
        ];
    }

    async run(query: string) : Promise<string> {
        console.log(`*FiltersManagerTool ${query}`);

        const agent = await this.createAgent();

        const response = await agent.invoke({
            input: query,
        });

        return response.output;
    }
}

export class TestFilterTool extends BaseTool {
    name: string = "test_filter";
    description: string =
`Use it to validate filter in case a user wants to create or update filter.
This tool must be used every time you want to create or update filter. Different filters should be tried until a meaningful answer is obtained.
There is no chance to create or update filter without using this tool.
Call this tool to make sure that filter is ok (based on output).
The output data is not intended to be presented to the user.

Output value is a JSON string representing at most 10 records that satisfy the filter.
Input should be a JSON string in the following format: 
{ 
  "tableId": <target table ID>, 
  "filter": <filter config according filter structure>, 
}.
`;

    async run(query: any): Promise<string> {
        try {
            console.log(`*TestFilterTool ${query}`);
            let task : {tableId: string, filter: ICrmFilter};

            try {
                task = JSON.parse(query);
            }
            catch (error: any) {
                return "Exception: " + error.toString() + ". Input is incorrect JSON";
            }

            const userInfo = this.api.getUserInfo();
            const queryVars = createQueryVars(userInfo);

            task.filter.id = uuidv4();
            const filterError = validateMongoFilter(task.filter, queryVars);
            if (filterError != null) {
                return filterError;
            }

            const store = this.api.getStore();

            const querySpec: IQuerySpecification = {
                where: task.filter.where,
                orderBy: task.filter.sortField ?? null,
                transient: true,
            }
            const entities = await store.queryEntities(querySpec, queryVars, 10);
            let result: IEntity[] = [];
            
            if (task.filter.fields == null) {
                result = entities;
            }
            else {
                for (let entity of entities) {
                    const newEntity: IEntity = { ...entity, data: { ...entity.data } };

                    for (const field in entity.data) {
                        if (!task.filter.fields.includes(field)) {
                            delete newEntity.data[field];
                        }
                    }

                    result.push(newEntity);
                }
            }

            return JSON.stringify(result);
        }
        catch (error: any) {
            return "Exception: " + error.toString();
        }
    }
}

export class AddFilterTool extends BaseTool {
    name: string = "add_filter";
    description: string =
`useful for when you need add filter view to CRM.
Input should be a JSON object in the following format: 
{ 
  "tableId": <target table ID>, 
  "filter": <filter config according filter structure>, 
}.`;

    async run(query: string): Promise<string> {
        try {
            console.log(`*AddFilterTool ${query}`);
            let task : {tableId: string, filter: ICrmFilter};

            try {
                task = JSON.parse(query);
            }
            catch (error: any) {
                return "Exception: " + error.toString() + ". Input is incorrect JSON";
            }
    
            task.filter.aiGenerated = true;
            task.filter.id = uuidv4();
            task.filter.version = 2;

            const userInfo = this.api.getUserInfo();
            const queryVars = createQueryVars(userInfo);

            const filterError = validateMongoFilter(task.filter, queryVars);
            if (filterError != null) {
                return filterError;
            }

            let oldFilters = this.api.getFilters(task.tableId) ?? [];

            if (oldFilters.find(x => x.caption.trim() === task.filter.caption.trim()))
                return `Filter with caption '${task.filter.caption}' is already exist in CRM`;

            let newFilters = [...oldFilters, task.filter];

            await this.api.saveFilters(task.tableId, newFilters);
            this.api.selectFilter(task.tableId, task.filter.id);

            return "Filter successfully added.";
        }
        catch (error: any) {
            return "Exception: " + error.toString();
        }
    }
}

export class DeleteFilterTool extends BaseTool {
    name: string = "delete_filter";
    description: string =
`useful for when you need to delete filter view from CRM.
Input should be a JSON object in the following format: 
{ 
  "tableId": <target table ID>, 
  "filterId": <target filter id>
}.`;

    async run(query: string): Promise<string> {
        try {
            console.log(`*DeleteFilterTool ${query}`);
            let task : {tableId: string, filterId: string};

            try {
                task = JSON.parse(query);
            }
            catch (error: any) {
                return "Exception: " + error.toString() + ". Input is incorrect JSON";
            }

            let oldFilters = this.api.getFilters(task.tableId) ?? [];
    
            let newFilters: any[] = [];
            let filterFounded = false;

            for (let f of oldFilters) {
                if (f.id !== task.filterId) {
                    newFilters.push(f);
                } else {
                    filterFounded = true;
                }
            }

            if (!filterFounded)
                return `Error: filter was not deleted because given id ${task.filterId} not found. You can use list_filters to look at available filters.`;

            await this.api.saveFilters(task.tableId, newFilters);
            return "filter deleted successfully";
        }
        catch (error: any) {
            return "Exception: " + error.toString();
        }
    }
}

export class GetFilterInfoTool extends BaseTool {
    name: string = "get_filter_info";
    description: string =
`useful for when you need to get full information about specific filter.
Input should be a JSON object in the following format: 
{ 
  "tableId": <target table ID>, 
  "filterId": <target filter id>
}.
Output is a JSON with filter configuration.`;
    async run(query: string) : Promise<string> {
        try {
            console.log(`*GetFilterInfoTool ${query}`);

            let task : {tableId: string, filterId: string};

            try {
                task = JSON.parse(query);
            }
            catch (error: any) {
                return "Exception: " + error.toString() + ". Input is incorrect JSON";
            }

            let filters = this.api.getFilters(task.tableId) ?? [];

            const result = filters.find(x => x.id === task.filterId);
            if (!result)
                return `Error: can not read filter properites because filter with id '${task.filterId}' not found in table with id '${task.tableId}'. You can use list_filters to look at available filters.`; 

            return JSON.stringify(result);
        }
        catch (error : any) {
            return "Exception: " + error.toString();
        }
    }
}

export class UpdateFilterTool extends BaseTool {
    name: string = "update_filter";
    description: string =
`useful for when you need to update filter properites in CRM. Make sure you have full current filter configuration. Preserve all properites intact except updated fields.
Input should be a JSON object in the following format: 
{ 
  "tableId": <target table ID>, 
  "filter": <new filter config according filter structure, id must be existing filter id>, 
}`;
    async run(query: string) : Promise<string> {
        try {
            console.log(`*UpdateFilterTool ${query}`);

            let task : {tableId: string, filter: ICrmFilter};

            try {
                task = JSON.parse(query);
            }
            catch (error: any) {
                return "Exception: " + error.toString() + ". Input is incorrect JSON";
            }

            const userInfo = this.api.getUserInfo();
            const queryVars = createQueryVars(userInfo);

            const filterError = validateMongoFilter(task.filter, queryVars);
            if (filterError != null) {
                return filterError;
            }

            task.filter.aiGenerated = true;

            let oldFilters = this.api.getFilters(task.tableId) ?? [];

            let newFilters : any[] = [];
            let filterFounded = false;

            for(let f of oldFilters) {
                if (f.id === task.filter.id) {
                    newFilters.push(task.filter);
                    filterFounded = true;
                } else {
                    newFilters.push(f);
                }
            }

            if (!filterFounded) 
                return `Error: filter was not updated because filter with id '${task.filter.id}' not found in table with id '${task.tableId}'. You can use list_filters to look at available filters.`;

            this.api.saveFilters(task.tableId, newFilters);
            return "filter updated successfully";
        }
        catch (error : any) {
            return "Exception: " + error.toString();
        }
    }
}

export class ListFiltersTool extends BaseTool {
    name: string = "list_filters";
    description: string =
        "useful for when you need to get brief summary information about all available filters in CRM. Input is empty. Output is existing filters represented as array of objects of partial filter structure.";
    async run(query: string) : Promise<string> {
        try {
            console.log(`*ListFilters ${query}`);

            let config = this.api.getOrderConfig();
            if (!config)
                return "[]";

            let result = [];
            for (let tableConfig of config.tables) {
                let filters = _.map(tableConfig.filters, (x: ICrmFilter) => { return {id: x.id, caption: x.caption} });
                result.push({
                    tableId: tableConfig.tableId,
                    tableName: tableConfig.tableName,
                    filters: filters
                }) 
            }
            return JSON.stringify(result);
        }
        catch (error : any) {
            return "Exception: " + error.toString();
        }
    }
}