import { IEventProcessorEf, IGenerateProcessorReq } from '@core/Models/autogenerated/processes.models';
import axios, { AxiosResponse } from 'axios';
import { serverUrl } from '@platform/config';

const endpoint = `${serverUrl}/api/EventProcessor`;

export async function eventProcessorApiGenerate(req: IGenerateProcessorReq): Promise<AxiosResponse<IEventProcessorEf, any>> {
    const resp = await axios.post(`${endpoint}/Generate`, JSON.stringify(req));
    return resp;
}

export async function eventProcessorApiAdd(req: IEventProcessorEf): Promise<AxiosResponse<any, any>> {
    const resp = await axios.post(`${endpoint}/Add`, JSON.stringify(req));
    return resp;
}

export async function eventProcessorApiDelete(id: string): Promise<AxiosResponse<any, any>> {
    const resp = await axios.post(`${endpoint}/Delete?id=`+id.toString());
    return resp;
}

export async function eventProcessorApiList(): Promise<AxiosResponse<IEventProcessorEf[], any>> {
    const resp = await axios.get(`${endpoint}/List`);
    return resp;
}
