import Logger from "js-logger";
import { isIosSafari } from "src/Utils/userAgent";

export var stealedLocks = new Set();

export function lock<T>(name: string, handler: (lock: Lock|null) => Promise<T>, options?: LockOptions) : Promise<T> {
    if (isIosSafari() && !stealedLocks.has(name)) { //bug workaround. safari sometimes not releasing lock even if page are closed
        stealedLocks.add(name);

        return navigator.locks.request(name, {steal: true}, async (lock) => {
            Logger.debug(`lock ${name} stealed`);
            return await handler(lock);
        });
    } else {
        return navigator.locks.request(name, async (lock) => {
            return await handler(lock);
        })
    }
}