import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { store } from "../store";

export interface IFiltersView {
    currentFilterId: string|null;
    currentFilterOffset: number;
}
export interface ISetCurrentFilterArgs
{
    id: string|null;
    offset: number;
}

export const filtersViewSlice = createSlice({
    name: 'filtersView',
    initialState: () => {
        let state = {
            currentFilterId: null,
            currentFilterOffset: 0,
        } as IFiltersView;

        return state;
    },
    reducers: {
        setCurrentFilter: (state, action: PayloadAction<ISetCurrentFilterArgs>) => {
            if (state){
                state.currentFilterId = action.payload.id;
                state.currentFilterOffset = action.payload.offset;
            }
        },
        setFilterOffset: (state, action: PayloadAction<number>) => {
            if (state) {
                state.currentFilterOffset = action.payload;
            }
        }
    }
});

export function setCurrentFilter(args : ISetCurrentFilterArgs): void {
    store.dispatch(filtersViewSlice.actions.setCurrentFilter(args));
}

export function setFilterOffset(offset : number): void {
    store.dispatch(filtersViewSlice.actions.setFilterOffset(offset));
}

export default filtersViewSlice.reducer;
