import { CrmFieldViewType, ICrmField } from "@core/Models/autogenerated/tenantConfig.models";
import { ICrmUserInfo } from "@core/Models/autogenerated/user.models";
import { CrmFieldDefault } from "@core/Models/tenantConfig.models";
import { getCurrentUtcDateAsUnix, getCurrentTimeString, getCurrentUtcDateWithTimeAsUnix } from "./js-date-transformations";

export function getDefaultValue(field: ICrmField, userInfo: ICrmUserInfo) {
    if (field.default == null) {
        return null;
    }

    switch (field.default) {
        case CrmFieldDefault.User:
            return userInfo.login;

        case CrmFieldDefault.Date:
            if (field.viewType == CrmFieldViewType.Date) {
                return getCurrentUtcDateAsUnix();
            }
            return null;

        case CrmFieldDefault.Time:
            if (field.viewType == CrmFieldViewType.Time) {
                return getCurrentTimeString();
            }
            return null;

        case CrmFieldDefault.Now: {
            switch (field.viewType) {
                case CrmFieldViewType.Date:
                    return getCurrentUtcDateAsUnix()
                case CrmFieldViewType.Time:
                    return getCurrentTimeString();
                case CrmFieldViewType.DateTime:
                    return getCurrentUtcDateWithTimeAsUnix();
                default:
                    break;
            }
            return null;
        }

        default:
            return field.default;
    }
}