import type { ICrmArrayOperationAdd, ICrmArrayOperationRemove, ICrmArrayUpdateEvent } from "@core/Models/i-crm-array-operation-events";
import type { ICrmOperationEventDecoded } from "@core/Models/i-crm-operation-event-decoded";
import type { ICrmCommentsOperationEvent } from "@core/Models/i-crm-timeline-operation-event";


export function migratePushMessagesToTimelineOrderFieldV2(event: ICrmOperationEventDecoded): ICrmOperationEventDecoded {
    const changes = event.decodedData as ICrmCommentsOperationEvent;

    let remove: ICrmArrayOperationRemove[] = [];
    let add: ICrmArrayOperationAdd [] = [];
    
    for (let e of changes.messages ?? []) {
        remove.push({id: e.id} as ICrmArrayOperationRemove)
        add.push({pos: 0, value: e} as ICrmArrayOperationAdd)
    }

    let data: ICrmArrayUpdateEvent = {
        fieldId: changes.fieldId,
        add: add,
        remove: remove,
        update: [],
    };

    return {
        ...event,
        type: 'ArrayUpdate',
        data: JSON.stringify(data),
        decodedData: data
    }
}