import { CommentsDateTime } from './CommentsDateTime';
import { ReactComponent as TrashCanOutlineIcon } from "@assets/Icons/trash-can-outline-icon.svg";
import styles from './CommentsMessage.module.scss';
import { t } from 'i18next';
import { ReactNode, useRef, useState } from 'react';
import { ConfirmDelete } from '../ConfirmDelete/ConfirmDelete';
import { UserProfileIcon } from '../Icons/UserProfileIcon/UserProfileIcon';
import { CrmText } from '../CrmText';
import { registerComponent } from '@core/Plugins/pluginManager';
import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';

export interface ICommentsMessageProps {
    text: ReactNode;
    date: number;
    author?: string;
    onDelete?: () => void;
}

export const CommentsMessage = registerComponent(coreUiComponentDescriptions.CommentsMessage, _CommentsMessage);

function _CommentsMessage(props: ICommentsMessageProps) {
    const [isConfirmShow, setIsConfirmShow] = useState<boolean>(false);
    const deleteButtonRef = useRef<any>(null);

    const onDelete = () => {
        if (props.onDelete) {
            setIsConfirmShow(true);
        }
    };

    const onCancelDelete = () => {
        setIsConfirmShow(false);
    };

    const onConfirmDelete = () => {
        props.onDelete?.();
    };

    return (
        <div className={styles.host}>
            <div className={styles.header}>
                <UserProfileIcon name={props.author}/>
                {props.author || ""}
                <CommentsDateTime date={props.date}/>
            </div>
            <div className={styles.deleteButton} onClick={onDelete}>
                {props.onDelete && <TrashCanOutlineIcon ref={deleteButtonRef} title={t("delete")}/>}
            </div>
            <ConfirmDelete
                triggerRef={deleteButtonRef}
                visible={isConfirmShow}
                onCancel={onCancelDelete}
                onDelete={onConfirmDelete}
            />
            <p className={styles.text}>
                {typeof props.text == "string"
                    ? <CrmText text={props.text}/>
                    : props.text
                }
            </p>
        </div>
    );
}