import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {store} from '@core/Redux/store';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import './Scss/index.scss';
import {setupAxiosInterceptors} from '@core/AxiosInterceptors/setup-interceptors';
import Logger from 'js-logger';
import {initLogger} from '@core/Services/logger-service';
import {serializeError} from 'serialize-error';
import "./Locale/i18n";
import "./Locale/date-fns";
import "@assets/Scripts/long-press-event.min";
import { PRIMARY_COLOR } from './theme';

declare var location: Location;
const container = document.getElementById('root')!;
const root = createRoot(container);

initLogger();
setupAxiosInterceptors(location);

const onComponentError = (error: any, info: any) => {
    debugger;
    const sErr = serializeError(error, { maxDepth: 2 });
    Logger.error('Unhandled component error', sErr);
};

root.render(
    // <React.StrictMode> Почему-то вызывает монтирование App дважды
   // <ErrorBoundary fallback={<div>Something went wrong</div>}
    //               onError={(e,i) => onComponentError(e,i)}>
        <Provider store={store}>
            <App/>
            {/* <Router>
                <App/>
            </Router> */}
        </Provider>
  //  </ErrorBoundary>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
