import styles from './MainMenuItemCaption.module.scss';

export interface IMainMenuItemCaptionProps {
    children: any;
}

export function MainMenuItemCaption(props: IMainMenuItemCaptionProps) {
    return (<div className={styles.host}>
        {props.children}
    </div>);
}
