import { IEventSourcingStoreReactClient } from '@core/EventSourcing/EventSourcingClientBase';
import {createAsyncThunk} from '@reduxjs/toolkit';
import Logger from 'js-logger';
import {serializeError} from 'serialize-error';

export const deleteOrderAsync = createAsyncThunk(
    'orders/deleteOrderAsync',
    async ( args : { store : IEventSourcingStoreReactClient, entityId: string }, {dispatch}) => {
        try {
            Logger.debug('deleteOrderAsync', {id: args.entityId})
            const time = performance.now();
            await args.store.delete(args.entityId);
            Logger.debug(`delete orders spent ${performance.now() - time} ms`);
        } catch (err: any) {
            Logger.error('deleteOrderAsync failed');
            Logger.error(serializeError(err, { maxDepth: 2}));
            throw new Error(err);
        }
    }
);
