import {CrmCellDateEditor} from './CrmCellDateEditor/CrmCellDateEditor';
import {CSSProperties, MutableRefObject, useRef, useState} from 'react';
import {CrmCellTimeEditor} from './CrmCellTimeEditor/CrmCellTimeEditor';
import { IOnShowEditorReq, coreUiComponentDescriptions } from '@pluginShared/core-ui-api';
import { registerComponent } from '@core/Plugins/pluginManager';
import { ICrmArrayElement } from '@core/Models/i-array-element';
import { ModalWindow } from '@core/VisualComponents/ModalWindow/ModalWindow';
import { ArrayEditorInner } from '@core/VisualComponents/ArrayComponents/ArrayEditorInner/ArrayEditorInner';
import { t } from 'i18next';
import { StringInput } from '@core/VisualComponents/Inputs/StringInput/StringInput';
import { InputValidator } from '@core/VisualComponents/Inputs/InputValidator/InputValidator';
import { CrmEditOverlay, ICrmEditOverlayProps } from './CrmEditOverlay/CrmEditOverlay';
import { UrlInput } from '@core/VisualComponents/Inputs/UrlInput/UrlInput';
import { DecimalInput } from '@core/VisualComponents/Inputs/DecimalInput/DecimalInput';
import { CheckboxInput } from '@core/VisualComponents/Inputs/CheckboxInput/CheckboxInput';
import { ComboboxInput } from '@core/VisualComponents/Inputs/ComboboxInput/ComboboxInput';
import { CommentsEditView } from '@core/VisualComponents/Comments/CommentsEditView';
import { PhoneInput } from '@core/VisualComponents/Inputs/PhoneInput/PhoneInput';
import { IEntityData } from '@core/Models/i-entity';
import { ICrmField, CrmFieldViewType } from '@core/Models/autogenerated/tenantConfig.models';
import _ from 'lodash';
import { TagsInput } from '@core/VisualComponents/Inputs/TagsInput/TagsInput';

export interface ICellEditor {
    req: IOnShowEditorReq;
}

export interface ICrmCellInputEditorsProps {
    onHide: () => void;
    onSave: (value: ICrmArrayElement[] | string | number | boolean | null) => void;
    tableId: string;
    entityId: string;
    initialValue: any;
    entityData: IEntityData;
    autocompleteValues: string[];
    field: ICrmField;
    targetRef: MutableRefObject<any>;
    readonly?: boolean;
    style?: CSSProperties;
}

export const CrmCellInputEditors = registerComponent(coreUiComponentDescriptions.CrmCellInputEditors, _CrmCellInputEditors);

function _CrmCellInputEditors(props: ICrmCellInputEditorsProps) {

    const popperConfig = {
        modifiers: [
            {
                name: 'preventOverflow',
                options: {
                    boundary: 'viewport',
                },
            },
            {
                name: 'flip',
                options: {
                    fallbackPlacements: ['left', 'right', 'bottom'],
                },
            },
        ]
    };

    const valueRef = useRef<any>(props.initialValue);
    const [isValid, setIsValid] = useState<boolean>(true);

    const onSave = () => {
        let newValue = valueRef.current;

        if (!_.isEqual(newValue, props.initialValue)) {
            props.onSave(newValue);
        }

        props.onHide();
    }

    const { viewType } = props.field;

    const overlayProps: ICrmEditOverlayProps = {
        onHide: props.onHide,
        onSave: onSave,
        targetRef: props.targetRef,
        disabledSave: !isValid,
    }

    const beforeHide = () => {
        let confirmed = true;
        if (!_.isEqual(props.initialValue, valueRef.current)) {
            // eslint-disable-next-line no-restricted-globals
            confirmed = confirm(t("confirm-drop-changes"));
        }

        return confirmed;
    }

    if (props.readonly || props.field.readonly) {
        return <></>;
    }

    switch (viewType) {
        case CrmFieldViewType.Time:
            return <CrmCellTimeEditor
                value={props.initialValue as number}
                valueRef={valueRef}
                targetRef={props.targetRef}
                onSave={onSave}
                onHide={() => props.onHide()}
                popperConfig={popperConfig}
            />;
        case CrmFieldViewType.Date:
        case CrmFieldViewType.DateTime:
            return <CrmCellDateEditor
                withTime={viewType == CrmFieldViewType.DateTime}
                value={props.initialValue as number}
                valueRef={valueRef}
                targetRef={props.targetRef}
                onSave={onSave}
                onHide={() => props.onHide()}
                popperConfig={popperConfig}
            />;
        case CrmFieldViewType.YesNo:
            return <CrmEditOverlay {...overlayProps}>
                <CheckboxInput
                    initialValue={props.initialValue as boolean}
                    placeholder={props.field.caption}
                    valueRef={valueRef}
                    setIsValid={setIsValid}
                    validation={value => InputValidator.validateByField(value, props.field)}
                />
            </CrmEditOverlay>
        case CrmFieldViewType.Combobox:
            return <CrmEditOverlay {...overlayProps}>
                <ComboboxInput
                    initialValue={props.initialValue as string}
                    options={props.field.options ?? []}
                    placeholder={props.field.placeholder}
                    valueRef={valueRef}
                    setIsValid={setIsValid}
                    validation={value => InputValidator.validateByField(value, props.field)}
                    autoFocus
                />
            </CrmEditOverlay>;
        case CrmFieldViewType.Timeline:
        case CrmFieldViewType.Comments:
            return <ModalWindow
                onHide={props.onHide}
                title={props.field.caption}
                styles={{
                    body: { padding: 0 }
                }}
            >
                <CommentsEditView
                    field={props.field}
                    tableId={props.tableId}
                    entityId={props.entityId}
                    isReversed
                    style={props.style}
                />
            </ModalWindow>;
        case CrmFieldViewType.String:
        case CrmFieldViewType.MultiString:
        case CrmFieldViewType.Unknown:
            return <CrmEditOverlay {...overlayProps}>
                <StringInput
                    initialValue={props.initialValue as string}
                    placeholder={props.field.placeholder}
                    autocompleteValues={props.field.autocomplete ? props.autocompleteValues : undefined}
                    onEnter={onSave}
                    valueRef={valueRef}
                    setIsValid={setIsValid}
                    type={viewType == CrmFieldViewType.String ? "text" : "textarea"}
                    validation={value => InputValidator.validateByField(value, props.field)}
                    autoFocus
                />
            </CrmEditOverlay>;
        case CrmFieldViewType.Url:
            return <CrmEditOverlay {...overlayProps}>
                <UrlInput
                    initialValue={props.initialValue as string}
                    placeholder={props.field.placeholder}
                    onEnter={onSave}
                    valueRef={valueRef}
                    setIsValid={setIsValid}
                    validation={value => InputValidator.validateByField(value, props.field)}
                    autoFocus
                />
            </CrmEditOverlay>;
        case CrmFieldViewType.Phone:
            return <CrmEditOverlay {...overlayProps}>
                <PhoneInput
                    initialValue={props.initialValue as string}
                    placeholder={props.field.placeholder}
                    autocompleteValues={props.field.autocomplete ? props.autocompleteValues : undefined}
                    onEnter={onSave}
                    valueRef={valueRef}
                    setIsValid={setIsValid}
                    autoFocus
                />
            </CrmEditOverlay>;
        case CrmFieldViewType.Array:
            return <ModalWindow
                    title={props.field.caption}
                    beforeHide={beforeHide}
                    onHide={props.onHide}
                    onSave={onSave}
                >
                    <ArrayEditorInner
                        initialValues={props.initialValue}
                        onChanged={values => valueRef.current = values}
                        setIsValid={setIsValid}
                        saveIfInvalid
                        tableId={props.tableId}
                        field={props.field}
                        reversed
                    />
                </ModalWindow>;
        case CrmFieldViewType.Decimal:
            return <CrmEditOverlay {...overlayProps}>
                <DecimalInput
                    initialValue={props.initialValue as string}
                    placeholder={props.field.placeholder}
                    onEnter={onSave}
                    valueRef={valueRef}
                    setIsValid={setIsValid}
                    validation={value => InputValidator.validateByField(value, props.field)}
                    autoFocus
                />
            </CrmEditOverlay>;
        case CrmFieldViewType.Tags:
            return <CrmEditOverlay {...overlayProps}>
                <TagsInput
                    initialValue={props.initialValue as string[]}
                    options={props.field.options ?? []}
                    placeholder={props.field.placeholder}
                    valueRef={valueRef}
                    setIsValid={setIsValid}
                    validation={value => InputValidator.validateByField(value, props.field)}
                    autoFocus
                />
            </CrmEditOverlay>;
        default:
            return <></>;
    };
}
