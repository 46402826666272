import _ from 'lodash';
import {DateTime} from 'luxon';

export function applyFromYmdToUnixTimestampTransormation(value: unknown): number | null {
    if (_.isString(value)) {
        const date = DateTime.fromISO(value);

        if (!date.isValid) {
            return null;
        }

        const offsetInMinutes = date.toJSDate().getTimezoneOffset();
        return DateTime
            .fromISO(value)
            .plus({minute: -offsetInMinutes})
            .toUnixInteger()
            ;
    }

    if (_.isNumber(value))
        return value as number;

    throw new Error('Unsupported data type');
}
