import * as array_operations from './array_operations'
import type { IDbEntity, IDbEntityRawData } from '@core/JsStore/stores/shared/models/i-db-entity';
import type { ICrmArrayUpdateEvent } from '@core/Models/i-crm-array-operation-events';
import type { ICrmArrayElement } from '@core/Models/i-array-element';
import type { ICrmOperationEventDecoded } from '@core/Models/i-crm-operation-event-decoded';

export class EventExecutor
{
    public addOrder(event: ICrmOperationEventDecoded) : IDbEntity
    {
        if (event.type != 'AddOrder') {
            throw new Error(`invalid event type fo AddEntity: ${event.type}`);
        }

        const entity: IDbEntity = {
            lastEventNumber: event.id,
            lastEventTime: Math.floor(new Date(event.createdAt).getTime()/1000),
            entityId: event.entityId,
            entityData: event.decodedData,
        };

        return entity;
    }

    public updateOrder(event: ICrmOperationEventDecoded, oldEntity: IDbEntity) : IDbEntity
    {
        if (event.type != 'UpdateOrder') {
            throw new Error(`invalid event type fo UpdateOrder: ${event.type}`);
        }
        
        let entity = oldEntity;

        if (!event.id || !oldEntity.lastEventNumber || event.id > oldEntity.lastEventNumber) {
            entity = {
                ...this.applyChanges(oldEntity, event.decodedData),
                lastEventNumber: event.id,
                lastEventTime: Math.floor(new Date(event.createdAt).getTime()/1000),
            };
        }

        return entity;
    }

    public arrayUpdate(event: ICrmOperationEventDecoded, oldEntity: IDbEntity) : IDbEntity
    {
        if (event.type != 'ArrayUpdate') {
            throw new Error(`invalid event type fo ArrayUpdate: ${event.type}`);
        }

        let entity = oldEntity;

        if (!event.id || !oldEntity.lastEventNumber || event.id > entity.lastEventNumber) {
            const changes: ICrmArrayUpdateEvent = {
                fieldId: event.decodedData.fieldId,
                add: event.decodedData.add ?? [],
                remove: event.decodedData.remove ?? [],
                update: [...(event.decodedData.move ?? []), ...(event.decodedData.update ?? [])]
            };
            let values = entity.entityData[changes.fieldId] as ICrmArrayElement[];
            if (!Array.isArray(values)) {
                values = [];
            }
            values = array_operations.ApplyArrayModifications(values, changes.add, changes.remove, changes.update);
            
            entity.entityData[changes.fieldId] = values;
            entity.lastEventNumber = event.id;
            entity.lastEventTime = Math.floor(new Date(event.createdAt).getTime()/1000);
        }

        return entity;
    }
    
    private applyChanges(entity: IDbEntity, changes: IDbEntityRawData) : IDbEntity {
        delete changes.id;
        return {
            ...entity,
            entityData: {
                ...entity.entityData,
                ...changes
            }
        }
    }
}