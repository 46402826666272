import {useContext} from 'react';
import {DeviceTypeContext} from '@core/Contexts/DeviceTypeContext';
import styles from './ViewSelector.module.scss';
import ListViewSvg from '@assets/Icons/list-view-icon.svg';
import GridViewSvg from '@assets/Icons/grid-view-icon.svg';
import classNames from 'classnames/bind';

//type ViewMode = 'list' | 'grid';

export enum ViewMode {
    List = 'list',
    Grid = 'grid'
}

export interface IViewSelector{
    viewMode : ViewMode;
    setViewMode : (mode: ViewMode) => void;
}

let cx = classNames.bind(styles);

export function ViewSelector(props: IViewSelector) {
    const onChangeViewClicked = (mode: ViewMode) => {
        props.setViewMode(mode);
    };

    return (
        <div className={styles.additionalToolsPanel}>
            <div className={styles.spring}></div>
            <div className={styles.viewMode}>
                <div className={cx(styles.viewModeButton, { selected: props.viewMode === ViewMode.Grid })}
                    onClick={() => onChangeViewClicked(ViewMode.Grid)}>
                    <img src={GridViewSvg} />
                </div>
                <div className={cx(styles.viewModeButton, { selected: props.viewMode === ViewMode.List })}
                    onClick={() => onChangeViewClicked(ViewMode.List)}>
                    <img src={ListViewSvg} />
                </div>
            </div>
        </div>)
}