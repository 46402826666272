import { MongoQueryParser, NowReference, OrderBySelector, QueryPredicate, QueryVars } from "@core/Utils/MongoQueryParser";
import type { IEntity } from "@core/Models/i-entity";
import _ from "lodash";
import { SearchTools } from "./SearchTools";
import Logger from "js-logger";

export type OrderSpecification = null | string | {
        [columnName: string]: any;
    };

export type WhereSpecification = any;

export interface IQuerySpecification {
    where: WhereSpecification;
    fullTextSearch?: string;
    orderBy: OrderSpecification;
    transient?: boolean;
}

export class LiveQuery {
    wherePredicate: QueryPredicate = () => [true, Infinity];
    orderBySelector: OrderBySelector = (entity) => entity.id;

    nowRef: NowReference = new NowReference();

    mongoQuery: any;
    fullTextSearch: string | undefined = undefined;
    orderBy?: OrderSpecification;
    transient: boolean = false;

    public static fingerprint(query: IQuerySpecification, queryVars: QueryVars) {
        let result = JSON.stringify(query);
        if (queryVars != null && result.includes("$var")) {
            result += "|" + JSON.stringify(Object.fromEntries(queryVars));
        }
        return result;
    }

    public static fromMongoQuery(query: IQuerySpecification, queryVars: QueryVars) {
        const result = new LiveQuery();

        result.mongoQuery = query.where;
        result.fullTextSearch = query.fullTextSearch;
        result.transient = !!query.transient;

        let mainPredicate: QueryPredicate = () => [true, Infinity];
        if (query.where) {
            let parser = new MongoQueryParser(true, queryVars);

            mainPredicate = parser.find(query.where, result.nowRef);
        }


        if (query.fullTextSearch) {
            let textToSearch = query.fullTextSearch.trim().toLowerCase();
            const normalizedTextToSearch = SearchTools.normalizeTextToSearch(textToSearch);

            let fullTextSearchPredicate = (entity: IEntity) => {
                try {
                    return SearchTools.isValuesInEntity(normalizedTextToSearch, entity);
                }
                catch (error) {
                    Logger.error(`[LiveQuery] fullTextSearchPredicate: Error: ${(error as Error).message}`);
                    return false;
                }
            };

            result.wherePredicate = (entity: IEntity) => {
                if (!fullTextSearchPredicate(entity))
                    return [false, Infinity];

                else
                    return mainPredicate(LiveQuery.entity2queryable(entity));
            };
        }
        else {
            result.wherePredicate = (entity: IEntity) => mainPredicate(LiveQuery.entity2queryable(entity));
        }

        if (query.orderBy != null) {

            //todo: hacky workaround for ipku sorting. need to be replaced
            if (query.orderBy === '$ipku_tasks') {
                result.orderBySelector = (entity) => {
                    try {
                        const maxTime = '9999-99-99T99:99';
                        const queryable = LiveQuery.entity2queryable(entity);
                        if (queryable.tasks == null || queryable.tasks.length == 0)
                            return maxTime;

                        const undoneTasks = queryable.tasks.filter((x:any) => !x.completed && x.deadline != null && x.responsible == queryVars.get('user'));
                        if (undoneTasks.length == 0)
                            return maxTime;

                        const dates = undoneTasks.map( (x:any)=> new Date(x.deadline*1000).toISOString().slice(0, 10) + 'T' + x.taskTime?.toString() );

                        const minimum = dates.reduce((a:any, b:any) => a < b ? a : b);
                        
                        return minimum;
                    } catch (err) {
                        return "";
                    }
                }
            }
            else if (typeof query.orderBy === 'string')
                result.orderBySelector = (entity) => LiveQuery.lowerString(LiveQuery.entity2queryable(entity)[query.orderBy as string]);
            else {
                let columns = Object.keys(query.orderBy)
                result.orderBySelector = (entity) => {
                    const queryable = LiveQuery.entity2queryable(entity);
                    return columns.map(c => LiveQuery.lowerString(queryable[c]));
                }
            }
        } else {
            result.orderBySelector = (entity) => entity.id;
        }

        return result;
    }

    private static lowerString(value: any): string {
        if (typeof value === 'string')
            return value.trim().toLocaleLowerCase();
        else
            return value;
    }

    private static entity2queryable(entity: IEntity): Record<string, any> {
        return {
            id:entity.id, 
            _lastEventNumber: entity._lastEventNumber ?? Infinity,
            _lastEventTime: entity._lastEventTime ?? 0,
            ...entity.data
        };
    }
}