import { CrmSortDirection } from "@core/Models/autogenerated/tenantConfig.models";
import { ICrmFilter } from "@core/Models/tenantConfig.models";
import { MongoQueryParser, NowReference, QueryVars } from "@core/Utils/MongoQueryParser";
import _ from "lodash";

export function validateMongoFilter(filter: ICrmFilter, queryVars: QueryVars): string | null {
    if (filter.id == null || typeof filter.id != "string") {
        return `Incorrect 'id' value: 'id' must be a string`;
    }
    if (filter.caption == null || typeof filter.caption != "string") {
        return `Incorrect 'caption' value: 'caption' must be a string`;
    }
    if (filter.users != null && (!Array.isArray(filter.users) || filter.users.some(x => typeof x != "string"))) {
        return `Incorrect 'users' value: 'users' must be null or an array of strings representing user logins`;
    }
    if (filter.fields != null && (!Array.isArray(filter.fields) || filter.fields.some(x => typeof x != "string"))) {
        return `Incorrect 'fields' value: 'fields' must be null or an array of strings representing field ids`;
    }
    const whereError = validateMongoQuery(filter.where, queryVars);
    if (whereError != null) {
        return whereError;
    }
    if (filter.sortField != null && typeof filter.sortField != "string") {
        return `Incorrect 'sortField' value: 'sortField' must be null or a string representing field id`;
    }
    if (filter.sortDirection != null && !Object.values(CrmSortDirection).includes(filter.sortDirection)) {
        return `Incorrect 'sortDirection' value: 'sortDirection' must be null or one of the following strings:\n'`
            + Object.values(CrmSortDirection).join(`',\n'`) + `'`;
    }
    
    return null;
}

function validateMongoQuery(query: any, queryVars: QueryVars): string | null {
    if (query == null) {
        return `Incorrect 'where' value: 'where' must be specified`;
    }
    try {
        new MongoQueryParser(true, queryVars).find(query, new NowReference());
    }
    catch (e: any) {
        return `Error while compiling 'where' query: ${e.message}`;
    }
    return null;
}
