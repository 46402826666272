import styles from './SignupPage.module.scss';
import {useContext, useEffect, useState} from 'react';
import {authApiSignup} from '@core/Api/auth-api';
import {AccessTokenDispatchContext} from '@core/Contexts/AccessTokenContext';
// import Logger from 'js-logger';
// import {serializeError} from 'serialize-error';
// import {useAppDispatch} from '@core/Redux/hooks';
// import {initApplicationAsync} from '@core/Redux/Slices/appSlice';
import {useTranslation} from "react-i18next";
import Logger from 'js-logger';
import _ from 'lodash';
import { LoadingIcon } from '@core/VisualComponents/Icons/LoadingIcon';

declare var location: any;

function getCookie(name: string): string | null {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export function SignupPage() {
    const { t } = useTranslation();
    //const dispatch = useAppDispatch();
    const setAccessToken = useContext(AccessTokenDispatchContext);
    const [progressStatus, setProgressStatus] = useState('');
    
    const progressStages: {message: string, time:number}[] = [
        {message: t("setting_up_crm_progress1"), time: 5000},
        {message: t("setting_up_crm_progress2"), time: 20000},
        {message: t("setting_up_crm_progress3"), time: 20000},
        {message: t("setting_up_crm_progress4"), time: 20000},
    ];

    let updateProgressStatusDebounce : _.DebouncedFunc<()=>void>;

    const updateProgressStatus = (n: number = 0) =>
    {
        setProgressStatus(progressStages[n].message);

        if (n < progressStages.length-1) {
            updateProgressStatusDebounce = _.debounce(() => updateProgressStatus(n+1), progressStages[n].time);
            updateProgressStatusDebounce();
        }
    }

    useEffect(() => {
        const signup = async () => {
            try
            {
                let search = location.search;
                let params = new URLSearchParams(search);

                let email = params.get('email')!;
                if (!email)
                    email = getCookie("email")!;
                if (!email)
                    Logger.error("email is empty");

                let description = params.get('description')!;
                if (!description)
                    description = getCookie("description")!;
                if (!description)
                    Logger.error("description is empty");
                
                let locale = params.get('locale')!;
                if (!locale)
                    Logger.warn("locale is empty");

                const resp = await authApiSignup({email, description, locale});
                setAccessToken(resp.accessToken);

                location.replace('/'); 
            }
            catch(ex: any)
            {
                Logger.error("signup error", ex);
                location.replace('/');
            }
        };
        
        signup();
        updateProgressStatus();

        return () => {
            updateProgressStatusDebounce.cancel();
        }
    }, []);

    return (
        <div className={styles.host}>
            <div className={styles.loginFormWrapper}>
                <h1 className={styles.title}>{t("setting_up_crm")}</h1>
                <h1 className={styles.title}>{t("setting_up_crm_2")}</h1>
                <LoadingIcon/>
                <h2 className={styles.title}>{progressStatus}</h2>
            </div>
        </div>
    );
}
