import styles from './RegistrationPage.module.scss';
import {useContext, useState} from 'react';
import {PrimaryButtonRounded} from '@core/VisualComponents/Buttons/PrimaryButtonRounded';
import {authApiRegister, authApiSignin} from '@core/Api/auth-api';
import {StatusCode} from '@core/Constants/http-status-codes';
import {AxiosError} from 'axios';
import {AccessTokenDispatchContext} from '@core/Contexts/AccessTokenContext';
import Logger from 'js-logger';
import {serializeError} from 'serialize-error';
import {useAppDispatch} from '@core/Redux/hooks';
import {initApplicationAsync} from '@core/Redux/Slices/appSlice';
import {useTranslation} from "react-i18next";
import { Input } from 'antd';


export function RegistrationPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const setAccessToken = useContext(AccessTokenDispatchContext);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [tenant, setTenant] = useState('');
    const [purchaseCode, setPurchaseCode] = useState('');
    
    
    const isFormValid = () => {
        return login && login !== ''
            && password && password !== ''
    };

    const onRegisterClicked = () => {
        if (!isFormValid) {
            alert(t("login_password_must_not_be_empty_error"));
        }

        const register = async () => {
            const resp = await authApiRegister({tenant, purchaseCode, login, password});
            setAccessToken(resp.accessToken);

            // TODO не получается красиво обновить базу без перезагрузки страницы.
            // Падает OperationEventsPoller c ошибкой DatabaseClosedError
            await dispatch(initApplicationAsync());
            window.location.pathname = '/';
        }

        register().catch((err: AxiosError) => {
            const statusCode = err.response?.status;
            if (statusCode === StatusCode.ClientErrorUnauthorized
                || statusCode == StatusCode.ClientErrorBadRequest) {
                    let message = err.response?.data as string;
                    if (!message)
                        alert("Unknown error while registration");

                    if (message.startsWith("U001:"))
                        message = t("errorU001_tenant_already_exist");
                    else if (message.startsWith("U002:"))
                        message = t("errorU002_user_with_same_login_already_exist");
                    else if (message.startsWith("U003:"))
                        message = t("errorU003_invalid_role");
                    else if (message.startsWith("U004:"))
                        message = t("errorU004_purchase_code_is_invalid");
                    else if (message.startsWith("U005:"))
                        message = t("errorU005_purchase_code_is_already_claimed");
                    alert(message);
                return;
            }

            Logger.error('Registration failed', serializeError(err, { maxDepth: 2}));
        });
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            onRegisterClicked();
        }
    };
    
    return (<div className={styles.host}>
        <div className={styles.loginFormWrapper}>
            <h1 className={styles.title}>{t("pleaseCreateAccount")}</h1>
            <div className={styles.loginForm}>
                <div className={styles.inputFields}>
                    <div className={styles.inputWrapper}>
                        <span>{t("purchaseCode")}</span>
                        <Input
                            className={styles.input}
                            placeholder={t("purchaseCode") ?? ""}
                            type="text"
                            id="purchaseCode"
                            value={purchaseCode}
                            onKeyDown={handleKeyDown}
                            onChange={e => setPurchaseCode(e.target.value)}
                        />
                    </div>
                    <div className={styles.inputWrapper}>
                        <span>{t("tenant")}</span>
                        <Input
                            className={styles.input}
                            placeholder={t("tenant") ?? ""}
                            type="text"
                            id="tenant"
                            value={tenant}
                            onKeyDown={handleKeyDown}
                            onChange={e => setTenant(e.target.value)}
                        />
                    </div>
                    <div className={styles.inputWrapper}>
                        <span>{t("login")}</span>
                        <Input
                            className={styles.input}
                            placeholder={t("login") ?? ""}
                            type="text"
                            id="login"
                            value={login}
                            onKeyDown={handleKeyDown}
                            onChange={e => setLogin(e.target.value)}
                        />
                    </div>
                    <div className={styles.inputWrapper}>
                        <span>{t("password")}</span>
                        <Input.Password
                            className={styles.input}
                            placeholder={t("password") ?? ""}
                            id="password"
                            value={password}
                            onKeyDown={handleKeyDown}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </div>
                </div>
                <PrimaryButtonRounded className={styles.signinButton}
                                      onClick={onRegisterClicked}>{t("toSignUp")}</PrimaryButtonRounded>
            </div>
        </div>
    </div>);
}