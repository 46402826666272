import { useMemo, useRef, useState } from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import styles from './MobileAppLayout.module.scss';
import {BeforeMobileMainMenuRefContext} from '@core/Contexts/BeforeMobileMainMenuRefContext';
import {ReactComponent as RobotSvg} from '@assets/Icons/robot-icon.svg';
import {ReactComponent as TableSvg} from '@assets/Icons/table-icon.svg';
import {ReactComponent as AvatarSvg} from '@assets/Icons/profile-boy-icon.svg';
import {ReactComponent as ChartPieSvg} from '@assets/Icons/chart-pie-icon.svg';
import {ReactComponent as ArrowsLeftRightSvg} from '@assets/Icons/arrows-left-right-icon.svg';
import { addIntegrationPath, addReportPath as addReportPath, aiChatPath, tablePath, profilePath } from '@core/Constants/route-paths';
import { MobileMenuButton } from '../Shared/MobileMenuButton/MobileMenuButton';
import { useNavigate  } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@core/Redux/hooks';
import { selectCurrentTable, selectTableConfig, selectTenantConfig } from '@core/Redux/store';
import { getDefaultTableId } from 'src/App/App';
import Logger from 'js-logger';

enum SelectedButton {
    Entities,
    Aichat,
    Reports,
    Integrations,
    Profile
}

export function MobileAppLayout() {
    const beforeMobileMainMenuPanelRef = useRef(null);
    const { t } = useTranslation();
    const location = useLocation(); 
    let navigate = useNavigate ();
    const tenantConfig = useAppSelector(selectTenantConfig);
    const tableConfig = useAppSelector(selectCurrentTable) ?? tenantConfig?.tables[0];
    const tableName = tableConfig?.tableName ?? '';
    
    const selectedButton = useMemo(() => {
        const isEntityListPath = tenantConfig?.tables.some(table => location.pathname.startsWith(tablePath(table.tableId)));
        if (isEntityListPath) {
            return SelectedButton.Entities;
        } else if (location.pathname.startsWith(aiChatPath)) {
            return SelectedButton.Aichat;
        } else if (location.pathname.startsWith(addReportPath)) {
            return SelectedButton.Reports;
        } else if (location.pathname.startsWith(addIntegrationPath)) {
            return SelectedButton.Integrations;
        } else if (location.pathname.startsWith(profilePath)) {
            return SelectedButton.Profile;
        }
    }, [location.pathname]);

    const onEntitiesClicked = () => {
        if (tableConfig?.tableId == null) {
            Logger.error("[MobileAppLayout] no default table");
            return;
        }
        navigate(tablePath(tableConfig.tableId));
    }
    const onAIChatClicked = () => {
        navigate(aiChatPath);
    }
    const onProfileClicked = () => {
        navigate(profilePath);
    }
    const onAddReportClicked = () => {
        navigate(addReportPath);
    }
    const onAddIntegrationClicked = () => {
        navigate(addIntegrationPath);
    }
    return (<BeforeMobileMainMenuRefContext.Provider value={beforeMobileMainMenuPanelRef}>
        <div className={styles.host}>
            <div className={styles.mainContent}>
                <Outlet/>
            </div>
            <div className={styles.mobileBottomPanel}>
                <div ref={beforeMobileMainMenuPanelRef} className={styles.mobileButtomOutsidePannel}>
                </div>

                <div className={styles.mobileMainMenu}>

                    <MobileMenuButton
                        onClick = {onEntitiesClicked}
                        svg={<TableSvg style={{padding:"5px"}}/>}
                        caption={tableName}
                        selected={selectedButton == SelectedButton.Entities}
                    />

                    <MobileMenuButton
                        onClick = {onAIChatClicked}
                        svg={<RobotSvg style={{padding:"5px"}}/>}
                        caption={t("ai-assistant-button")}
                        selected={selectedButton == SelectedButton.Aichat}
                    />
                    
                    <MobileMenuButton
                        onClick = {onAddReportClicked}
                        svg={<ChartPieSvg style={{padding:"5px"}}/>}
                        caption={t("add-report")}
                        selected={selectedButton == SelectedButton.Reports}
                    />
                    
                    <MobileMenuButton
                        onClick = {onAddIntegrationClicked}
                        svg={<ArrowsLeftRightSvg style={{padding:"5px"}}/>}
                        caption={t("add-integration")}
                        selected={selectedButton == SelectedButton.Integrations}
                    />
                    
                    <MobileMenuButton
                        onClick = {onProfileClicked}
                        svg={<AvatarSvg/>}
                        caption={t("profile")}
                        selected={selectedButton == SelectedButton.Profile}
                    />
                </div>
            </div>
        </div>
    </BeforeMobileMainMenuRefContext.Provider>);
}
