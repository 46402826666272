import { tablePath } from '@core/Constants/route-paths';
import { useAppSelector } from '@core/Redux/hooks';
import { selectTenantConfig } from '@core/Redux/store';
import { useNavigate } from 'react-router-dom';
import styles from "./TableSelector.module.scss";
import { Select } from 'antd';

export function TableSelector(props: {currentTableId?: string}) {
    const navigateTo = useNavigate();
    const tenantConfig = useAppSelector(selectTenantConfig);

    const options = tenantConfig?.tables?.map(x => ({ label: x.tableName, value: x.tableId }));

    const onClick = (tableId: string) => {
        navigateTo(tablePath(tableId));
    }

    if (tenantConfig?.tables?.length == 1) {
        return <span>{tenantConfig.tables[0].tableName}</span>;
    }

    const selected = tenantConfig?.tables?.find(x => x.tableId == props.currentTableId);

    return <Select
        value={selected?.tableId}
        options={options}
        onChange={onClick}
        variant="borderless"
        className={styles.select}
        dropdownStyle={{
            width: "unset",
        }}
    />;
}