import { useCallback, useMemo } from "react";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import styles from './Breadcrumbs.module.scss';

export interface IBreadcrumbs {
    caption: string;
    url: string;
}

interface IBreadcrubsProps {
    breadcrumbs: IBreadcrumbs[];
}

export function breadcrubsBack(breadcrumbs: IBreadcrumbs[]) {
    if (breadcrumbs.length < 2)
        return {url: "/", caption: "" };

    return breadcrumbs[breadcrumbs.length - 2];
}

export function goBack(breadcrumbs: IBreadcrumbs[], navigate: NavigateFunction) {
    const back = breadcrubsBack(breadcrumbs);
    navigate(back.url);
}

export function Breadcrubs(props: IBreadcrubsProps) {
    const navigate = useNavigate();

    const breadcrumbsView = useMemo(() => {
        if (props.breadcrumbs.length <= 0)
            return [];

        let result = [];

        let key: number = 0;
        for (let e of props.breadcrumbs.slice(0, -1)) {
            result.push(<Link key={key++} to={e.url}>{e.caption}</Link>);
            result.push(<span key={key++}>&nbsp;/&nbsp;</span>);
        }

        const last = props.breadcrumbs[props.breadcrumbs.length-1];
        result.push(<span key={key++}>{last.caption}</span>);

        return result;
    }, [props.breadcrumbs]);

    const showBackButton = props.breadcrumbs.length > 1;

    const onBackClicked = useCallback(()=>{
        const back = breadcrubsBack(props.breadcrumbs);
        navigate(back.url);
    }, [props.breadcrumbs]);

    if (props.breadcrumbs.length <= 0)
        return <></>;

    return (
        <div className={styles.breadcrumbs}>
            <div className={styles.back}>
                {showBackButton &&
                    <i onClick={onBackClicked} className={"fa-solid fa-arrow-left " + styles.back}></i>
                }
            </div>
            <div className={styles.urlList}>{breadcrumbsView}</div>
        </div>
    );
}