import _ from 'lodash';

import { BaseTool, IApiForAIAgent, LangChainAgent } from "./ai-api";
import { createApi } from '@core/Redux/Slices/appSlice';
import { ICrmApi } from '@pluginShared/i-crm-api';
import { ListFieldsTool } from './fieldsManager';
import { IGeneratedModuleInfo } from '@core/Models/autogenerated/plugins.models';
import { AddOrUpdatePluginTool, AskUserTool, PluginCreatorTool, SelectExternalEventsTool, SelectEntityEventsTool, SelectQueryTool } from './commonPluginTools';
import { getPluginPromptParts } from './pluginAiManager';
import { ITenantConfig } from '@core/Models/autogenerated/tenantConfig.models';


const getCreateReportPrompt = async (api: IApiForAIAgent, options?: any) => {
    const promptParts = await getPluginPromptParts(api);

    let isUpdate = !!options?.isUpdate;

    if (isUpdate && !options?.moduleInfo) {
        // If options is incorrect
        isUpdate = false;
    }

    return `
You are AI code plugin generator. Your goal is to ${isUpdate ? "edit" : "develop"} plugin for CRM based on user query.

${isUpdate
    ? ``
    : `Development consist of several steps:
1. Planing where you must plan overall structure and requirement for plugin. If needed ask user for some questions
2. Write plugin javascript code`}

Plugin is a object with following structure: 
interface IPlugin {
    routes: IRoute[]; //routes used when separate page needed. It visible as menu item and used for display user requested data (reports for example must use this)
    components: ComponentImpl<any>[]; //reserved for future usage. must be []
    decorators: IReactComponentDecorator<any>[]; //reserved for future usage. must be []
}

IRoute integrates in CRM frontend and contains custom jsx code for its purpose.

Plugin code must be provided in the following form:
return {
    routes: [{
        path: <Relative url of plugin page. Example:  "/my-plugin">,
        menuCaption: <User see this as menu caption. Use user language>,
        groupCaption: <Menu group name. Use simple names like Reports or something else. Use user language>

        //return JSX.Element
        func: () => {
            //Plugin code here. This function must return React component representing requested data

            //example step 1: get data using api

            //exmaple step 2: postprocess data

            return (
                {/* Here you can put your React code to render the report */}
                <p>My plugin</p>
            );
        }
    }],
    components: [], //reserved for future use
    decorators: [], //reserved for future use
}

Important considerations:
- This route function run in sandboxed environment and don't have access to third party libraries.
- ONLY DEFAULT BROWSER JS LIBRARIES ARE AVAILABLE. NO OTHER LIBRARIES (e.g. DateTime) ARE INSTALLED OR ABLE TO BE IMPORTED
- func can NOT be async function! It's used as React functional component.
- Plugin must be written using javascript code
- Data type issues must be addresed by examining the data. For example some numbers may come as strings and so on. Include cast of data in plan if neccessary.
- Grouping in select is not supported. The overall recommendation is to perform grouping/aggregation on the client side.
- Strictly follow hook restrictions for custom react hooks


several globals constants are defined and can be used:
- React: This is a global variable in React
- api, a global object that contains:
    - configApi
        //custom react hook. forbidden to be called inside useEffect
        //before every usage write comment that guarante that this api not called inside useEffect
        - function useTableConfig(tableId: string): ITableConfig; //must be called at the top level of a component, not inside useEffect or any other hook. Provide config for specific table. This is not a data-fetching api.
        
    - entityApi, an object that contains:
        - function select(query), returns Promise<Array>.  //do not forget to catch exceptions
            query is 
            {   
                "select": array of fields with aggregation if needs. Example ["name", "date"]. 'as' operator not supported.
                "where": array of conditions for data filtration. Structure of each condition is:
                    {
                        "field": Field id (string)
                        "operator": gte, lt, eq, notEq, isNotNull, isNull, like
                        "valueType": field type, one of: "Date", "String". For numbers and booleans "String" must be used with string value representation in value.
                        "value": value in condition
                    }
                "limit" : sql like limit, optional 
                "offset" : sql like offset, optional
                "orderBy": optional, you can skip it in case the user does not request sorting. Array of:
                    {
                        "field": Field id (string). Aggregation functions are prohibited
                        "direction": Asc, Desc
                    }
            }
            
    - externalEventApi
        - async function* getEventsByFilterStream(req: { filter: any, fromDate: Date, toDate: Date }): AsyncGenerator<any, void, unknown>
            This function returns a stream of events. This stream must be processed using the for await operator. The event structure is described below.
    - operationApi
        //query update entity events for specific range of time (from/to in unix time format)
        //can be used for example in reports counting entity field changes
        //also for track entity change over time
        //This function returns a stream of entities events. This stream must be processed using the for await operator.
        //Avoid storing of full response: events count can be quite large. For reporting use aggregation over stream
        - async function* queryEventsStream(tableId?: string, from?: number, to?: number): AsyncGenerator<ICrmOperationEventDecoded>
    - userApi
        - function userApiGetTenantUsers(Object), returns Promise<Array>, can be used to get all users registered in system
        - function userApiGetUserInfo(Object), returns Promise<Object>, can be used to get current user
    - coreUiApi
        - components // Contains components which can be used

Event structure: {
    id: number;
    createdAt: string;
    createdBy: string;
    creationContext: object;
    tenant: string;
    data: object;
    metaData: object;
}

Components to use: ${promptParts.componentsForUse}

Types descriptions: ${promptParts.commonNotes}

Example of using components:
    const StringInput = api.coreUiApi.components.StringInput;
    return <StringInput/>;

Following fields can be queried:
    ${await new ListFieldsTool(api).run("")}

Date time now is: ${new Date().toUTCString()}

IMPORTANT:
* The date in the system is stored as a number of seconds since the epoch (meaning since 1 January 1970 00:00:00 UTC). Display it like {value.date ? new Date(value.date * 1000).toLocaleDateString() : ""}. 
* If you need to get the current date to compare it, use the following commands: let currentDate = new Date(); currentDate.setHours(0, 0, 0, 0); currentDate = Math.floor(currentDate.getTime() / 1000);
* Parameter types must match the configuration of the corresponding columns.
* To display the value of a column with the combobox type in the table, you need to display the label instead of the value.

${isUpdate
    ? `Your task is to update the plugin with name "${options.moduleInfo.name}".
    Plugin description: "${options.moduleInfo.description}".
    Current plugin code:
    ${options.moduleInfo.code}`
    : `For now you only need to follow first step: plan you task.
- Does plugin require some data? What is structure of that data?
- Does current CRM data sturcture enough to solve task? Use provide tools to check it.

You current task:
- At current step you can use tools for gather additional data.
- As a result write plan with plain english as a base for next step.
- Plan must not contains assumptions. All assumptions must be resolved with tools.
- Next step started when explicitly asked by administrator.
- At the end of this step, analyze the plan, highlight what is directly confirmed by facts from the current dialogue, and what is an assumption. Adjust the plan by removing all assumptions. The final version should contain only factually confirmed conclusions.
- After plan ready, do not switch to next step you must answer 'ready for next step'.`}
`
};

export class CreateReportTool extends PluginCreatorTool {

    public static name_: string = "create_report";
    name = CreateReportTool.name_;
    //access_token: string;
    crmApi: ICrmApi;
    orderConfig: ITenantConfig;
    //llm: ChatOpenAI;
    toolkit: BaseTool[];
    //history: ChatMessageHistory;
    description: string = `
Tool used for add plugin with report functionality. Must be used for creating new report plugins, can't modify existing plugins. For further modifications you must use modification tool.
IMPORTANT: this tool can't modify reports, only create new ones.
This tool have memory and remember past conversations in current session.
Input is text with human description of report.
Output is result of adding report.
`;

    constructor(api: IApiForAIAgent, access_token: string) {
        super(api, access_token, 'gpt-4o');
        //super(api, access_token, 'gpt-4-turbo', true);

        let orderConfig = api.getOrderConfig();
        if (!orderConfig)
            throw Error("system not properly initialized");
        this.orderConfig = orderConfig;
        this.crmApi = createApi(api.getUserInfo()!);

        this.toolkit = [
            new SelectQueryTool(api, this.crmApi),
            new SelectEntityEventsTool(api, this.crmApi),
            new SelectExternalEventsTool(api, this.crmApi),
            new AskUserTool(api, this.crmApi),
            new AddOrUpdatePluginTool(api, this.crmApi, this),
        ];
    }

    getPrompt(options?: any): Promise<string> {
        return getCreateReportPrompt(this.api, options);
    }

    async create(query: string, agent: LangChainAgent): Promise<string> {
        //response stored in history. ignore it
        const response_plan = await agent.invoke(
            { input: query }
        );

        this.currentModule = null;

        let response = await agent.invoke(
            { input: "Let's move further to the step 2. Write plugin javascript code." }
        );

        return response.output;

    }

    async update(query: string, agent: LangChainAgent, moduleInfo: IGeneratedModuleInfo): Promise<string> {
        console.log(`*${this.name}/update ${query}`);

        let response = await agent.invoke(
            { input: query }
        );

        return response.output;
    }
}

