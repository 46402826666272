import * as React from 'react';
import cs from 'clsx';
import styles from './Body.module.scss';

// /** @jsxRuntime classic */
// /** @jsx jsx */
// import { css, jsx } from '@emotion/react';
// // jsx;

import { ThemeContext } from '@table-library/react-table-library/common/context/Theme';
import { LayoutContext } from '@table-library/react-table-library/common/context';

import { BodyProps } from '@table-library/react-table-library/types/table';

export const Body: React.FC<BodyProps> = ({ children, ...rest }: BodyProps) => {
  const theme = React.useContext(ThemeContext);

  const context = React.useContext(LayoutContext);

  if (!context) {
    throw new Error('No Layout Context.');
  }

  const { layout } = context;

  const As = layout?.isDiv ? 'div' : 'tbody';

  return (
    <As
      // css={css`
      //   ${theme?.Body}

      //   display: contents;
      // `}
      data-table-library_body=""
      className={cs("tbody", styles.body)}
      {...rest}
    >
      {children}
    </As>
  );
};
