import axios, {AxiosError, AxiosRequestConfig} from 'axios';
import {IInterceptorIdPair} from '../setup-interceptors';
import {isRetryAllowed} from './is-retry-allowed';

const configNamespace = 'dumbcrm-retry'

export interface IRetryInterceptorOptions {
    /**
     * 3 by default
     */
    retries: number;
    /**
     * isNetworkOrIdempotentRequestError is a default option
     */
    retryCondition?: (error: AxiosError) => boolean;

    /**
     * exponentialDelay is a default option
     */
    retryDelay?: (retryCount: number) => number;
}

interface ICurrentState {
    timeout: number;
    retryCount: number;
    lastRequestTime: number | undefined;
}

interface IRequestConfig {
    options: IRetryInterceptorOptions;
    currentState: ICurrentState;
}

export function isNetworkError(error: AxiosError): boolean {
    return (
        !error.response &&
        Boolean(error.code) && // Prevents retrying cancelled requests
        error.code !== 'ECONNABORTED' && // Prevents retrying timed out requests
        isRetryAllowed(error)
    ); // Prevents retrying unsafe errors
}

const SAFE_HTTP_METHODS = ['get', 'head', 'options'];
const IDEMPOTENT_HTTP_METHODS = SAFE_HTTP_METHODS.concat(['put', 'delete']);

export function isRetryableError(error: AxiosError): boolean  {
    return (
        error.code !== 'ECONNABORTED' &&
        (!error.response || (error.response.status >= 500 && error.response.status <= 599))
    );
}

export function isIdempotentRequestError(error: AxiosError): boolean {
    if (!error.config || !error.config.method) {
        return false;
    }

    return isRetryableError(error) && IDEMPOTENT_HTTP_METHODS.indexOf(error.config.method) !== -1;
}

export function isNetworkOrIdempotentRequestError(error: AxiosError): boolean {
    return isNetworkError(error) || isIdempotentRequestError(error);
}

function getRequestOptions(config: AxiosRequestConfig, defaultOptions:IRetryInterceptorOptions): IRetryInterceptorOptions {
    const reqConfig = (config as any)[configNamespace] as IRequestConfig;
    return { ...defaultOptions, ...reqConfig.options };
}

function getCurrentState(config: AxiosRequestConfig): ICurrentState{
    const reqConfig: IRequestConfig = (config as any)[configNamespace] || { currentState: {} };
    const currentState = reqConfig.currentState;
    currentState.retryCount = currentState.retryCount || 0;
    (config as any)[configNamespace] = reqConfig;
    return currentState;
}

function shouldRetry(retries: number, retryCondition: (error: AxiosError) => boolean, currentState: ICurrentState, error: AxiosError): boolean {
    return currentState.retryCount < retries && retryCondition(error);
}

export function exponentialDelay(retryNumber = 0) {
    const delay = Math.pow(2, retryNumber) * 100;
    const randomSum = delay * 0.2 * Math.random(); // 0-20% of the delay
    return delay + randomSum;
}

function addRetryRequestInterceptor(props: IRetryInterceptorOptions): number {
    return axios.interceptors.request.use(function (config) {
        const currentState = getCurrentState(config);
        currentState.lastRequestTime = Date.now();
        return config;
    });
}

function addRetryResponseInterceptor(defaultOptions: IRetryInterceptorOptions): number {
    return axios.interceptors.response.use(null, function (error: AxiosError) {
        const { config } = error;
        if (!config) {
            return Promise.reject(error);
        }

        const {
            retries = 3,
            retryCondition = isNetworkOrIdempotentRequestError,
            retryDelay = exponentialDelay
        } = getRequestOptions(config, defaultOptions);

        const currentState = getCurrentState(config);
        if (shouldRetry(retries, retryCondition, currentState, error)) {
            currentState.retryCount += 1;
            const delay = retryDelay(currentState.retryCount);
            return new Promise((resolve) => setTimeout(() => resolve(axios(config)), delay));
        }

        return Promise.reject(error);
    });
}

export function addRetryInterceptor(options: IRetryInterceptorOptions): IInterceptorIdPair {
    return {
        requestInterceptorId: addRetryRequestInterceptor(options),
        responseInterceptorId: addRetryResponseInterceptor(options)
    };
}
