import { ICrmValueOption, ICrmField, CrmFieldViewType } from "@core/Models/autogenerated/tenantConfig.models";

export class InputValidator {
    static validateDecimal(value: any): boolean {
        if (typeof value == "string") {
            value = value.replace(",", ".");
        }
        return value == null || value === '' || (!isNaN(value) && (typeof value == "string" || typeof value == "number"));
    }

    static validateUrl(value: any): boolean {
        return value == null || typeof value == "string" && (value == "" || !!value.match(/^((http|https|ftp|file):\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|localhost)(:[0-9]{1,5})?([/\?]\S*)?$/ig));
    }

    static validateTime(value: any): boolean {
        return value == null || typeof value == "string" && (value == "" || !!value.match(/^(\d{1,2}):(\d{2})$/));
    }

    static validateCombobox(value: any, options?: ICrmValueOption[]) {
        return (typeof value == "string" || typeof value == "number") && options != null && options.find(x => x.value == value) != null;
    }

    static validateComment(value: any) {
        return typeof value == "object" && (value.author == null || typeof value.author == "string") && typeof value.date == "number" && typeof value.message == "string";
    }

    static validateBoolean(value: any) {
        return value == null || typeof value == "boolean";
    }

    static validateTags(value: any, options?: ICrmValueOption[]) {
        return (Array.isArray(value) && value.every(item => typeof item == "string" && options?.find(x => x.value == item) != null));
    }

    static validateByRegExp(value: any, regExp?: string) {
        if (value == null) {
            return true;
        }

        if (regExp) {
            if (typeof value != "string") {
                return false;
            }
            return !!value.match(regExp);
        }

        return true;
    }

    static validateByField(value: any, field: ICrmField) {
        if (value == null) {
            return true;
        }

        if (field.regExp && this.validateByRegExp(value, field.regExp)) {
            return true;
        }

        switch (field.viewType) {
            case CrmFieldViewType.Date:
            case CrmFieldViewType.DateTime:
            case CrmFieldViewType.Decimal:
                return this.validateDecimal(value);
            case CrmFieldViewType.Url:
                return this.validateUrl(value);
            case CrmFieldViewType.Time:
                return this.validateTime(value);
            case CrmFieldViewType.Combobox:
                return this.validateCombobox(value, field.options);
            case CrmFieldViewType.YesNo:
                return this.validateBoolean(value);
            case CrmFieldViewType.Tags:
                return this.validateTags(value, field.options);
        }

        return true;
    }

    static isEqual(v1: any, v2: any) {
        if (v1 === "") {
            v1 = null;
        }
    
        if (v2 === "") {
            v2 = null;
        }

        if (v1 == null && v2 == null) {
            return true;
        }
    
        return v1 === v2;
    }
}