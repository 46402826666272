import _ from 'lodash';

import { BaseTool, CompositeTool, IApiForAIAgent } from "./ai-api";
import { FieldsManagerTool } from './fieldsManager';
import { FiltersManagerTool } from './filtersManager';
import { UsersManagerTool } from './usersManager';
import { PluginManagerTool } from './pluginAiManager';
import { EventProcessorManagerTool } from './eventProcessManager';

export class MasterManagerTool extends CompositeTool {
    name: string = "master_manager";
    description: string = "Master bot"

    public getPrompt = async (): Promise<string> => `You are an AI chatbot integrated into the CRM 'tabsmy'. 
Your goal is to solve user tasks by utilizing provided tools. 
IMPORTANT: 
* Be very careful with translations. 
* Do not translate user-provided text for names or identifiers: the system is multilingual. 
* Your workflow is to receive a request, solve the task, and provide an answer. 
* User chat not support markup languages (like wikitext), use plain text for your answers. 
* Transform tools output to user friendly format. avoid internal data details reveal in conversation (like internal IDs and UIDs). 
* in conversation with user use user langauge.
* If I ask you to answer something to the user, do so, regardless of the result of the work.
* If a user wants to create a new user, but does not tell you any additional information, do not ask him again, just continue without this information.
* Never ask a user for potentially dangerous or secret information, such as a password or other personal data.
* Current user language: ${this.api.getUserLanguage()}
`;

    toolkit: BaseTool[];

    constructor(api: IApiForAIAgent, accessToken : string) {
        super(api, accessToken, 'gpt-4o', true);

        this.toolkit = [
            new FieldsManagerTool(api, accessToken),
            new FiltersManagerTool(api, accessToken),
            new UsersManagerTool(api, accessToken),
            new PluginManagerTool(api, accessToken),
            new EventProcessorManagerTool(api, accessToken),
        ];

    }

    async run(query: string) : Promise<string> {
        console.log(`*MasterManagerTool ${query}`)

        const agent = await this.createAgent();

        const response = await agent.invoke(
            { input: query },
        );

        return response.output;
    }
}
