import styles from "./AddIntegration.module.scss"
import { useAppSelector } from "@core/Redux/hooks";
import { selectDefaultTenantId } from '@core/Redux/store';
import { useTranslation } from 'react-i18next'; 

export function AddIntegration() {
    const { i18n } = useTranslation(); 
    const selectedTenant = useAppSelector(selectDefaultTenantId);

    // Определяем базовые URL для разных языков
    const baseUrlEn = "https://tabsmy.com/select-app/";
    const baseUrlRu = "https://tabsmy.com/select-app-ru";

    // Выбираем URL в зависимости от текущей локали
    const currentLang = i18n.language;
    const baseUrl = currentLang === 'ru' ? baseUrlRu : baseUrlEn;

    const urlWithParameter = `${baseUrl}?tenantId=${selectedTenant}`;

    return (
        <div className={styles.host}>
            <iframe
                src={urlWithParameter}
                style={{width: '100%', height: '100%'}}
            />
        </div>
    );
}
