import { selectUserInfo } from '@core/Redux/store';
import { useAppSelector } from "@core/Redux/hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from './UserProfileWindow.module.scss';
import { maintancePage, signinPagePath } from "@core/Constants/route-paths";
import { PrimaryButton } from "@core/VisualComponents/Buttons/PrimaryButton";

export function UserProfileWindow(props: any) {
    const { t } = useTranslation();
    const navigateTo = useNavigate();
    const userInfo = useAppSelector(selectUserInfo);

    const onChangeUserClicked = (event: React.MouseEvent) => {
        event.stopPropagation();
        navigateTo(signinPagePath);
    };

    const gotoMaintance = (event: React.MouseEvent) => {
        window.location.href = maintancePage;
    };

    return (
        <div className={styles.profileWindow}>
            <div className={styles.caption}>{t("userInfo")}</div>
            <div className={styles.userInfoRows}>
                <div className={styles.userInfoRow}>
                    <div className={styles.userInfoRowCaption}>{t("login")}</div>
                    <div className={styles.userInfoRowValue}>{userInfo?.login}</div>
                </div>
                <div className={styles.userInfoRow}>
                    <div className={styles.userInfoRowValue}>{userInfo?.firstName} {userInfo?.middleName} {userInfo?.lastName}</div>
                </div>
                <div className={styles.userInfoRow}>
                    <div className={styles.userInfoRowCaption}>{t("company")}</div>
                    <div className={styles.userInfoRowValue}>{userInfo?.tenant}</div>
                </div>
                <div className={styles.userInfoRow}>
                    <div className={styles.userInfoRowCaption}>{t("role")}</div>
                    <div className={styles.userInfoRowValue}>{t(userInfo?.role ?? "")}</div>
                </div>
            </div>
            <div className={styles.buttonsPanel}>
                <PrimaryButton style={{ backgroundColor: "gray" }} title="maintance" onClick={gotoMaintance}>
                    <i className="fa fa-wrench"></i>
                </PrimaryButton>
                <div className={styles.spring}></div>
                <PrimaryButton title="LogOut" onClick={e => onChangeUserClicked(e)}>
                    {t("logOut")}
                </PrimaryButton>
            </div>
        </div>);
}