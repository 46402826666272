import { registerComponent } from "@core/Plugins/pluginManager";
import { CommentsDateTime } from "@core/VisualComponents/Comments/CommentsDateTime";
import { CrmText } from "@core/VisualComponents/CrmText";
import { UserProfileIcon } from "@core/VisualComponents/Icons/UserProfileIcon/UserProfileIcon";
import { coreUiComponentDescriptions } from "@pluginShared/core-ui-api";
import { ReactNode } from "react";

export interface ICommentViewerProps {
    author?: string;
    date?: number;
    text: ReactNode;
}

export const CommentViewer = registerComponent(coreUiComponentDescriptions.CommentViewer, _CommentViewer);

function _CommentViewer(props: ICommentViewerProps) {
    return <div>
        <UserProfileIcon name={props.author}/>
        &nbsp;
        {props.date && 
            <><CommentsDateTime date={props.date}/>&nbsp;</>
        }
        {typeof props.text == "string"
            ? <CrmText text={props.text}/>
            : props.text
        }
    </div>;
}