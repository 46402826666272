import axios, {AxiosError, AxiosHeaders} from 'axios';
import {signinPagePath} from '@core/Constants/route-paths';
import {accessTokenKey} from '@core/Constants/app-storage-keys';
import {StatusCode} from '@core/Constants/http-status-codes';
import _ from 'lodash';
import {getValueFromLocalStorage} from '@core/Hooks/use-local-storage';
import {IInterceptorIdPair, IInterceptorIds} from './setup-interceptors';
import Logger from 'js-logger';

export interface IAuthInterceptorOptions {
    excludedPaths: string[];
    location: Location;
}

function addAuthRequestInterceptor(options: IAuthInterceptorOptions): number {
    return axios.interceptors.request.use(function (config) {
        const accessToken = getValueFromLocalStorage<string>(accessTokenKey);
        const headers = config.headers as AxiosHeaders;

        if (options.excludedPaths.some(path => config.url && _.startsWith(config.url.toLowerCase(), path.toLowerCase()))) {
            return config;
        }

        if (accessToken) {
            headers.set('Authorization', `Bearer ${accessToken}`);
            return config;
        }

        return {
            ...config,
            signal: AbortSignal.abort()
        };
    }, function (error) {
        return Promise.reject(error);
    });
}

function addAuthResponseInterceptor(options: IAuthInterceptorOptions): number {
    return axios.interceptors.response.use(function (config) {
        return config;
    }, function (error: AxiosError) {
        Logger.error(error)
        const status = error.response?.status;

        if(options.location.pathname === signinPagePath)
            return Promise.reject(error);

        if(error.code === 'ERR_CANCELED') {
            const accessToken = getValueFromLocalStorage<string>(accessTokenKey);
            if(!accessToken)
                options.location.pathname = signinPagePath;
        }

        if (status === StatusCode.ClientErrorUnauthorized) {
            options.location.pathname = signinPagePath;
        }
        return Promise.reject(error);
    });
}

export function addAuthInterceptor(options: IAuthInterceptorOptions): IInterceptorIdPair {
    return {
        requestInterceptorId: addAuthRequestInterceptor(options),
        responseInterceptorId: addAuthResponseInterceptor(options)
    };
}
