import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import _ from 'lodash';
import Logger from 'js-logger';

export type LocalStorageDispatch<A> = (value: A) => void;
export type LocalStorageSetStateAction<S> = S | ((prevState: S) => S);

export interface IUseLocalStorageOptions<TValue>{
    initialValue?: TValue;
    watchChanges?: boolean;
}

/**
 * Using this method keep in mind it works like useState method.
 * A hook declared in one component doesn't distribute changes on set value to a hook in the another component.
 */
export function useLocalStorage<TValue>(key: string, options?: IUseLocalStorageOptions<TValue>)
    : [TValue, LocalStorageDispatch<LocalStorageSetStateAction<TValue>>] {
    const parseValue = (val: string) => {
        try{
            return JSON.parse(val);
        }
        catch (e) {
            Logger.error('Unhandled exception during JSON deserialization', e);
            return null as TValue;
        }
    }

    const [storedValue, setStoredValue] = useState<TValue>(() => {
        if (typeof window === "undefined") {
            return options?.initialValue as TValue;
        }

        const val = localStorage.getItem(key);

        if(val === null)
            return options?.initialValue as TValue;

        return parseValue(val);
    });

    useEffect(()=>{
        if(options?.watchChanges !== true)
            return;

        const listener = (e: StorageEvent) => {
            if(e.key !== key)
                return;

            const parsedVal = e.newValue !== null
                ? parseValue(e.newValue!)
                : null;

            setStoredValue(parsedVal);
        }
        window.addEventListener('storage', listener);

        return ()=>{
          window.removeEventListener('storage', listener);
        };
    }, [setStoredValue]);

    const setValue = (value: LocalStorageSetStateAction<TValue>) => {
        let valueToStore:TValue;

        if(value instanceof Function ){
            const func = (value as ((prevState: TValue) => TValue));
            valueToStore = func(storedValue);
        }else{
            valueToStore = value;
        }

        setStoredValue(valueToStore);
        if (typeof window !== "undefined") {
            if (valueToStore === null)
                window.localStorage.removeItem(key);
            else
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
        }
    };

    return [storedValue, setValue];
}

export function getValueFromLocalStorage<TValue>(key: string): TValue;

export function getValueFromLocalStorage<TValue>(key: string): TValue|null {
    if (typeof window === "undefined") {
        return null;
    }

    const val = localStorage.getItem(key);

    if(val === null)
        return null;

    try{
        return JSON.parse(val);
    }
    catch (e) {
        Logger.error('Unhandled exception during JSON deserialization', e);
        return null as TValue;
    }
}

export function setValueToLocalStorage<TValue>(key: string, valueToStore: TValue){
    if (typeof window !== "undefined") {
        if (valueToStore === null)
            window.localStorage.removeItem(key);
        else
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
    }
}
