import { DateTime } from 'luxon';
import styles from './FormattedDate.module.scss';
import _ from 'lodash';
import { CSSProperties } from 'react';
import { getDateStringFromSeconds } from '@core/Helpers/js-date-transformations';
import { registerComponent } from '@core/Plugins/pluginManager';
import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';

export interface IFormattedDateProps {
    value: any;
    style?: CSSProperties;
    withTime?: boolean;
}

export const FormattedDate = registerComponent(coreUiComponentDescriptions.FormattedDate, _FormattedDate);

function _FormattedDate(props: IFormattedDateProps) {
    const dateStr = getDateStringFromSeconds(props.value, props.withTime);
    return (<span className={styles.host} style={props.style}>{dateStr}</span>);
}
