import { registerComponent } from "@core/Plugins/pluginManager";
import styles from "./TagsInput.module.scss";
import { coreUiComponentDescriptions } from "@pluginShared/core-ui-api";
import { ICrmTagOption } from "@core/Models/autogenerated/tenantConfig.models";
import { MutableRefObject, CSSProperties, useState, useEffect, useMemo, useCallback } from "react";
import { Select, Space, Tag } from "antd";
import { t } from "i18next";
import { InvalidSpan } from "../InvalidHint/InvalidHint";
import type { SelectProps } from 'antd';

type TagRender = SelectProps['tagRender'];

export interface ITagsInputProps {
    initialValue?: string[] | null;
    options?: ICrmTagOption[];
    placeholder?: string;
    setIsValid?: (isValid: boolean) => void;
    onChanged?: (value: string[] | null) => void;
    valueRef?: MutableRefObject<string[] | null>;
    className?: string
    style?: CSSProperties;
    readonly?: boolean;
    validation?: (value: any) => boolean;
    autoFocus?: boolean;
    disabled?: boolean;
    disableClear?: boolean;
}

export const TagsInput = registerComponent(coreUiComponentDescriptions.TagsInput, _TagsInput);

function _TagsInput(props: ITagsInputProps) {
    const isValidValue = (value: any) => {
        if (props.validation) {
            return props.validation(value);
        }

        if (value == null)
            return true;

        if (!Array.isArray(value))
            return false;

        if (value.length == 0)
            return true;
        
        return value.every(e => props.options?.find(o => o.value == e.toString()) != null);
    }

    const [isValid, setIsValid] = useState<boolean>(isValidValue(props.initialValue));
    const [inputValue, setInputValue] = useState<string[]>(extractValue(props.initialValue));

    useEffect(() => {
        setIsValid(isValidValue(props.initialValue));
    }, [props.initialValue, props.options]);

    useEffect(() => {
        if (props.setIsValid) {
            props.setIsValid(isValid);
        }
    }, [isValid]);
    
    //antd select does not support abstract values. only string/number. store it as keys
    const options = useMemo(() => {
        return props.options?.map(x => ({label: x.label, value: JSON.stringify(x.value), color: x.color} as ICrmTagOption)) ?? [];
    }, [props.options]);

    const onChange = (e: string[]) => {
        if (e == null) {
            e = [];
        }

        //antd rstore values back from keys
        e = (e as []).map(v => JSON.parse(v));

        setIsValid(isValidValue(e));
        setInputValue(e);
        props.onChanged?.(e);
        if (props.valueRef) {
            props.valueRef.current = e;
        }
    }

    // let initialValue = options.filter(x => inputValue.includes(x.value)).map(x => x.value);

    const initialValue = inputValue.map(x => options.find(opt => opt.value === x)?.value ?? x);

    const tagRender: TagRender = (props) => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault();
            event.stopPropagation();
        };
        const option = options.find(x => x.value === value)
        const color = option?.color;
        return (
            <Tag
                className={option == null ? styles.invalidTag : undefined}
                color={color}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
            >
                {label}
            </Tag>
        );
    };

    return <div onClick={e => e.stopPropagation()}>
        <Select
            mode="multiple"
            options={options}
            defaultValue={initialValue}
            placeholder={isValid ? props.placeholder : t("error")}
            onChange={onChange}
            disabled={props.readonly || props.disabled}
            className={styles.select}
            allowClear={!props.disableClear}
            status={isValid ? undefined : "error"}
            autoFocus={props.autoFocus}
            tagRender={tagRender}
            placement="topLeft"
            optionRender={(option) => (
                <Space>
                    <Tag color={option.data.color}>{option.data.label}</Tag>
                </Space>
            )}
        />
        {isValid ||
            <InvalidSpan/>
        }
    </div>;
}

function extractValue(value: any): string[] {
    if (value == null) {
        return [];
    }

    if (!Array.isArray(value)) {
        return [];
    }

    return value.map(x => JSON.stringify(x));
}