import Overlay from 'react-overlays/Overlay';
import { MutableRefObject, useRef, useState } from 'react';
import styles from './CrmCellDateEditor.module.scss';
import { DateInput } from '@core/VisualComponents/Inputs/DateInput/DateInput';

export interface ICrmCellDateEditorProps {
    targetRef: MutableRefObject<any>;
    value: any;
    valueRef: MutableRefObject<number | null>;
    onSave: () => void;
    onHide: () => void;
    popperConfig?: any;
    withTime?: boolean;
}

export function CrmCellDateEditor(compProps: ICrmCellDateEditorProps) {
    const hostRef = useRef<any>();

    const onChanged = (value: number | null) => {
        compProps.valueRef.current = value;

        if (!compProps.withTime) {
            compProps.onSave();
        }
    }

    const onConfirmed = (value: number | null) => {
        compProps.valueRef.current = value;
        compProps.onSave();
    }

    return (<Overlay
        show
        flip
        placement={'right-start'}
        rootClose
        onHide={compProps.onHide}
        target={compProps.targetRef}
        popperConfig={compProps.popperConfig}
        ref={hostRef}
    >
        {({ show, props, arrowProps, placement }) => 
            <div {...props} className={compProps.withTime ? styles.hostWide : styles.host}>
                <DateInput
                    initialValue={compProps.value}
                    onChanged={onChanged}
                    onConfirmed={onConfirmed}
                    withTime={compProps.withTime}
                    staticHostRef={hostRef}
                />
            </div>
        }
    </Overlay>)
}

