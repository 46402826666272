//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { ITenantSelector } from './tenantConfig.models';

export interface IGetAllRequest
{
	tenant: ITenantSelector;
}
export interface IGetRequest
{
	name: string;
}
export interface IAddModuleRequest
{
	tenant: ITenantSelector;
	module: IGeneratedModuleInfo;
}
export interface IDeleteGeneratedModuleRequest
{
	tenant: ITenantSelector;
	id: string;
}
export interface IModuleInfo
{
	moduleType: ModuleType;
	description: string;
	name: string;
}
export interface IManualModuleInfo extends IModuleInfo
{
	moduleType: ModuleType;
	description: string;
	name: string;
	jsUrl: string;
}
export interface IGeneratedModuleInfo extends IModuleInfo
{
	moduleType: ModuleType;
	id: string;
	createdAt?: string|Date;
	createdBy?: string;
	tenant?: string;
	name: string;
	description: string;
	code: string;
	creatorTool: string;
	history?: string;
}
export enum ModuleType {
	ManualModule = 0,
	GeneratedModule = 1
}
