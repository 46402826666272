import { ICrmTagOption } from "@core/Models/autogenerated/tenantConfig.models";
import styles from "./TagsViewer.module.scss";
import { useMemo } from "react";
import { Tag } from "antd";
import { InvalidHint } from "../Inputs/InvalidHint/InvalidHint";

interface IViewTagOption extends ICrmTagOption {
    incorrect?: boolean;
}

export interface ITagsViewerProps {
    values: string[];
    options?: ICrmTagOption[];
}

export function TagsViewer(props: ITagsViewerProps) {
    const filteredOptions = useMemo(() => {
        return props.values.map(x => {
            const option = props.options?.find(opt => opt.value === x);
            if (!option) {
                return { value: x, label: x, incorrect: true } as IViewTagOption;
            }
            return option as IViewTagOption;
        });
    }, [props.values, props.options]);

    return <div className={styles.host}>
        {filteredOptions.map(opt =>
            <Tag key={opt.value} color={opt.color} className={opt.incorrect ? styles.invalidTag : undefined}>{opt.label}</Tag>
        )}
        {filteredOptions.some(option => option.incorrect) &&
            <InvalidHint inline/>
        }
    </div>;
}