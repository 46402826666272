import { ICrmValueOption } from "@core/Models/autogenerated/tenantConfig.models";
import { Select } from "antd";
import { CSSProperties, ReactNode, useEffect, useState } from "react";

export interface ICreatableSelectProps {
    value?: string | null;
    placeholder?: string;
    options?: string[];
    onChanged?: (value: string | null) => void;
    disabled?: boolean;
    autoFocus?: boolean;
    className?: string;
    style?: CSSProperties;
    suffix?: ReactNode;
    status?: "" | "warning" | "error";
}

export function CreatableSelect(props: ICreatableSelectProps) {
    const getOptions = () => props.options?.map(option => ({ value: option, label: option })) ?? [];

    const [options, setOptions] = useState<ICrmValueOption[]>(getOptions());
    const [searchValue, setSearchValue] = useState<string | null>(null);
    const [value, setValue] = useState<string | null>(props.value ?? null);

    useEffect(() => {
        setOptions(getOptions());
    }, [props.options]);

    const onChange = (value: string | null) => {
        setValue(value);
        props.onChanged?.(value ?? null);
        setSearchValue(null);

        if (value != null && !options.some(x => x.value == value)) {
            setOptions(prev => [...prev, {value: value, label: value}]);
        }
    }

    const onSearch = (value: string) => {
        if (value != null && value.trim() !== "" && !options.some(x => x.value == value)) {
            setSearchValue(value);
        }
        else {
            setSearchValue(null);
        }
    }

    return <div onClick={e => e.stopPropagation()}>
        <Select
            value={value === "" ? null : value}
            options={searchValue == null
                ? options
                : [
                    ...options,
                    { value: searchValue, label: `Create: "${searchValue}"` },
                ]
            }
            onChange={onChange}
            onSearch={onSearch}
            disabled={props.disabled}
            autoFocus={props.autoFocus && !props.disabled}
            placeholder={props.placeholder}
            className={props.className}
            style={props.style}
            showSearch
            allowClear
            suffixIcon={props.suffix}
            status={props.status}
        />
    </div>;
}