import { ICrmArrayElement } from '@core/Models/i-array-element';
import styles from './ArrayValuesViewer.module.scss';
import { registerComponent } from '@core/Plugins/pluginManager';
import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';
import { CSSProperties } from 'react';
import { CrmText } from '@core/VisualComponents/CrmText';
import { InputValidator } from '@core/VisualComponents/Inputs/InputValidator/InputValidator';
import { t } from 'i18next';
import { FormattedDate } from '@core/VisualComponents/FormattedDate/FormattedDate';
import { OrderListViewItemValueError } from '@core/VisualComponents/OrdersListViewItemValue/OrderListViewItemValueError';
import { ICrmField, CrmFieldViewType } from '@core/Models/autogenerated/tenantConfig.models';
import { TagsViewer } from '@core/VisualComponents/Tags/TagsViewer';

export interface IArrayValuesViewerProps {
    values: ICrmArrayElement[];
    field?: ICrmField;
    renderValue?: (v: ICrmArrayElement) => JSX.Element; // TODO: remove this
    style?: CSSProperties;
    reversed?: boolean;
    tableId: string;
}

export const ArrayValuesViewer = registerComponent(coreUiComponentDescriptions.ArrayValuesViewer, _ArrayValuesViewer);

function _ArrayValuesViewer(props: IArrayValuesViewerProps) {
    const values = props.reversed ? extractValues(props.values).slice().reverse() : extractValues(props.values);
    const limitedValues = values?.slice(0, 3) ?? [];
    const hasMore = values?.length > 3;
    return <div className={styles.cell} style={props.style}>
        {limitedValues.map(value =>
            <div key={value.id} className={styles.container}>
                {props.renderValue
                    ? props.renderValue(value)
                    : <ArrayValueRenderer tableId={props.tableId} arrayValue={value} field={props.field}/>
                }
            </div>
        )}
        {hasMore && <div>...</div>}
    </div>
}

export interface IArrayValueRendererProps {
    arrayValue: any;
    field?: ICrmField;
    style?: CSSProperties;
    tableId: string; // Do not delete! Required for plugins!
}

export const ArrayValueRenderer = registerComponent(coreUiComponentDescriptions.ArrayValueRenderer, _ArrayValueRenderer);

function _ArrayValueRenderer(props: IArrayValueRendererProps) {
    const { id, ...rest } = props.arrayValue;
    if (props.field?.fields) {
        const result = [];
        let nonEmptyCount = 0;
        for (const field of props.field.fields) {
            const value = props.arrayValue[field.id];
            if (value == null || value === "") {
                continue;
            }
            nonEmptyCount++;

            switch (field.viewType) {
                case CrmFieldViewType.Combobox:
                    if (InputValidator.validateByField(value, field)) {
                        result.push(field.options!.find(x => x.value == value)?.label);
                    }
                    break;
                case CrmFieldViewType.Date:
                case CrmFieldViewType.DateTime:
                    if (InputValidator.validateByField(value, field)) {
                        result.push(<FormattedDate value={parseFloat(value)} style={{color: "#DE16C8"}} withTime={field.viewType == CrmFieldViewType.DateTime}/>);
                    }
                    break;
                case CrmFieldViewType.Decimal:
                    if (InputValidator.validateByField(value, field)) {
                        result.push(<CrmText text={value as string} length={field.textEllipsis}/>);
                    }
                    break;
                case CrmFieldViewType.MultiString:
                case CrmFieldViewType.String:
                case CrmFieldViewType.Phone:
                case CrmFieldViewType.Unknown:
                    if (typeof value == "string" || typeof value == "number") {
                        result.push(<CrmText text={value.toString()} length={field.textEllipsis}/>);
                    }
                    break;
                case CrmFieldViewType.Url:
                    if (InputValidator.validateByField(value, field)) {
                        result.push(<CrmText text={value as string} length={field.textEllipsis}/>);
                    }
                    break;
                case CrmFieldViewType.Time:
                    if (InputValidator.validateByField(value, field)) {
                        result.push(<CrmText text={value.toString()} length={field.textEllipsis} style={{color: "#DE16C8"}}/>);
                    }
                    break;
                case CrmFieldViewType.YesNo:
                    if (typeof value == "boolean") {
                        result.push((value as boolean) ? t("yes") : t("no"));
                    }
                    break;
                case CrmFieldViewType.Tags:
                    if (InputValidator.validateTags(value, field.options)) {
                        result.push(<TagsViewer values={value} options={field.options}/>)
                    }
                    break;
            }
        }

        if (result.length < nonEmptyCount) {
            return <OrderListViewItemValueError/>
        }

        return <>{result.map((v: any, i) => <span style={props.style} key={i}>{v}{i < result.length - 1 && <>,&nbsp;</>}</span>)}</>;
    }
    else {
        return <>
            {Object.values(rest).map((v: any, i) => <span style={props.style} key={i}>{v.toString()};&nbsp;</span>)}
        </>;
    }
}

const extractValues = (values: any) => {
    if (Array.isArray(values)) {
        return values;
    }
    return [];
}