import _ from 'lodash';
import {DateTime} from 'luxon';

export function getCurrentUtcDateAsUnix(): number {
    return DateTime
        .now()
        .setZone('UTC', { keepLocalTime: true })
        .startOf('day')
        .toUnixInteger();
}

export function getCurrentTimeString(): string {
    return DateTime
        .now()
        .toFormat("HH:mm");
}

export function getCurrentUtcDateWithTimeAsUnix(): number {
    return DateTime
        .now()
        .setZone('UTC', { keepLocalTime: true })
        .startOf('minute')
        .toUnixInteger();
}

export function getDateStringFromSeconds(value: any, withTime: boolean = false): string {
    const parsed = parseFloat(value);

    if (!_.isNumber(parsed) || _.isNaN(parsed)) {
        return value;
    }

    const date = DateTime.fromSeconds(parsed).setZone('UTC');
    const isEndOfDay = date.hour === 23 && date.minute === 59 && date.second === 59;

    if (withTime && !isEndOfDay) {
        return `${date.toLocaleString()} ${date.toFormat('HH:mm')}`;
    }
    return date.toLocaleString();
}

export function isEndOfDay(date: DateTime | Date) {
    if (date instanceof DateTime) {
        return date.hour === 23 && date.minute === 59 && date.second === 59;
    }
    return date.getHours() === 23 && date.getMinutes() === 59 && date.getSeconds() === 59;
}
