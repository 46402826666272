let isIosSafariCache: boolean|undefined = undefined;

export function isIosSafari(): boolean {
    if (isIosSafariCache === undefined) {
        var userAgent = window.navigator.userAgent;
        var iOS = !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i);
        var webkit = !!userAgent.match(/WebKit/i);
        var iOSSafari = iOS && webkit && !userAgent.match(/CriOS/i) && !userAgent.match(/OPiOS/i) && !userAgent.match(/FxiOS/i);
        isIosSafariCache = iOSSafari;
    }
    return isIosSafariCache;
}