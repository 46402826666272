import Overlay from 'react-overlays/Overlay';
import {CSSProperties, MutableRefObject, ReactElement, useRef} from 'react';
import styles from './CrmCellTimeEditor.module.scss';
import DatePicker from 'react-datepicker';
import { CalendarContainerProps } from 'react-datepicker';
import {DateTime} from "luxon";
import {useTranslation} from "react-i18next";
import { cloneElement } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { IMousePosition } from '@pluginShared/core-ui-api';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { InputValidator } from '@core/VisualComponents/Inputs/InputValidator/InputValidator';

export interface ICrmCellTimeEditorProps {
    targetRef: MutableRefObject<any>;
    value: any;
    valueRef: MutableRefObject<string | null>;
    onSave: () => void;
    onHide: () => void;
    popperConfig?: any;
}

export function CrmCellTimeEditor(compProps: ICrmCellTimeEditorProps) {
    const hostRef = useRef<any>();
    
    const onChange = (date: any) => {
        const timeStr = date != null
            ? DateTime.fromJSDate(date.toDate()).toFormat('HH:mm')
            : null;

        compProps.valueRef.current = timeStr;
        compProps.onSave();
    };

    const isValid = InputValidator.validateTime(compProps.value);

    const match = isValid ? compProps.value?.match(/^(\d{1,2}):(\d{2})$/) : null;

    let timeValue: any = undefined;
    if (match) {
        const hours = match[1];
        const minutes = match[2];
        const hoursStr = `${(hours.length === 1 ? '0' : '')}${hours}`;
        const dateStr = `2000-01-01T${hoursStr}:${minutes}:00`;
        timeValue = dayjs(DateTime.fromISO(dateStr).toJSDate());
    }

    const handleClear = (e: any) => {
        onChange(null);
    };

    const handleNow = () => {
        onChange(dayjs());
    }

    return (<Overlay
        show
        flip
        placement={'right-start'}
        rootClose
        onHide={compProps.onHide}
        target={compProps.targetRef}
        popperConfig={compProps.popperConfig}
        ref={hostRef}
    >
        {({ show, props, arrowProps, placement }) => 
            <div {...props} className={styles.host}>
                <TimePicker
                    open
                    defaultValue={timeValue}
                    format={"HH:mm"}
                    onChange={onChange}
                    popupStyle={{
                        position: "static",
                    }}
                    style={{
                        display: 'none',
                    }}
                    getPopupContainer={() => hostRef.current}
                    showNow={false}
                    renderExtraFooter={() =>
                        <div style={{ textAlign: "center" }}>
                            <span className={styles.footerSpan} onClick={handleNow}>{t("now")}</span><br/>
                            <span className={styles.footerSpan} onClick={handleClear}>{t("clear")}</span>
                        </div>
                    }
                />
            </div>
        }
    </Overlay>)
}
