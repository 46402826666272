import { eventSourcingRpc } from "@core/config";
import { useAppSelector } from "@core/Redux/hooks";
import { getAutocompleteStore } from "@core/Redux/Slices/appSlice";
import { selectTenantConfig } from "@core/Redux/store";
import { IAutocompleteStore } from "@core/Shared/AutocompleteStore";
import React, { useContext, useMemo } from "react";
import { RpcAutocompleteStore } from "./RpcAutocompletedStore";

export const AutocompleteStoreContext = React.createContext<Record<string, IAutocompleteStore>>(null!);
export const useAutocompleteStore = (tableId: string) => useContext(AutocompleteStoreContext)[tableId];
export const useAutocompleteStores = () => useContext(AutocompleteStoreContext)


//todo:
export function AutocompleteStoreProvider(props: any) {
    const tables = useAppSelector(selectTenantConfig)?.tables;
    const config = useAppSelector(selectTenantConfig);
    const esnode = config?.esnode ?? null; 

    const rpc = eventSourcingRpc(esnode);

    const stores = useMemo(() => {
            let result: Record<string, IAutocompleteStore> = {};
            for (let table of tables ?? []) {
                //result[table.tableId] = new AutocompleteStore(table.tableId);
                if (rpc.enabled) {
                    result[table.tableId] = new RpcAutocompleteStore(table.tableId, rpc.url)
                } else {
                    result[table.tableId] = getAutocompleteStore(table.tableId);
                }
            }
            return result;
    }, [tables]);

    return (
        <AutocompleteStoreContext.Provider value={stores}>
            {props.children}
        </AutocompleteStoreContext.Provider>
    );
}