import { TimeInput } from "@core/VisualComponents/Inputs/TimeInput/TimeInput";
import styles from "./CrmGridAddTimeFilterDialog.module.scss";
import { MutableRefObject, useEffect, useState } from "react";
import { t } from "i18next";
import { ICrmField } from "@core/Models/autogenerated/tenantConfig.models";

interface ITimeFilterQuery {
    $gte?: string;
    $lte?: string;
}

export interface ICrmGridAddTimeFilterDialogProps {
    field: ICrmField;
    query: ITimeFilterQuery | null;
    onChanged: (query: ITimeFilterQuery | null) => void;
    onEnter?: () => void;
    autoFocus?: boolean;
}

export function CrmGridAddTimeFilterDialog(props: ICrmGridAddTimeFilterDialogProps) {
    const [currQuery, setCurrQuery] = useState<ITimeFilterQuery | null>(props.query);

    const keydownHandler = (event: KeyboardEvent) => {
        switch (event.key) {
            case "Enter":
                props.onEnter && props.onEnter();
                break;
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", keydownHandler, false);
        return () => {
            document.removeEventListener("keydown", keydownHandler, false);
        };
    }, [keydownHandler]);

    const onChangedLeft = (value: string | null) => {
        let newQuery = currQuery;

        if (value == null || value === "") {
            if (newQuery?.$lte == null) {
                newQuery = null;
            }
            else {
                delete newQuery.$gte;
            }
        }
        else {
            newQuery = currQuery ?? {};
            newQuery.$gte = value;
        }

        setCurrQuery(newQuery);
        props.onChanged(newQuery);
    }

    const onChangedRight = (value: string | null) => {
        let newQuery = currQuery;

        if (value == null || value === "") {
            if (newQuery?.$gte == null) {
                newQuery = null;
            }
            else {
                delete newQuery.$lte;
            }
        }
        else {
            newQuery = newQuery ?? {};
            newQuery.$lte = value;
        }

        setCurrQuery(newQuery);
        props.onChanged(newQuery);
    }

    return <div className={styles.container}>
        <div>
            <TimeInput
                placeholder={t("from")}
                initialValue={currQuery?.$gte}
                onChanged={onChangedLeft}
                autoFocus={props.autoFocus}
            />
        </div>
        <span>–</span>
        <div>
            <TimeInput
                placeholder={t("to")}
                initialValue={currQuery?.$lte}
                onChanged={onChangedRight}
            />
        </div>
    </div>;
}