import { useState } from "react";
import styles from "./ConfirmChanges.module.scss";
import { t } from "i18next";
import {  ICrmFilter } from "@core/Models/tenantConfig.models";
import _ from "lodash";
import { FullChangesJson } from "./FullChangesJson/FullChangesJson";
import { useAppSelector } from "@core/Redux/hooks";
import { selectTenantConfig } from "@core/Redux/store";
import { ITenantConfig, ITableConfig, ICrmField } from "@core/Models/autogenerated/tenantConfig.models";

export interface IConfirmChangesMessageProps {
    newConfig: ITenantConfig | null;
    onSubmit: () => void;
    onCancel: () => void;
}
// Добавь таблицу "Клиенты" с полями ФИО, дата рождения, город (автокомплит), комментарии
// Удали таблицу "Клиенты", Добавь таблицу "Пользователи" с полями ФИО, дата рождения, город (автокомплит), комментарии. В таблице Пользователи добавь фильтр "город Пермь". Еще добавь столбец с датой поставки в текущую таблицу
export function ConfirmChangesMessage(props: IConfirmChangesMessageProps) {
    const [isSubmited, setIsSubmited] = useState<boolean>(false);

    const onSubmit = () => {
        setIsSubmited(true);
        props.onSubmit();
    }

    const onCancel = () => {
        setIsSubmited(true);
        props.onCancel();
    }

    return <div className={styles.container}>
        <ConfirmChangesInner newConfig={props.newConfig}/>
        {isSubmited || <div className={styles.buttonPanel}>
            <button className={styles.submitButton} onClick={onSubmit}>{t("confirm")}</button>
            <button className={styles.cancelButton} onClick={onCancel}>{t("cancel")}</button>
        </div>}
    </div>;
}

export function ConfirmChangesInner(props: {newConfig: ITenantConfig | null}) {
    let currTenantConfig = useAppSelector(selectTenantConfig);
    
    const [oldTenantConfig, setOldTenantConfig] = useState<ITenantConfig | null>(currTenantConfig);
    const [newTenantConfig, setNewTenantConfig] = useState<ITenantConfig | null>(props.newConfig);

    if (oldTenantConfig == null || newTenantConfig == null) {
        return <></>;
    }
    let changes: JSX.Element[] = [];

    let tablesToDelete: ITableConfig[] = [];
    let tablesToEdit: {oldTableConfig: ITableConfig, newTableConfig: ITableConfig}[] = [];
    let tablesToAdd: ITableConfig[] = [];

    for (const oldTableConfig of oldTenantConfig.tables) {
        const newTableConfig = newTenantConfig.tables.find(x => x.tableId === oldTableConfig.tableId);

        if (newTableConfig == null) {
            tablesToDelete.push(oldTableConfig);
            continue;
        }
        
        if (!_.isEqual(oldTableConfig, newTableConfig)) {
            tablesToEdit.push({oldTableConfig, newTableConfig});
        }
    }

    for (const newTableConfig of newTenantConfig.tables) {
        const oldTableConfig = oldTenantConfig.tables.find(x => x.tableId === newTableConfig.tableId);

        if (oldTableConfig == null) {
            tablesToAdd.push(newTableConfig);
        }
    }

    if (tablesToDelete.length > 0) {
        changes.push(<div key="deleted">
            <h2>{t("deleted-tables")}</h2>
            <ul>
                {tablesToDelete.map(table => <li key={table.tableId}><h2>{table.tableName}</h2> <FullChangesJson oldData={table} newData={{}}/></li>)}
            </ul>
        </div>);
    }

    if (tablesToEdit.length > 0) {
        changes.push(<div key="edited">
            <h2>{t("edited-tables")}</h2>
            <ul>
                {tablesToEdit.map(({oldTableConfig, newTableConfig}) => <li key={oldTableConfig.tableId}>{renderTableChanges(oldTableConfig, newTableConfig)}</li>)}
            </ul>
        </div>);
    }

    if (tablesToAdd.length > 0) {
        changes.push(<div key="added">
            <h2>{t("added-tables")}</h2>
            <ul>
                {tablesToAdd.map(table => <li key={table.tableId}>{renderTableInfo(table)}</li>)}
            </ul>
        </div>)
    }

    return <div className={styles.changes}>
        <h1>{t("confirm-changes")}: <FullChangesJson oldData={oldTenantConfig} newData={newTenantConfig}/></h1>
        {changes}
    </div>;
}

function renderTableChanges(oldConfig: ITableConfig, newConfig: ITableConfig) {
    return <div className={styles.changes}>
        <h2>{t("table")}: {oldConfig.tableName === newConfig.tableName
            ? oldConfig.tableName
            : <><s>{oldConfig.tableName}</s> &#10140; {newConfig.tableName}</>
        } <FullChangesJson oldData={oldConfig} newData={newConfig}/></h2>
        <div>{renderFieldsChanges(oldConfig.fields, newConfig.fields)}</div>
        <div>{renderFiltersChanges(oldConfig.filters, newConfig.filters)}</div>
    </div>;
}

function renderTableInfo(newConfig: ITableConfig) {
    return <div className={styles.changes}>
        <h2>{t("table")}: {newConfig.tableName} <FullChangesJson oldData={{}} newData={newConfig}/></h2>
        <div>{renderFieldsChanges([], newConfig.fields)}</div>
        <div>{renderFiltersChanges([], newConfig.filters)}</div>
    </div>;
}

function renderFieldsChanges(oldFields: ICrmField[], newFields: ICrmField[]): JSX.Element[] {
    let changes: JSX.Element[] = [];

    let fieldsToDelete: ICrmField[] = [];
    let fieldsToEdit: {oldField: ICrmField, newField: ICrmField}[] = [];
    let fieldsToAdd: ICrmField[] = [];

    for (const oldField of oldFields) {
        const newField = newFields.find(x => x.id === oldField.id);

        if (newField == null) {
            fieldsToDelete.push(oldField);
            continue;
        }
        
        if (!_.isEqual(oldField, newField)) {
            fieldsToEdit.push({oldField, newField});
        }
    }

    for (const newField of newFields) {
        const oldField = oldFields.find(x => x.id === newField.id);

        if (oldField == null) {
            fieldsToAdd.push(newField);
        }
    }

    if (fieldsToDelete.length > 0) {
        changes.push(<div key="deleted">
            <h2>{t("deleted-fields")}</h2>
            <ul>
                {fieldsToDelete.map(field => <li key={field.id}><b>{field.caption}</b> <FullChangesJson oldData={field} newData={{}}/></li>)}
            </ul>
        </div>);
    }

    if (fieldsToEdit.length > 0) {
        changes.push(<div key="edited">
            <h2>{t("edited-fields")}</h2>
            <ul>
                {fieldsToEdit.map(({oldField, newField}) => <li key={oldField.id}>{renderFieldChanges(oldField, newField)}</li>)}
            </ul>
        </div>);
    }

    if (fieldsToAdd.length > 0) {
        changes.push(<div key="added">
            <h2>{t("added-fields")}</h2>
            <ul>
                {fieldsToAdd.map(field => <li key={field.id}>{renderFieldInfo(field)}</li>)}
            </ul>
        </div>)
    }

    return changes;
}

function renderFieldChanges(oldField: ICrmField, newField: ICrmField) {
    return <>
        <b>{oldField.caption === newField.caption
            ? oldField.caption
            : <><s>{oldField.caption}</s> &#10140; {newField.caption}</>
        }</b> <FullChangesJson oldData={oldField} newData={newField}/>
    </>;
}

function renderFieldInfo(field: ICrmField) {
    return <>
        <b>{field.caption}</b>: <FullChangesJson oldData={{}} newData={field}/>
    </>;
}

function renderFiltersChanges(oldFilters: ICrmFilter[], newFilters: ICrmFilter[]) {
    let changes: JSX.Element[] = [];

    let filtersToDelete: ICrmFilter[] = [];
    let filtersToEdit: {oldFilter: ICrmFilter, newFilter: ICrmFilter}[] = [];
    let filtersToAdd: ICrmFilter[] = [];

    for (const oldFilter of oldFilters) {
        const newFilter = newFilters.find(x => x.id === oldFilter.id);

        if (newFilter == null) {
            filtersToDelete.push(oldFilter);
            continue;
        }
        
        if (!_.isEqual(oldFilter, newFilter)) {
            filtersToEdit.push({oldFilter, newFilter});
        }
    }

    for (const newFilter of newFilters) {
        const oldFilter = oldFilters.find(x => x.id === newFilter.id);

        if (oldFilter == null) {
            filtersToAdd.push(newFilter);
        }
    }

    if (filtersToDelete.length > 0) {
        changes.push(<div key="deleted">
            <h2>{t("deleted-filters")}</h2>
            <ul>
                {filtersToDelete.map(filter => <li key={filter.id}><b>{filter.caption}</b> <FullChangesJson oldData={filter} newData={{}}/></li>)}
            </ul>
        </div>);
    }

    if (filtersToEdit.length > 0) {
        changes.push(<div key="edited">
            <h2>{t("edited-filters")}</h2>
            <ul>
                {filtersToEdit.map(({oldFilter, newFilter}) => <li key={oldFilter.id}>
                    <b>{oldFilter.caption === newFilter.caption
                        ? oldFilter.caption
                        : <><s>{oldFilter.caption}</s> &#10140; {newFilter.caption}</>
                    }</b> <FullChangesJson oldData={oldFilter} newData={newFilter}/><br/>
                </li>)}
            </ul>
        </div>);
    }

    if (filtersToAdd.length > 0) {
        changes.push(<div key="added">
            <h2>{t("added-filters")}</h2>
            <ul>
                {filtersToAdd.map(filter => <li key={filter.id}><b>{filter.caption}</b> <FullChangesJson oldData={{}} newData={filter}/><br/></li>)}
            </ul>
        </div>)
    }

    return changes;
}
