import { useState, useRef, ComponentProps, RefObject, useEffect } from 'react';
import Select, { components, MenuProps } from 'react-select';
import { Overlay } from 'react-overlays';
import styles from './CustomSelect.module.scss';
import classNames from 'classnames';

let cx = classNames.bind(styles);

// Определяем тип пропсов, соответствующих Select
type SelectComponentProps<T> = ComponentProps<typeof Select<T>>;

export function CustomSelect<T>(props: SelectComponentProps<T>) {
    const containerRef = useRef<HTMLDivElement>(null);
    const containerWidth = useRef<number>(0);

    useEffect(() => {
        if (!containerRef.current) {
            return;
        }

        containerWidth.current = containerRef.current.clientWidth;
    }, [containerRef.current?.clientWidth]);

    return (
        <div ref={containerRef}>
            <Select
                {...props}
                styles={{
                    ...props.styles,
                    menu: (base: any, props: any) => ({
                        ...base,
                        minWidth: containerWidth.current,
                        ...props.styles?.menu?.(base, props),
                    })
                }}
                classNames={{
                    ...props.classNames,
                    menu: (state) => cx(styles.selectMenu, props.classNames?.menu?.(state)),
                    container: (state) => cx(styles.selectContainer, props.classNames?.container?.(state)),
                }}
                components={{
                    ...props.components,
                    Menu: (menuProps) => (
                        <CustomMenu {...menuProps} containerRef={containerRef} />
                    ),
                }}
            />
        </div>
    );
}

// Определяем типы для пропсов CustomMenu
interface CustomMenuProps extends MenuProps<any, boolean> {
    containerRef: RefObject<HTMLDivElement>;
}

function CustomMenu(props: CustomMenuProps) {
    const popperConfig = {
        modifiers: [
            {
                name: 'preventOverflow',
                options: {
                    boundary: 'viewport',
                },
            },
            {
                name: 'flip',
                options: {
                    fallbackPlacements: ['top'],
                },
            },
        ],
    };

    const onClick = (e: any) => {
        e.stopPropagation();
    }

    return (
        <Overlay
            flip={true}
            show={true}
            target={props.containerRef}
            placement="bottom-start"
            popperConfig={popperConfig}
        >
            {({ props: overlayProps }) => (
                <div {...overlayProps} style={{ ...overlayProps.style, zIndex: 9999 }} onClick={onClick}>
                    <components.Menu {...props} />
                </div>
            )}
        </Overlay>
    );
}
