import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit';
import storesReducer from './Slices/ordersSlice/storesSlice';
import appReducer from './Slices/appSlice';
import filtersViewReducer from './Slices/filtersViewSlice';
import i18n from 'src/Locale/i18n';

export const store = configureStore({
    reducer: {
        app: appReducer,
        stores: storesReducer,
        filtersView: filtersViewReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;

export const selectAppReady = (state: RootState) => state.app.status === 'ready';
export const selectDbSchema = (state: RootState) => state.app.dbSchema;

export const selectPluginsState = (state: RootState) => state.app.pluginsState;
export const selectTenantConfig = (state: RootState) => state.app.tenantConfig;
export const selectTableConfig = (tableId: string|undefined) => (state: RootState) => state.app.tenantConfig?.tables?.find(x => x.tableId === tableId);


export const selectUserInfo = (state: RootState) => state.app.userInfo;
export const selectUserLogged = (state: RootState) => state.app.userLogged;
export const selectUserNotLogged = (state: RootState) => state.app.userNotLogged;
export const selectDefaultTenantId = (state: RootState) => state.app.userInfo?.tenant;

export const selectCurrentTable = (state: RootState) => state.app.currentTable;

export const selectTableName = (tableId: string) => (state: RootState) => selectTableConfig(tableId)(state)?.tableName ?? i18n.t("orders");
export const selectOrderFilter = (tableId: string) => (state: RootState) => selectTableConfig(tableId)(state)?.filters;
export const selectSortField = (tableId: string) => (state: RootState) => selectTableConfig(tableId)(state)?.sortField || null;
export const selectSortDirection = (tableId: string) => (state: RootState) => selectTableConfig(tableId)(state)?.sortDirection || null;

export const selectAppErrorsState = (state: RootState) => state.app.appErrors;
