import {DATA_TYPE, IDataBase} from 'jsstore';
import {ITable} from 'jsstore/dist/ts/common/interfaces';
import _ from 'lodash';
import {DateTime} from 'luxon';
import {IDbEntityRawData} from '@core/JsStore/stores/shared/models/i-db-entity';
import {IEntityData} from '@core/Models/i-entity';

export function mapAccordingToSchema(record: IDbEntityRawData|IEntityData, table: ITable): IDbEntityRawData {
    const modifiedRecord = {...record};

    for(let columnName in table.columns){
        const column = table.columns[columnName];

        if(!record.hasOwnProperty(columnName))
            continue;

        const val = record[columnName];

        if(val == null)
            continue;

        switch (column.dataType) {
            case DATA_TYPE.String:
                if(!_.isString(val)) {
                    modifiedRecord[columnName] = (val as any).toString();
                }
                break;
            case DATA_TYPE.DateTime:
                if(_.isString(val)) {
                    modifiedRecord[columnName] = DateTime.fromISO(val).toUnixInteger();
                }
                break;
            case DATA_TYPE.Number:
                if(!_.isNumber(val)) {
                    const parsedValue = parseInt(val as any);
                    if (!isNaN(parsedValue)) {
                        modifiedRecord[columnName] = parsedValue;
                    }
                }
                break;
        }
    }

    return modifiedRecord;
}
