import * as React from 'react';
import cs from 'clsx';
import styles from './Row.module.scss';

// /** @jsxRuntime classic */
// /** @jsx jsx */
// import { css, jsx } from '@emotion/react';
// jsx;

import { LayoutContext } from '@table-library/react-table-library/common/context';

import { Nullish } from '@table-library/react-table-library/types/common';

const getBaseStyle = () => `
  display: contents;

  &.disabled td {
    cursor: auto;
  }

  ${() => {
    /* #1 */
    // otherwise tree + resize would have overflow icons */
    // otherwise pin shine through */
  }}
  background-color: #ffffff;
`;

// const getRowContainerStyle = () => css`
//   ${getBaseStyle()}
// `;

const RowContainer = React.forwardRef(
  function RowContainer(props: Record<string, any>, ref: React.ForwardedRef<HTMLTableRowElement> | Nullish) {
    const context = React.useContext(LayoutContext);

    if (!context) {
      throw new Error('No Layout Context.');
    }

    const { layout } = context;

    const As = layout?.isDiv ? 'div' : 'tr';

    return <As ref={ref} {...props} className={cs(styles.rowBaseStyle, props.className)}/>;
  },
);

// const getHeaderRowContainerStyle = () => css`
//   ${getBaseStyle()}
// `;

const HeaderRowContainer = React.forwardRef(
  (props: Record<string, any>, ref: React.ForwardedRef<HTMLTableRowElement> | Nullish) => {
    const context = React.useContext(LayoutContext);

    if (!context) {
      throw new Error('No Layout Context.');
    }

    const { layout } = context;

    const As = layout?.isDiv ? 'div' : 'tr';

    return <As ref={ref} {...props}  className={cs(styles.rowBaseStyle, props.className)}/>;
  },
);

export { RowContainer, HeaderRowContainer };
