import ChatWindow from "../../Shared/AIChat/ChatWindow";
import { ReactComponent as CloseBtnSvg } from '@assets/Icons/close-button-icon.svg';
import './AiChatPopup.css';

interface AiChatPopupProps {
    isOpen: boolean;
    onHide?: () => void;
}

export function AiChatPopup(props: AiChatPopupProps) {
    if (!props.isOpen)
        return null;

    return (
        <div className="chat-popup">
            <ChatWindow/>
            <button type="button"
                    className="chat-popup-close-button"
                    onClick={props.onHide}>
                <CloseBtnSvg />
            </button>
        </div>
    );
}

export default AiChatPopup;