import { useEffect, useState } from "react";
import style from "./NewUserForm.module.scss";
import { useTranslation } from "react-i18next";
import { ReactComponent as EyeIcon } from '@assets/Icons/eye-icon.svg';
import { ReactComponent as EyeSlashIcon } from '@assets/Icons/eye-slash-icon.svg';
import { StringInput } from "@core/VisualComponents/Inputs/StringInput/StringInput";
import Password from "antd/es/input/Password";
import { Input } from "antd";
import { ComboboxInput } from "@core/VisualComponents/Inputs/ComboboxInput/ComboboxInput";

export interface INewUserInfo {
    login: string;
    password: string;
    role: "User" | "SuperUser";
}

export interface INewUserFormProps {
    userInfo: INewUserInfo;
    onSubmit: (value: INewUserInfo) => void;
    onCancel: () => void;
}

export function NewUserForm(props: INewUserFormProps) {
    const { t } = useTranslation();
    const [login, setLogin] = useState(getIsLoginCorrect(props.userInfo.login) ? props.userInfo.login : "");
    const [password, setPassword] = useState(props.userInfo.password);
    const [role, setRole] = useState(props.userInfo.role);
    const [isSubmited, setIsSubmited] = useState(false);
    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const [isLoginCorrect, setIsLoginCorrect] = useState(true);
    const [isPasswordCorrect, setIsPasswordCorrect] = useState(true);

    const handleShowPasswordButton = () => {
        setIsPasswordShown(prev => !prev);
    }

    const onSubmit = () => {
        if (!isLoginCorrect || !isPasswordCorrect) {
            return;
        }

        props.onSubmit({
            login,
            password,
            role,
        });

        setIsSubmited(true);
        setIsLoginCorrect(true);
        setIsPasswordCorrect(true);
    }

    const onCancel = () => {
        props.onCancel();

        setIsSubmited(true);
        setIsLoginCorrect(true);
        setIsPasswordCorrect(true);
    }

    const generateRandomPassword = () => {
        const chars = "ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz23456789";
        const length = 12;

        let newPassword = "";
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * chars.length);
            newPassword += chars[randomIndex];
        }

        setPassword(newPassword);
        setIsPasswordCorrect(true);
    }

    const onChangeLogin = (newLogin: string) => {
        setLogin(newLogin);
        setIsLoginCorrect(getIsLoginCorrect(newLogin));
    }

    const onChangePassword = (newPassword: string) => {
        setPassword(newPassword);
        setIsPasswordCorrect(getIsPasswordCorrect(newPassword));
    }

    return <div className={style.container}>
        <label>
            {t("login")}
            <Input
                className={style.loginInput}
                value={login}
                placeholder={t("login")}
                onChange={e => onChangeLogin(e.target.value)}
                disabled={isSubmited}
                autoFocus
                allowClear
                status={isLoginCorrect ? "" : "error"}
            />
            {isLoginCorrect || <p className={style.errorMessage}>{t("form_error_invalid_login")}</p>}
        </label>
        <label>
            {t("password")}
            <div className={style.passwordContainer}>
                <Password
                    className={style.passwordInput}
                    value={password}
                    placeholder={t("password")}
                    onChange={e => onChangePassword(e.target.value)}
                    disabled={isSubmited}
                    allowClear
                    status={isPasswordCorrect ? "" : "error"}
                />
            </div>
            {isPasswordCorrect || <p className={style.errorMessage}>{t("form_error_invalid_password")}</p>}
            {isSubmited || <button className={style.randomPasswordButton} onClick={generateRandomPassword}>{t("generate_random_password")}</button>}
        </label>
        <label>
            {t("role")}
            <ComboboxInput
                initialValue={role}
                options={[
                    { value: "User", label: t("User") },
                    { value: "SuperUser", label: t("SuperUser") },
                ]}
                className={style.roleInput}
                onChanged={value => setRole(value as any)}
                disabled={isSubmited}
                disableClear
            />
        </label>
        {isSubmited || <div className={style.buttonPanel}>
            <button className={style.submitButton} onClick={onSubmit}>{t("confirm")}</button>
            <button className={style.cancelButton} onClick={onCancel}>{t("cancel")}</button>
        </div>}
    </div>;
}

function getIsLoginCorrect(login: string) {
    const isValidLength = login.length > 0;
    const validCharacters = /^[a-zA-Z0-9_]+$/;

    return isValidLength && validCharacters.test(login);
}

function getIsPasswordCorrect(password: string) {
    const isValidLength = password.length >= 8;
    const validCharacters = /^[a-zA-Z0-9!@#$%^&*()_+]+$/;
    
    return isValidLength && validCharacters.test(password)
}