import { CSSProperties, MutableRefObject } from 'react';
import { registerComponent } from '@core/Plugins/pluginManager';
import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';
import { InputValidator } from '../InputValidator/InputValidator';
import { StringInput } from '../StringInput/StringInput';

export interface IUrlInputProps {
    initialValue?: string | null;
    placeholder?: string;
    onChanged?: (value: string | null) => void;
    onEnter?: () => void;
    setIsValid?: (isValid: boolean) => void;
    className?: string;
    style?: CSSProperties;
    readonly?: boolean;
    disabled?: boolean;
    valueRef?: MutableRefObject<string | null>;
    autoFocus?: boolean;
    validation?: (value: string) => boolean;
    delayOnChanged?: boolean;
}

export const UrlInput = registerComponent(coreUiComponentDescriptions.UrlInput, _UrlInput);

function _UrlInput(props: IUrlInputProps) {

    return <StringInput
        {...props}
        validation={props.validation ?? InputValidator.validateUrl}
    />
}
