import {createAsyncThunk} from '@reduxjs/toolkit';
import {IEntityData} from '@core/Models/i-entity';
import Logger from 'js-logger';
import {serializeError} from 'serialize-error';
import { IEventSourcingStoreReactClient } from '@core/EventSourcing/EventSourcingClientBase';

export const updateOrderAsync = createAsyncThunk(
    'orders/updateOrderAsync',
    async (args : { store: IEventSourcingStoreReactClient, id: string, changes: IEntityData}) => {
        try {
            Logger.debug('updateOrderAsync', {id:args.id, data:args.changes});
            const time = performance.now();

            await args.store.update(args.id, args.changes);

            Logger.debug(`update order spent ${performance.now() - time} ms`);
            return {tableId: args.store.tableId, id : args.id, changes : args.changes};

        } catch (err: any) {
            Logger.error('updateOrderAsync failed');
            Logger.error(serializeError(err, { maxDepth: 2}));
            throw new Error(err);
        }
    }
);
