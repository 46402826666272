import { IRoute} from '../../../PluginShared/i-plugin-contract';
import styles from './PluginPage.module.scss'

export interface IPluginPageProps {
    route: IRoute
}

export function PluginPage(props: IPluginPageProps) {
    const PluginComponent = () => {
        try {
            return props.route.func();
        }
        catch (e: any) {
            return <div className={styles.pluginPage}>
                <h1 style={{ color: 'red', marginTop: "36px" }}>Plugin error</h1>
                <h2 style={{ color: 'red', margin: "20px" }}>{e.message}</h2>
                <div style={{ whiteSpace: "pre-line" }}>{e.stack}</div>
            </div>;
        }
    }
    
    return (<PluginComponent key={props.route.id}/>);
}
