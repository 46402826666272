import styles from './MenuExpander.module.scss';
import classNames from 'classnames/bind';
import {ReactComponent as ChevronLeftIconSvg} from '@assets/Icons/chevron-left-icon.svg';

let cx = classNames.bind(styles);

export interface IMenuExpanderProps {
    expanded: boolean;
    onClick: () => void;
}

export function MenuExpander(props: IMenuExpanderProps) {
    return (<div className={cx(styles.host, {host_collapsed: !props.expanded})}
                 onClick={() => props.onClick()}>
        <ChevronLeftIconSvg/>
    </div>);
}
