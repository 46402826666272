import { IDbEntity } from "@core/JsStore/stores/shared/models/i-db-entity";
import { DataTransformationFunction, EventSourcingStore, fullDataCacheEnabled } from "./EventSourcingStore";
import { IEventStream } from "./IEventStream";
import { KVStore } from "@core/Stores/KVStore";
import { EntitySnapshotStore, ISnapshotStore } from "@core/Stores/EntitySnapshotStore";
import { useEffect, useState } from "react";
import { IEntity } from "@core/Models/i-entity";
import { IEventSouringQueryViewState, LiveQueryView } from "./Implementation/LiveQueryView";
import { IQuerySpecification } from "./Implementation/LiveQuery";
import { extenedLogsEnabled } from "@core/Services/logger-service";
import { EventSourcingClientBase } from './EventSourcingClientBase';
import { ICrmOperationEventDecoded, ICrmOperationEventDecodedWithLastEventInfo } from "@core/Models/i-crm-operation-event-decoded";
import { AutocompleteStore } from "@core/Shared/AutocompleteStore";
import { updateEntityCountAsync } from "@core/Redux/Slices/ordersSlice/thunks/updateOrdersCountAsync";
import { ICrmField } from "@core/Models/autogenerated/tenantConfig.models";
import { IBulkUpdateResult } from "./BulkUpdateApi";
import { QueryVars } from "@core/Utils/MongoQueryParser";

const fullDataCache = fullDataCacheEnabled();


export class EventSourcingReactLocalClient extends EventSourcingClientBase {
    public static getSchemas(name: string, fields: ICrmField[]) {
        let result = [
            ...EntitySnapshotStore.getSchemas(name, fields),
            ...KVStore.getSchemas(name + '_meta')
        ];
        return result;
    }

    store: EventSourcingStore;

    constructor(tableId: string
        , eventStream: IEventStream
        , dataTransformation: DataTransformationFunction
        , postprocess: (entity: IDbEntity) => IDbEntity
        , tenant: string
        , clientInstance: string
        , autocompleteStore: AutocompleteStore
        , dispatch: any) {

        const metaStore = new KVStore(tableId + '_meta');

        let persistentStore: ISnapshotStore;

        persistentStore = new EntitySnapshotStore(tableId);

        const extended_logs = extenedLogsEnabled();

        const store = new EventSourcingStore(tableId, eventStream, persistentStore, metaStore, dataTransformation, postprocess, extended_logs);
        super(tableId, tenant, clientInstance);
        this.store = store;

        store.onEntitySetChanged(async (changes) => {
            //dispatch(updateChangedOrdersAsync({ orderChanges: changes.getChangedIds() }));
            dispatch(updateEntityCountAsync({store: this}));
            await autocompleteStore.flushCachesToDumbCrmDb();
        });

        store.onNewEntity(async (newEntity) => {
            await autocompleteStore.updateAutocompleteValuesInDb(null, newEntity.data);
        });
        store.onUpdateEntity(async (oldEntity, newEntity) => {
            await autocompleteStore.updateAutocompleteValuesInDb(oldEntity.data, newEntity.data);
        });
        store.onRemoveEntity(async (oldEntity) => {
            await autocompleteStore.updateAutocompleteValuesInDb(oldEntity.data, null);
        });
    }

    public dispose(): void {
        this.store.dispose();
    }

    public preloadLiveQuerieMonitors(queries: IQuerySpecification[], queryVars: QueryVars): void {
        return this.store.preloadLiveQuerieMonitors(queries, queryVars);
    }
    public disposeLiveQueryMonitorByQuery(query: IQuerySpecification, queryVars: QueryVars): void {
        return this.store.disposeLiveQueryMonitorByQuery(query, queryVars);
    }

    protected propagateEvent(event: ICrmOperationEventDecoded): Promise<void> {
        //todo: remove broken event
        if (event.entityId == null)
            return Promise.resolve();
                
        return this.store.propagateEvent(event);
    }

    protected propagateEvents(events: ICrmOperationEventDecodedWithLastEventInfo[]): Promise<IBulkUpdateResult> {
        return this.store.propagateEvents(events);
    }

    public async queryIds(query: IQuerySpecification, queryVars: QueryVars): Promise<string[]> {
        return await this.store.queryIds(query, queryVars);
    }

    public async queryEntities(query: IQuerySpecification, queryVars: QueryVars, limit?: number): Promise<IEntity[]> {
        return this.store.queryEntities(query, queryVars, limit);
    }

    public async process(abortController: AbortController) : Promise<void> {
        await super.process(abortController);
        await this.store.process(abortController);
    }

    public count(): Promise<number> {
        return this.store.count();
    }
    public get(entityId: string): Promise<IEntity | null> {
        return this.store.get(entityId);
    }
    public bulkGet(entityIds: string[]): Promise<(IEntity | null)[]> {
        return this.store.bulkGet(entityIds);
    }
    public db2entity(entity: IDbEntity): IEntity {
        return this.store.db2entity(entity);
    }

    public useGet = (entityId: string): IEntity | null => {
        const self = this;
        const [entity, setEntitity] = useState<IEntity | null>(null);

        useEffect(() => {
            let active = true;
            load();
            subscribe();

            return () => {
                active = false;
                unsubscribe();
            }

            function subscribe() {
                self.store.onUpdateEntity(monitor_change);
                self.store.onNewEntity(monitor_new);
                self.store.onRemoveEntity(monitor_remove);
            }

            function unsubscribe() {
                self.store.onUpdateEntityRemove(monitor_change);
                self.store.onNewEntityRemove(monitor_new);
                self.store.onRemoveEntityRemove(monitor_remove);
            }

            async function load() {
                let entity = await self.store.get(entityId);
                setEntitity(entity);
            }

            async function monitor_change(prev: IEntity, next: IEntity) {
                if (next.id == entityId) {
                    setEntitity(next);
                }
            }
            async function monitor_new(entity: IEntity) {
                if (entity.id == entityId) {
                    setEntitity(entity);
                }
            }
            async function monitor_remove(entity: IEntity) {
                if (entity.id == entityId) {
                    setEntitity(null);
                }
            }

        }, [entityId]);

        return entity;
    }

    public useQuerySubscribe = (query: IQuerySpecification | null, queryVars: QueryVars, skip: number, limit: number, sortAscending: boolean
        , onArrayUpdate: (entities: IEntity[]) => void
        , onIndividualUpdate: (entities: IEntity[]) => void
        , onNewState: (state: IEventSouringQueryViewState) => void) => {

        useEffect(() => {
            if (query == null)
                return;

            const monitor = this.store.getLiveQueryMonitor(query, queryVars);
            const view = new LiveQueryView(this.store, monitor, skip, limit, sortAscending);

            // Захватываем монитор
            monitor.acquire();

            onNewState(view.state);
            onArrayUpdate(view.getEntities());

            view.onArrayUpdate(onArrayUpdate);
            view.onIndividualUpdate(onIndividualUpdate);
            view.onNewState(onNewState);

            return () => {
                view.onArrayUpdateRemove(onArrayUpdate);
                view.onIndividualUpdateRemove(onIndividualUpdate);
                view.dispose();
                view.onNewStateRemove(onNewState);

                // Освобождаем монитор
                monitor.release();
            }
        }, [this, query, skip, limit, sortAscending]);

        return;
    }
}