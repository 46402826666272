import { AllFilter, IFilter, QueryFilter, QueryFilterV2, SearchFilter } from "./OrderFilters";
import { userApiSaveJsonConfig } from "@core/Api/user-api";
import { saveConfig } from "@core/Redux/Slices/appSlice";
import { TFunction } from "i18next";
import { ICrmUserInfo } from "@core/Models/autogenerated/user.models";
import { IQuerySpecification } from "@core/EventSourcing/Implementation/LiveQuery";
import _ from "lodash";
import { ITenantConfig } from "@core/Models/autogenerated/tenantConfig.models";

function removeFilterFromConfig(filter: IFilter
    , config : ITenantConfig
    , dispatch: (f:any)=>void
    ) {

    const tables = config.tables.map(table => {
        if (table.tableId != filter.tableId)
            return table;

        let newFilters = [];
        for (var x of table.filters ?? []) {
            if (x.id !== filter.id)
                newFilters.push(x);
        }
    
        return {
            ...table,
            filters: newFilters
        }
    });

    let newConfig = {...config, tables: tables} as ITenantConfig;

    dispatch(saveConfig(newConfig));
    userApiSaveJsonConfig({ config: JSON.stringify(newConfig) });    
}

export function createFilters(config : ITenantConfig | null
    , tableId: string
    , selectFilter: (id:string|null)=>void
    , userInfo: ICrmUserInfo
    , dispatch: (f:any)=>void
    , t: TFunction<"translation", undefined>
    ): IFilter[] {

    let filterList: IFilter[] = [];

    if (!config)
        return [];

    const tableConfig = config.tables.find(x => x.tableId === tableId);
    if (tableConfig == null)
        return [];

    const onConfigFilterRemove = (filter : IFilter) => {
        removeFilterFromConfig(filter, config, dispatch);
    }

    const onFilterSelected = (filter : IFilter) => {
        selectFilter(filter.id);
    };

    const defaultSortDirection = tableConfig.sortDirection ?? 'Desc';

    let filters = tableConfig?.filters ?? [];

    filters = filters.filter(x => x.users == null || x.users.includes(userInfo.login));
    
    const allFilter = filters.find(x => x.id == '$all') ?? null;
    filterList.push(new AllFilter(tableId, t("notSelected") ?? "All", allFilter, defaultSortDirection, onFilterSelected, onConfigFilterRemove));

    filters = filters.filter(x => x.id != '$all');

    for (let x of filters) {
        if (x.version != null && x.version >= 2) {
            filterList.push(new QueryFilterV2(tableId, x, defaultSortDirection, onFilterSelected, onConfigFilterRemove));
        } else {
            filterList.push(new QueryFilter(tableId, x, defaultSortDirection, onFilterSelected, onConfigFilterRemove));
        }
    }

    return filterList;
}

export function getMergedMongoQueries(filterQuery: IQuerySpecification, searchQuery: string | null) : IQuerySpecification {
    let query: IQuerySpecification = {
        where: filterQuery.where,
        orderBy: filterQuery.orderBy,
        fullTextSearch: searchQuery ?? undefined,
    };

    if (searchQuery != null) {
        query.transient = true;
    }

    return _.cloneDeep(query);
}