import styles from './MainMenuSection.module.scss';
import classNames from 'classnames/bind';

let cx = classNames.bind(styles);

export interface IMainMenuSectionProps {
    caption: string;
    expanded: boolean;
    children: any;
}

export function MainMenuSection(props: IMainMenuSectionProps) {
    return (<div className={styles.host}>
        <h6 className={cx(styles.title, {title_collapsed:!props.expanded})}>{props.caption}</h6>
        <div className={styles.list}>
            {props.children}
        </div>
    </div>);
}
