import { ICrmField } from "@core/Models/autogenerated/tenantConfig.models";
import { StringInput } from "@core/VisualComponents/Inputs/StringInput/StringInput";

interface IStringFilterQuery {
    $containsString: string;
}

export interface ICrmGridAddStringFilterDialogProps {
    field: ICrmField;
    query: IStringFilterQuery | null;
    onChanged: (query: IStringFilterQuery | null) => void;
    onEnter?: () => void;
    autoFocus?: boolean;
}

export function CrmGridAddStringFilterDialog(props: ICrmGridAddStringFilterDialogProps) {
    const onChanged = (value: string | null) => {
        if (value == null || value === "") {
            props.onChanged(null);
        }
        else {
            props.onChanged({
                $containsString: value,
            });
        }
    }

    const onEnter = () => {
        props.onEnter?.();
    }

    return <StringInput
        initialValue={props.query?.$containsString}
        onChanged={onChanged}
        autoFocus={props.autoFocus}
        onEnter={onEnter}
    />;
}