import classNames from "classnames";
import styles from "./LoadingIcon.module.scss"

interface ILoadingIconProps {
    size?: string;
    color?:string;
    visibility?:boolean;
    classes?: string[];
}

export function LoadingIcon(props: ILoadingIconProps) {
    let visibility = 'visible';
    if (props.visibility != null)
        visibility = props.visibility ? 'visible' : 'hidden';

    let classNames = styles.loader;

    if (props.classes != null)
        for (let c of props.classes)
            classNames += " " + c;

    return (
        <div className={classNames} style={{ 
                    '--loader-size': props.size ?? '2em', 
                    '--loader-color': props.color ?? 'white',
                    '--loader-visibility': visibility,
                    } as any}>
            {props.visibility &&
            <>
                <div className={styles.bar}></div>
                <div className={styles.bar}></div>
                <div className={styles.bar}></div>
                <div className={styles.bar}></div>
                <div className={styles.bar}></div>
                <div className={styles.bar}></div>
                <div className={styles.bar}></div>
                <div className={styles.bar}></div>
            </>
            }
        </div>
    );
}