import { getDateStringFromSeconds } from "@core/Helpers/js-date-transformations";
import { extractNormalizedPhones } from "@core/Helpers/phoneNumbers";
import type { IDbEntity } from "@core/JsStore/stores/shared/models/i-db-entity";
import { ITableConfig, ICrmField, CrmFieldViewType } from "@core/Models/autogenerated/tenantConfig.models";
import { DateTime } from 'luxon';
//import i18n from 'src/Locale/i18n';

/**
 * Версия генератора.
 * 
 * Увеличить при изменении алгоритма генерации,
 * чтобы запустить перегенерацию всех данных.
 */
export const KEYWORDS_GENERATOR_VERSION = 13;
export const KEYWORDS_GENERATOR_META_KEY = "keywords_generator_version";
    
export function generateKeywordsByEntity(entity: IDbEntity, tableConfig: ITableConfig, language: string) : string[] {
    let keywords = new Set<string>();
    const fields = tableConfig.fields;

    if (!fields) {
        return [];
    }

    for (let field of fields) {
        if (!entity.entityData.hasOwnProperty(field.id)) {
            continue;
        }

        let value = entity.entityData[field.id] as any;

        if (value == null) {
            continue;
        }

        for (const keyword of generateKeywordsByField(value, field, language)) {
            keywords.add(keyword);
        }
    }

    return Array.from(keywords);
}

function generateKeywordsByField(value: any, field: ICrmField, language: string): string[] {
    switch (field.viewType) {
        case CrmFieldViewType.MultiString:
        case CrmFieldViewType.String:
        case CrmFieldViewType.Phone:
            return normalizeString(value.toString(), language);
        case CrmFieldViewType.Date:
        case CrmFieldViewType.DateTime:
            const parsedValue = parseFloat(value);
            if (isNaN(parsedValue)) {
                return [];
            }

            const normalizedDate = normalizeDate(parsedValue);
            if (normalizedDate) {
                return [normalizedDate];
            }
            return [];
        case CrmFieldViewType.Array:
            if (!field.fields) {
                return [];
            }
            if (!Array.isArray(value)) {
                return [];
            }

            const keywords: string[] = [];
            for (const arrayField of field.fields) {
                for (const arrayValue of value) {
                    if (arrayValue[arrayField.id] == null) {
                        continue;
                    }

                    keywords.push(...generateKeywordsByField(arrayValue[arrayField.id], arrayField, language));
                }
            }
            return keywords;
        case CrmFieldViewType.Combobox:
            if (!field.options) {
                return [];
            }

            const option = field.options.find(x => x.value === value);
            if (!option) {
                return [];
            }

            return [option.label];
        default:
            return [];
    }
}

function normalizeString(value: string, language: string) : string[] {
    value = value.trim();

    const keywords = extractNormalizedPhones(value, language);

    return keywords;
}

const dateFormat = "dd.MM.yyyy";

/**
 * Функция, приводящая дату в формат `dd.MM.yyyy`
 */
function normalizeDate(value: number) : string | null {
    if (typeof value == "number") {
        return getDateStringFromSeconds(value);
    }

    return null;
}