import axios from 'axios';
import { IOperationServiceResult } from '@core/Models/autogenerated/operation.models';
import { ICallRequest } from '@core/Models/autogenerated/phone.models';
import { serverUrl } from '@platform/config';

const endpoint = `${serverUrl}/api/Phone`;

export async function phoneApiMakeCall(req: Partial<ICallRequest>): Promise<void> {
    const resp = await axios.post(`${endpoint}/MakeCall`, JSON.stringify(req));
    //return resp.data;
}