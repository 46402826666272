import { registerLocale } from  "react-datepicker";
import ru from 'date-fns/locale/ru';

import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import isoWeek from 'dayjs/plugin/isoWeek';

import i18n from 'src/Locale/i18n';
import enUS from 'antd/es/locale/en_US';
import ruRU from 'antd/es/locale/ru_RU';

registerLocale('ru', ru);

dayjs.extend(isoWeek);
dayjs.locale(i18n.language);

export const locale = i18n.language == "ru-RU" ? ruRU : enUS;