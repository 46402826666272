import { ReactNode, useRef, useState } from 'react';
import styles from './FilterAreaButton.module.scss';
import classNames from 'classnames/bind';
import { ConfirmDelete } from '@core/VisualComponents/ConfirmDelete/ConfirmDelete';

let cx = classNames.bind(styles);

export interface IFilterAreaButtonProps {
    selected?: boolean;
    caption: string;
    children?: ReactNode;
    canRemove: boolean;
    onClick: () => void;
    onRemove: () => void;
    confirmRemove?: boolean;
} 

export function FilterAreaButton(props: IFilterAreaButtonProps) {
    const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
    const removeButtonRef = useRef<any>();

    const onRemove = () => {
        if (!props.confirmRemove) {
            props.onRemove();
        }
        else {
            setShowConfirmDelete(true);
        }
    }

    const onRemoveConfirmed = () => {
        props.onRemove();
    }

    const onRemoveCanceled = () => {
        setShowConfirmDelete(false);
    }
    return (<div className={cx({ host_selected: props.selected||false}, styles.host)}
                 onClick={(e) => {e.stopPropagation();  props.onClick()} }>
        {props.caption}
        {props.children}
        {props.canRemove && <i className="fas fa-times" ref={removeButtonRef} onClick={(e) => {e.stopPropagation(); onRemove()} }></i>}
        <ConfirmDelete triggerRef={removeButtonRef} visible={showConfirmDelete} onCancel={onRemoveCanceled} onDelete={onRemoveConfirmed}/>
    </div>);
}
