import { CSSProperties, MutableRefObject, useEffect, useMemo, useRef } from 'react';
import { useAppSelector } from '@core/Redux/hooks';
import { selectEntityById } from '@core/Redux/Slices/ordersSlice/storesSlice';
import styles from './OrdersListViewItem.module.scss';
import { selectTableConfig } from '@core/Redux/store';
import { LoadingIcon } from '@core/VisualComponents/Icons/LoadingIcon';
import { isIosSafari } from 'src/Utils/userAgent';
import { IOrderContextMenu } from '../../OrderContextMenu/OrderContextMenu';
import { OrdersListViewItemValue } from '@core/VisualComponents/OrdersListViewItemValue/OrdersListViewItemValue';
import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';
import { registerComponent } from '@core/Plugins/pluginManager';
import { IEntity } from '@core/Models/i-entity';
import { ReactComponent as EllipsisIcon } from '@assets/Icons/ellipsis-vertical-icon.svg';
import { CrmCellInternalSpan } from '@core/VisualComponents/CrmGridView/CrmCellInternal/CrmCellInternal';
import { ICrmField } from '@core/Models/autogenerated/tenantConfig.models';
import { VisibilityOnList } from '@core/Models/tenantConfig.models';

export interface IOrdersListViewItemWrapperProps {
    tableId: string;
	entityId: string;
	fields: ICrmField[];
	onClick: (entityId: string) => void;
	contextMenuRef: MutableRefObject<IOrderContextMenu>;
	selected: boolean;
    style?: CSSProperties;
}

export function OrdersListViewItemWrapper(props: IOrdersListViewItemWrapperProps) {
    const entity = useAppSelector(selectEntityById(props.tableId, props.entityId));

    return OrdersListViewItem({
        ...props,
        entity,
    });
}

export interface IOrdersListViewItemProps {
    tableId: string;
	entityId: string;
    entity: IEntity;
	fields: ICrmField[];
	onClick: (entityId: string) => void;
	contextMenuRef: MutableRefObject<IOrderContextMenu>;
	selected: boolean;
    style?: CSSProperties;
}

export const OrdersListViewItem = registerComponent(coreUiComponentDescriptions.OrdersListViewItem, _OrdersListViewItem);

function _OrdersListViewItem(props: IOrdersListViewItemProps) {
    const itemRef = useRef<HTMLDivElement>(null);

    const handleContextMenu = (e: MouseEvent) => {
        if (!props.contextMenuRef.current.onContextMenu)
            return;

        e.preventDefault();
        props.contextMenuRef.current.onContextMenu(e, itemRef, props.tableId, props.entityId, props.entity.data);
    };

    useEffect(() => {
        itemRef.current && itemRef.current.addEventListener("contextmenu", handleContextMenu);
        if (isIosSafari()) {
            console.log('ios detected');
            itemRef.current && itemRef.current.addEventListener("long-press" as any, handleContextMenu);
        }
        return () => {
            itemRef.current && itemRef.current.removeEventListener("contextmenu", handleContextMenu);
            if (isIosSafari()) {
                itemRef.current && itemRef.current.removeEventListener("long-press" as any, handleContextMenu);
            }
        };
    });

    const titleFields = props.fields.filter(x => x.visibilityOnList === VisibilityOnList.Title);
    const subtitleFields = props.fields
        .filter(x => (x.visibilityOnList === VisibilityOnList.Subtitle)
        && !!props.entity.data[x.id] && (!Array.isArray(props.entity.data[x.id]) || (props.entity.data[x.id] as any).length > 0))
    ;

    let title = [];
    let key = 0;

    for (let x of titleFields) {
        const value = <OrdersListViewItemValue
            tableId={props.tableId}
            value={props.entity.data[x.id]}
            field={x}
            key={key++}
        />;
        if (value != null && value.toString().length > 0) {
            if (title.length > 0)
                title.push(", ");
            title.push(value);
        }
    }

    if (props.entity._lastEventNumber == null) {
        const loadingIcon = (<LoadingIcon key={key++} size='0.8em' color='black' visibility={true} classes={[styles.loadingIcon]}/>);
        title = [loadingIcon, ...title];
    }

    return (
        <div className={styles.host + (props.selected ? " " + styles.selected : "")}
            onClick={() => props.onClick(props.entityId)}
            style={props.style}
            ref={itemRef}>
            <div className={styles.title}>{title}</div>
            <div className={styles.additionalInfo}>
                {subtitleFields.map(subtitleField =>
                    <div key={subtitleField.id} className={styles.additionalInfoItem}>
                        <div className={styles.caption}>{subtitleField.caption}</div>
                        <div className={styles.value}>
                            <OrdersListViewItemValue
                                tableId={props.tableId}
                                value={props.entity.data[subtitleField.id]}
                                field={subtitleField}/>
                        </div>
                    </div>)
                }
            </div>
            <div className={styles.ellipsisButton} onClick={(e) => {
                e.stopPropagation();
                handleContextMenu(e as any);
            }}>
                <EllipsisIcon/>
            </div>
        </div>
    );
}
