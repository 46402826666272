import type { IEntity } from "@core/Models/i-entity";
import _ from "lodash";
import { EntitySetChanges, EventSourcingStore } from "../EventSourcingStore";
import { LiveQuery } from "./LiveQuery";
import { IResultEntry, ResultSet } from "./ResultSet";
import { NowReference } from "@core/Utils/MongoQueryParser";
import { DateTime } from "luxon";
import { serializeError } from "serialize-error";
import { v4 as uuidv4 } from 'uuid';
import Logger from "js-logger";
import type { IDbEntity } from "@core/JsStore/stores/shared/models/i-db-entity";

export enum LiveQueryMonitorState {
    loading,
    live,
    shutdown,
    error
}

export class LiveQueryMonitor {
    id = uuidv4();

    state: LiveQueryMonitorState = LiveQueryMonitorState.loading;
    state_text: string = '';

    result: ResultSet;
    result_skiplimit: IEntity[] = [];

    store: EventSourcingStore;
    query: LiveQuery;
    nowRef: NowReference;

    recalculationTimer?: any;

    wherePredicate: (entity: IEntity) => [boolean, number];
    orderBySelector: (entity: IEntity) => any;

    private refCount: number = 0;
    private readonly DISPOSE_TIMEOUT_MINUTES = 360;

    private preloadAwaiter: Promise<void>;
    private preloadAwaiterResolver: () => void = () => {};

    private onChangeHandlers: Array<(result: ResultSet, changedEntities: Map<string, IEntity>) => void> = [];
    private onNewStateHandlers: Array<(state: LiveQueryMonitorState) => void> = [];

    private prefillSumDuration: number = 0;
    private predicateSumDuration: number = 0;

    constructor(query: LiveQuery, store: EventSourcingStore) {
        this.store = store;
        this.query = query;

        this.wherePredicate = query.wherePredicate;
        this.orderBySelector = query.orderBySelector;
        this.nowRef = query.nowRef;

        this.result = new ResultSet();

        this.preloadAwaiter = new Promise<void>((resolve, reject) => {
            this.preloadAwaiterResolver = resolve;
        });
    }

    public PreloadAwaiter() {
        return this.preloadAwaiter;
    }

    public takeAll() {
        return this.result.takeAll();
    }

    public take(limit: number) {
        return this.result.take(0, limit);
    }

    /** Захватить монитор, начать использовать */
    public acquire = () => {
        this.refCount++;
    }

    /** Освободить монитор, прекратить использовать */
    public release = (disposeImmediately: boolean = false) => {
        if (this.refCount > 0) {
            this.refCount--;
        }
        else {
            Logger.error("Released LiveQueryMonitor with refCount = 0");
            return;
        }

        if (this.query.transient) {
            if (disposeImmediately) {
                if (this.refCount == 0) {
                    this.store.disposeLiveQueryMonitor(this);
                }
            }
            else {
                setTimeout(() => {
                    if (this.refCount == 0) {
                        this.store.disposeLiveQueryMonitor(this);
                    }
                }, this.DISPOSE_TIMEOUT_MINUTES * 60 * 1000);
            }
        }
    }

    public getRefCount = () => {
        return this.refCount;
    }

    public dispose = () => {
        this.state = LiveQueryMonitorState.shutdown;

        if (this.result != null)
            this.result.dispose();

        this.onNewStateHandlers = [];
        this.onChangeHandlers = [];

        if (this.recalculationTimer != null)
            clearTimeout(this.recalculationTimer);

        this.preloadAwaiterResolver();
    }

    public prefill = (entity: IEntity): void => {
        if (this.isTerminated())
            return;

        const startTime = performance.now();

        try {
            let [ok, validDue] = this.wherePredicate(entity);
            this.predicateSumDuration += performance.now() - startTime;
            this.result.upsert(ok, { id: entity.id, orderKey: this.orderBySelector(entity), validDue: validDue });
        } catch (err: any) {
            Logger.error('[LiveQueryMonitor] LiveQuery predicate throw error', err, entity, this.query.mongoQuery);
            this.setError(`[LiveQueryMonitor] LiveQuery predicate throw error: ${err.toString()}`);
            throw err;
        }

        this.prefillSumDuration += performance.now() - startTime;
    }

    public completePrefill = () => {
        this.preloadAwaiterResolver();

        Logger.info(`Monitor prefill for ${JSON.stringify(this.query.mongoQuery)} completed in ${this.prefillSumDuration} ms (predicate: ${this.predicateSumDuration} ms)`);

        if (this.isTerminated())
            return;

        this.state = LiveQueryMonitorState.live;

        this.triggerOnNewState(this.state);
        const emptyCache = new Map<string, IEntity>();
        this.triggerOnChange(this.result, emptyCache);

        this.updateRecalculationTimer();
    }

    public handleEntitySetChanged = async (changes: EntitySetChanges) => {
        if (this.isTerminated())
            return;

        const start_time = performance.now();
        await this.preloadAwaiter;

        if (this.isTerminated())
            return;
        
        let resultUpdated = false;
        this.nowRef.now = DateTime.now();

        try {
            changes.handle(
                (newEntity) => {
                    try {
                        let [ok, validDue] = this.wherePredicate(newEntity);
                        if (this.result.upsert(ok, { id: newEntity.id, validDue: validDue, orderKey: this.orderBySelector(newEntity) }))
                            resultUpdated = true;
                    } catch (err: any) {
                        const sErr = serializeError(err, { maxDepth: 2 });
                        Logger.error('[LiveQueryMonitor] LiveQuery predicate throw error', sErr, newEntity, this.query.mongoQuery);
                        throw err;
                    }
                },
                (oldEntity, newEntity) => {
                    if (newEntity == null) {
                        if (this.result.delete(oldEntity.id)) {
                            resultUpdated = true;
                        }
                    } else {
                        try {
                            let [ok, validDue] = this.wherePredicate(newEntity);
                            if (this.result.upsert(ok, { id: newEntity.id, validDue: validDue, orderKey: this.orderBySelector(newEntity) }))
                                resultUpdated = true;
                        } catch (err: any) {
                            const sErr = serializeError(err, { maxDepth: 2 });
                            Logger.error('[LiveQueryMonitor] LiveQuery predicate throw error', sErr, newEntity, this.query.mongoQuery);
                            throw err;
                        }
                    }
                },
                (oldEntity) => {
                    if (this.result.delete(oldEntity.id)) {
                        resultUpdated = true;
                    }
                }
            );

            if (resultUpdated) {
                var changedEntities = new Map<string, IEntity>(changes.added);
                changes.updated.forEach((e) => {
                    if (e.newEntity != null)
                        changedEntities.set(e.newEntity.id, e.newEntity);
                });

                this.triggerOnChange(this.result, changedEntities);
            }

            this.updateRecalculationTimer();
        } catch (err: any) {
            Logger.error('[LiveQueryMonitor] handleEntitySetChanged throw error', err, this.query.mongoQuery);
            this.setError(`[LiveQueryMonitor] handleEntitySetChanged throw error: ${err.toString()}`);
            throw err;
        }

        //console.log(`[Query] updated:${resultUpdated}, time ${performance.now()-start_time} ms`);
    }

    private updateRecalculationTimer = () => {
        //"1+" для некоторого запаса
        let recalculationTimeout = 1 + this.result.getRecalculationTime() - DateTime.utc().toUnixInteger();
        if (this.recalculationTimer != null)
            clearTimeout(this.recalculationTimer);
        if (recalculationTimeout != Infinity) {
            let delay = Math.min(2000000000, recalculationTimeout * 1000); //prevent setTimeout delay overflow
            this.recalculationTimer = setTimeout(this.handlePredicateStateChanged, delay);
        }
        else
            this.recalculationTimer = undefined;
    }

    private handlePredicateStateChanged = () => {
        let now = DateTime.now();
        let needRecalculate = this.result.getRecalculationEntities(now.toUnixInteger());
        this.nowRef.now = now;

        this.store.bulkGet(Array.from(needRecalculate))
            .then((entities: (IEntity | null)[]) => {
                if (now < this.nowRef.now) {
                    //some newer task already do this update
                    return;
                }

                let resultUpdated = false;
                let changes = entities.filter(e => e != null);

                for (let entity of changes) {
                    try {
                        let [ok, validDue] = this.wherePredicate(entity!);
                        let orderKey = this.orderBySelector(entity!);
                        if (this.result.upsert(ok, { id: entity!.id, validDue: validDue, orderKey: orderKey }))
                            resultUpdated = true;
                    } catch (err: any) {
                        const sErr = serializeError(err, { maxDepth: 2 });
                        Logger.error('LiveQuery predicate throw error', sErr, entity, this.query.mongoQuery);
                    }
                }

                if (resultUpdated) {
                    var changedEntities = new Map<string, IEntity>();
                    changes.forEach((e) => {
                        if (e != null)
                            changedEntities.set(e.id, e);
                    });

                    this.triggerOnChange(this.result, changedEntities);
                }

                this.updateRecalculationTimer();
            })
            .catch(err => {
                Logger.error("[LiveQueryMonitor] handlePredicateStateChanged store.bulkGet failed", err);
            });
    }

    private isTerminated(): boolean {
        return this.state === LiveQueryMonitorState.shutdown || this.state === LiveQueryMonitorState.error;
    }

    private setError(text: string) {
        this.state = LiveQueryMonitorState.error;
        this.state_text = text;
        this.triggerOnNewState(this.state);
    }

    public onNewState = (handler: (state: LiveQueryMonitorState) => void): void => {
        this.onNewStateHandlers.push(handler);
    }
    public onNewStateRemove = (handler: (state: LiveQueryMonitorState) => void): void => {
        this.onNewStateHandlers = this.onNewStateHandlers.filter(h => h !== handler);
    }
    private triggerOnNewState = (state: LiveQueryMonitorState): void => {
        for (let h of this.onNewStateHandlers.slice(0))
            h(state);
    }

    /**
     * Adds a handler that monitor resultSet change. 
     * resultSet contain ids of all entities that passed the filter 
     * changedEntities contains entity data for changed entites at current batch of events.
     * target list of entities can't be reconstructed from only changedEntities and resultSet: manual loading may be required
     */
    public onChange = (handler: (resultSet: ResultSet, changedEntities: Map<string, IEntity>) => void): void => {
        this.onChangeHandlers.push(handler);
    }
    public onChangeRemove = (handler: (resultSet: ResultSet, changedEntities: Map<string, IEntity>) => void): void => {
        this.onChangeHandlers = this.onChangeHandlers.filter(h => h !== handler);
    }
    private triggerOnChange = (resultSet: ResultSet, changedEntities: Map<string, IEntity>): void => {
        for (let h of this.onChangeHandlers.slice(0))
            h(resultSet, changedEntities);
    }
}
