import {v3 as murmurhash} from 'murmurhash';
import styles from './UserProfileIcon.module.scss';

export interface IUserProfileIconProps {
    name?: string;
}

let colors = [
    "#0080FF", // blue-100
    "#2ABB7F", // green-100
    "#82B536", // lime-100
    "#42B2D7", // teal-100
    "#8F7EE7", // purple-100
    "#DA62AC", // magenta-100
    "#F38A3F", // orange-100
]

export function UserProfileIcon(props: IUserProfileIconProps) {
    let colorCode: number = 0;

    if (props.name) {
        colorCode = murmurhash(props.name) % colors.length;
    }

    return (<>
        {props.name ?
            <span title={props.name} className={styles.host} style={{ backgroundColor: colors[colorCode] }}>{props.name[0].toUpperCase()}</span>
        :
            <span className={styles.hostEmpty}>&nbsp;</span>
        }
    </>);
}