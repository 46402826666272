export type ColumnUserSettings = {
    size?: string|number;
}

export type CrmGridViewUserSettings = {
    ColumnSettings?: Record<string, ColumnUserSettings>;
}

export function setSettingsColumnSize(settings: CrmGridViewUserSettings|undefined, columnId: string, size: number|undefined) {
    settings = settings ?? {};
    settings.ColumnSettings = settings.ColumnSettings ?? {};
    if (!(columnId in settings.ColumnSettings))
        settings.ColumnSettings[columnId] = {};
    settings.ColumnSettings[columnId].size = size;
    return settings;
}