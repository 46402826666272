import { CrmSortDirection } from "./autogenerated/tenantConfig.models";

export interface ICrmFilter
{
	id: string;
	version?: number;
	caption: string;
	users?: string[];
	fields?: string[];
	where?: any;
	conditions?: ICrmFilterCondition[];
	sortField?: string;
	sortDirection?: CrmSortDirection;
	aiGenerated?: boolean;
}
export interface ICrmFilterCondition
{
	fieldName: string;
	valueType: CrmFilterType;
	value?: string;
	values?: string[];
	rangeValue?: ICrmFilterRangeValue;
}
export interface ICrmFilterRangeValue
{
	gteValueType: CrmFilterValueType;
	ltValueType: CrmFilterValueType;
	gte?: string;
	lt?: string;
}


// export interface ICrmField
// {
// 	id: string;
// 	caption: string;
// 	placeholder?: string;
// 	textEllipsis?: number;
// 	autocomplete?: boolean;
// 	readonly?: boolean;
// 	hideOnCreateDialog?: boolean;
// 	viewType: CrmFieldViewType;
// 	dataTransformations?: CrmFieldDataTransormation[];
// 	plugins?: string[];
// 	options?: ICrmValueOption[];
// 	style?: ICrmFieldStyle;
// 	visibilityOnList?: VisibilityOnList;
// 	fields?: ICrmField[];
// 	regExp?: string;
// 	default?: CrmFieldDefault | string;
// }
export enum CrmFieldDefault
{
	User = "$user",
	Date = "$date",
	Time = "$time",
	Now = "$now",
}
export interface ICrmFieldStyle
{
	maxWidth?: string;
	wordWrap?: string;
	whiteSpace?: string;
}
// export interface ICrmValueOption
// {
// 	value: string;
// 	label: string;
// }

// export interface ITableConfig {
//     tableId: string;
//     tableName: string;
// 	sortField: string;
// 	sortDirection: CrmSortDirection;
// 	filters: ICrmFilter[];
// 	fields: ICrmField[];
// }

// export interface ITenantConfig
// {
// 	tenantName: string;
//     language: string;
//     tables: ITableConfig[];
// }
// export enum CrmFieldDataTransormation {
// 	FromYmdToUnixTimestamp = 'FromYmdToUnixTimestamp'
// }

export enum CrmFieldEditor {
	Default = 'Default',
	YesNo = 'YesNo',
	TimeZone = 'TimeZone'
}
export enum VisibilityOnList {
	Invisible = 'Invisible',
	Title = 'Title',
	Subtitle = 'Subtitle'
}
// export const enum CrmSortDirection {
// 	Asc = 'Asc',
// 	Desc = 'Desc'
// }

export enum CrmFilterType {
	Constant = 'Constant',
	Range = 'Range',
	Substring = 'Substring',
	NonEmpty = "NonEmpty"
}

export enum CrmFilterValueType {
	StartOfDayPlusDayDiff = 'StartOfDayPlusDayDiff',
	Date = 'Date',
	Numeric = 'Numeric',
	String = 'String'
}