import axios, { AxiosResponse } from 'axios';
import {
    IAddRequest, 
    IAddRangeRequest, IGet2Request, IEventsQueryRequest, IOperationServiceResult, ICrmOperationEvent,
    IGetRequest
} from '@core/Models/autogenerated/operation.models';
import { serverUrl } from '@platform/config';
import { decodeEvent, ICrmOperationEventDecoded } from '@core/Models/i-crm-operation-event-decoded';

const endpoint = `${serverUrl}/api/operation`;

export async function operationApiGetOld(req: Partial<IGetRequest>): Promise<ICrmOperationEvent[]> {
    const resp = await axios.post(`${endpoint}/Get`, JSON.stringify(req));
    return resp.data;
}

export async function operationApiGet(req: Partial<IGet2Request>): Promise<IOperationServiceResult> {
    const resp = await axios.post(`${endpoint}/Get2`, JSON.stringify(req));
    return resp.data;
}

export async function operationApiAdd(req: IAddRequest): Promise<AxiosResponse<any, any>> {
    const resp = await axios.post(`${endpoint}/Add`, JSON.stringify(req));
    return resp;
}

export async function operationApiAddRange(req: IAddRangeRequest): Promise<AxiosResponse<any, any>> {
    const resp = await axios.post(`${endpoint}/AddRange`, JSON.stringify(req));
    return resp;
}

export async function operationApiQuery(req: Partial<IEventsQueryRequest>): Promise<ICrmOperationEvent[]> {
    const resp = await axios.post(`${endpoint}/Query`, JSON.stringify(req));
    return resp.data;
}

export async function* operationApiQueryStream(req: Partial<IEventsQueryRequest>): AsyncGenerator<ICrmOperationEventDecoded> {
    const limit = 1000;
    req.lastEventId = -1;

    req.limit = limit;
    while (true) {
        const events = await operationApiQuery(req);

        if (events.length === 0) {
            break;
        }

        for (const event of events) {
            req.lastEventId = Math.max(req.lastEventId, event.id);
            yield decodeEvent(event);
        }
    }
}