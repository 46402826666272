import { useMatch, useResolvedPath } from 'react-router-dom';
import styles from './MainMenuItem.module.scss';
import classNames from 'classnames/bind';

let cx = classNames.bind(styles);

export interface IMainMenuItemProps {
    to: string;
    children: any;
}

export function MainMenuItem(props: IMainMenuItemProps) {
    const resolved = useResolvedPath(props.to);
    const match = useMatch({ path: resolved.pathname, end: true });
    const active = match !== null;

    return (<div className={cx({host: true, host_active: active})}>
        <div className={styles.inner}>
            {props.children}
        </div>
    </div>);
}
