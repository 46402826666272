import React from 'react';
import { useTranslation } from "react-i18next";
import './AiChatButton.css';

interface ChatButtonProps {
  onClick: () => void;
}

const AiChatButton: React.FC<ChatButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <button className="chat-button" onClick={onClick}>
      <i className="fa-solid fa-pen-to-square"></i>&nbsp; &nbsp;<span>{t("ai-assistant-button")}</span>
    </button>
  );
};;
export default AiChatButton;