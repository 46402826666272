import type { ICrmArrayElement } from '@core/Models/i-array-element';
import type { ICrmArrayOperationAdd, ICrmArrayOperationUpdate, ICrmArrayOperationRemove } from '@core/Models/i-crm-array-operation-events';
import _ from 'lodash';

export function CalculateArrayModifications(oldValues: ICrmArrayElement[], newValues: ICrmArrayElement[]) {
    oldValues = oldValues ?? [];
    newValues = newValues ?? [];

    let toAdd : Record<string, ICrmArrayOperationAdd> = {};
    let toRemove : Record<string,ICrmArrayOperationRemove> = {};
    let toUpdate : Record<string, ICrmArrayOperationUpdate> = {};

    const oldMap = new Map(oldValues.map(value => [value.id, value]));
    const newMap = new Map(newValues.map(value => [value.id, value]));

    newValues.forEach((newElement, index) => {
        const oldElement = oldMap.get(newElement.id);

        if (!oldElement) {
            toAdd[newElement.id] = { pos: index, value: newElement };
        } else {
            const diff = getArrayElementDiffOrUndefined(oldElement, newElement);
            const pos = oldValues.indexOf(oldElement) !== index ? index : undefined;
            if (diff != null || pos != null) {
                toUpdate[newElement.id] = { id: newElement.id, pos: pos, value: diff };
            }
        }
    });

    oldValues.forEach(oldElement => {
        if (!newMap.has(oldElement.id)) {
            toRemove[oldElement.id] = { id: oldElement.id };
        }
    });

    return { 
        add: Object.values(toAdd),
        remove: Object.values(toRemove),
        update: Object.values(toUpdate),
    };
}

function getArrayElementDiffOrUndefined(oldValue: ICrmArrayElement, newValue: ICrmArrayElement): Record<string, any> | undefined {
    if (oldValue.id !== newValue.id) {
        throw new Error("ID values do not match");
    }
    
    const diff: Record<string, any> = {};

    for (const key in oldValue) {
        if (key !== 'id' && !_.isEqual(oldValue[key], newValue[key])) {
            // При удалении поля его значение становится null
            // Из-за того, что undefined не сериализуется
            diff[key] = newValue[key] ?? null;
        }
    }

    for (const key in newValue) {
        if (key !== 'id' && oldValue[key] == null && newValue[key] != null) {
            diff[key] = newValue[key];
        }
    }

    return Object.keys(diff).length > 0 ? diff : undefined;
}

interface ICrmArrayOperationAddOrUpdate {
    pos?: number;
    value: ICrmArrayElement;
}

export function ApplyArrayModifications(
    values: ICrmArrayElement[],
    toAdd: ICrmArrayOperationAdd[],
    remove: ICrmArrayOperationRemove[],
    update: ICrmArrayOperationUpdate[]
): ICrmArrayElement[] {
    const add: ICrmArrayOperationAddOrUpdate[] = [...(toAdd ?? [])];
    remove = [...(remove ?? [])];
    update = [...(update ?? [])];

    let result = [...values ?? []];

    const removeIds = new Set(remove.map(op => op.id));
    result = result.filter(el => !removeIds.has(el.id));

    for (const e of update) {
        const index = result.findIndex(el => el.id === e.id);
        if (index < 0) {
            continue;
        }

        const updatedElement = { ...result[index], ...(e.value ?? {}) };

        add.push({ pos: e.pos, value: updatedElement });
    }

    const addSorted = _.sortBy(add, x => x.pos);
    for (const e of addSorted) {
        const idx = result.findIndex(x => x != null && e.value != null && x.id === e.value.id);
        if (idx < 0 && e.pos == null) {
            continue;
        }

        if (idx >= 0) {
            result.splice(idx, 1);
        }
        
        let to = e.pos ?? idx;
        if (to < 0)
            to = result.length + 1 + to;
        if (to >= result.length)
            to = result.length;

        result.splice(to, 0, e.value);
    }

    return result;
}
