import _ from 'lodash';
import { BaseTool, CompositeTool, IApiForAIAgent } from "./ai-api";
import { userApiCreateNewUser, userApiGetTenantUsers } from '@core/Api/user-api';
import { AxiosError } from 'axios';
import { StatusCode } from '@core/Constants/http-status-codes';
import { AskUserTool } from './commonPluginTools';
import { ICrmApi } from '@pluginShared/i-crm-api';
import { createApi } from '@core/Redux/Slices/appSlice';

const getPrompt = async (api: IApiForAIAgent) => {
    const userInfo = api.getUserInfo();
    return `
You are an AI CRM user accounts manager tool.
Current user login is '${userInfo?.login}' and role is '${userInfo?.role}'.
    `;
}

export class UsersManagerTool extends CompositeTool {
    name: string = "users_manager";
    description: string = 
"This function is useful when a user wants to manage or list user accounts.\
You should know that in the system, only the username and password are known about the user but the user may not provide you with this information.\
If there is not enough information from the user, do not ask him again. Just leave these values blank.\
The input is a text with a human-readable description of task. If I ask you to answer something to the user, do so, regardless of the result of the work.\
";

    public getPrompt = (): Promise<string> => {
        return getPrompt(this.api);
    }

    toolkit: BaseTool[];
    crmApi: ICrmApi;

    constructor(api: IApiForAIAgent, access_token: string) {
        super(api, access_token, "gpt-4o", true);
        this.crmApi = createApi(api.getUserInfo()!);

        this.toolkit = [
            new ListUsersTool(this.api),
            new CreateUserTool(this.api),
            new AskUserTool(this.api, this.crmApi),
        ];
    }

    async run(query: string) : Promise<string> {
        console.log(`*UsersManagerTool ${query}`)

        const agent = await this.createAgent();

        const response = await agent.invoke({
            input: query,
        });

        return response.output;
    }
}

export class ListUsersTool extends BaseTool {
    name: string = "list_users";
    description: string =
        "useful for when you need to list all user accounts in CRM. Input is empty. Output is a list of registered accounts.";
    async run(query: string) : Promise<string> {
        try {
            console.log(`*ListUsersTool ${query}`);

            var userInfo = this.api.getUserInfo();
            var users = await userApiGetTenantUsers({tenant: userInfo?.tenant});
            return JSON.stringify(users);
        }
        catch (error : any) {
            return "Exception: " + error.toString();
        }
    }
}

export class CreateUserTool extends BaseTool {
    name: string = "create_user";
    description: string =
"useful for when you need to create new user.\
If the user has not provided a login or provided incorrect login, you are forbidden to ask him again, just leave the input as an empty string.\
If the user has provided a login, then the input must be a string with this login.\
If I ask you to answer something to the user, do so, regardless of the result of the work.\
IMPORTANT: Always inform the user about any changes to the login they provided.\
IMPORTANT: The username can only contain Latin letters, numbers and underscores\
Please note that only users with the 'SuperUser' role can create a new user.";

    async run(query: string): Promise<string> {
        try {
            console.log(`*CreateUserTool ${query}`);

            if (typeof query != "string") {
                query = "";
            }

            return this.api.showNewUserForm(query)
                .then(async result => {
                    const preAnswer = query == result.login ? `` : `The user has changed the login with which he wants to create a user, now he wants to create a user with the login '${result.login}'. Inform the user that they changed the login to '${result.login}'.`;

                    const resp = await userApiCreateNewUser({ login: result.login, password: result.password, role: result.role })
                        .catch((error: AxiosError) => {
                            if (error.response?.status === StatusCode.ClientErrorForbidden) {
                                return `${preAnswer} Inform the user that and he does not have permission to create a new user because of his role in the system`;
                            }
        
                            const message = error.response?.data as string;
                            if (!message) {
                                return `${preAnswer} Exception: ${error}`;
                            }
        
                            switch (message.slice(0, 4)) {
                                case "U002":
                                    return `${preAnswer} Inform the user that and the user with login '${result.login}' already exist`;
                                default:
                                    return `${preAnswer} Inform the user that and an error has occurred on the server. Message from the server: ${message}`;
                            }
                        });
                    
                    if (typeof resp == "string") {
                        return resp;
                    }

                    return `${preAnswer} Inform the user that and the new user with login '${result.login}' successfully created.`;
                })
                .catch((reason: string) => {
                    if (reason == "cancel") {
                        return `Inform the user that he canceled the creation of a new user`;
                    }
                    return `Exception: ${reason}`;
                })
        }
        catch (error: any) {
            return `Exception: ${error}`;
        }
    }
}
