import parsePhoneNumberFromString, { CountryCode } from "libphonenumber-js";

// \u2013 \u2014 - длинное и короткое тире
// IMPORTANT: При изменении этого регулярного выражения, не забудь актуализировать его на бэке в файле PhoneNumbersExtractor.cs
const phoneRegex = /\+?(?:(?![\n\r])[\s\-\u2013\u2014()/])*(\d(?:(?![\n\r])[\s\-\u2013\u2014()/])*){6,15}/gs;


export function extractStrictCleanPhones(value: string): string[] {
    value = value.trim();
    const phones: string[] = [];

    const phoneMatches = value.match(phoneRegex);
    if (phoneMatches) {
        for (const match of phoneMatches) {
            phones.push(strictCleanPhoneNumber(match));
        }
    }

    return phones;
}

export function extractNormalizedPhones(value: string, language: string): string[] {
    value = value.trim();
    const phones: string[] = [];

    const phoneMatches = value.match(phoneRegex);
    if (phoneMatches) {
        for (const match of phoneMatches) {
            phones.push(normalizePhoneNumber(match, language));
        }
    }

    return phones;
}

/**
 * Метод, пробующий извлечь номер телефона из строки
 */
export function extractShortenedPhoneNumbers(value: string) : string[] {
    const phones: string[] = [];
    const phoneMatches = value.match(phoneRegex);

    if (phoneMatches) {
        for (const match of phoneMatches) {
            let cleaned = match.replace(/\D/g, '');

            if (cleaned.length > 10 && (cleaned[0] == '8' || cleaned[0] == '0')) {
                cleaned = cleaned.slice(1);
            }

            phones.push(cleaned);
        }
    }

    return phones;
}

/**
 * очищает номер от всего кроме: цифр
 */
export function strictCleanPhoneNumber(phone: string): string {
    // Удаляем все символы, кроме цифр
    return phone.replace(/\D/g, '');
}

/**
 * Функция, приводящая строку с номером телефона в единый формат E164
 */
function normalizePhoneNumber(value: string, language: string): string {
    let normalized: string = extractDigitsAndPlus(value);

    if (!normalized.startsWith('+')) {
        normalized = parseNumberByRegion(normalized, language);
    }

    return normalized;
}

/**
 * Функция, извлекающая цифры и плюс из строки
 */
function extractDigitsAndPlus(input: string): string {
    const plusSign = input.includes('+') ? '+' : '';
    const digits = input.replace(/\D/g, '');

    return plusSign + digits;
}

/**
 * Функция, пробующая преобразовать строку с номером телефона из национального формата в международный
 */
function parseNumberByRegion(value: string, language: string): string {
    const countryCode = languageToCountryCodeOrDefault(language);
    const parsedNumber = parsePhoneNumberFromString(value, countryCode);

    if (parsedNumber && parsedNumber.isValid()) {
        return parsedNumber.number;
    }

    return value;
}

/**
 * Функция, пробующая извлечь код страны из языка системы. По умолчанию возвращает `US`
 */
function languageToCountryCodeOrDefault(language: string = "en-US"): CountryCode {
    try {
        return language.split("-")[1] as CountryCode;
    } catch (Ex) {
        return "US";
    }
}
